export default {
    setWX(state, wx) {
        state.wx = wx
    },
    setWXConfig(state, config) {
        state.wxConfig = config
        state.wx.config(config)
    },
    setCurrentTitle(state, currentTitle) {
        state.currentTitle = currentTitle
    }, 
    setAuthInfo(state, authInfo) {
        state.authInfo = authInfo
    }
}