import './redirect'
// import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from '@utils/i18n'
// import ElementUI from 'element-ui' // 需要优化
// import {
//   Toast,
//   Dialog,
//   Lazyload
// } from 'vant'
import 'swiper/swiper-bundle.css'

// import 'element-ui/lib/theme-chalk/index.css'
import './initialize'
import '@styles/index.scss'
import '@styles/font.css'
import Swiper2, {
  Navigation,
  Pagination,
  EffectFade,
  Autoplay
} from 'swiper'
import resize from '@/directive/imageResize'
import SlideVerify from 'vue-monoplasty-slide-verify';
import vueHashCalendar from 'vue-hash-calendar'
import 'vue-hash-calendar/lib/vue-hash-calendar.css'
// 注册组件库
Vue.use(vueHashCalendar)
Vue.use(SlideVerify);
Swiper2.use([Autoplay, Pagination])
// Vue.use(ElementUI)
// Vue.use(Toast)
// Vue.use(Dialog)
Vue.use(vant.Lazyload, {
  lazyComponent: true,
})

Vue.config.productionTip = false

const vm = new Vue({
  directives: {
    resize
  },
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

window.native2js = function (message) {
  if (!message) return
  return store.state.nativeBridge.nativeBridge.handleMessage(message)
}

window.handleActions = function (params) {
  return store.dispatch('communication/handleActions', params)
}

export default vm

// router.beforeEach((to, from, next) => {
//  console.log(to,from)
//   next();
// });