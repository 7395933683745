<template>
    <div class="home-list-wrap">
        <div class="tip-more">
            <div class="tip">手术示教</div>
            <div class="more" @click="$emit('handleClick', 3)">更多<van-icon name="arrow" /></div>
        </div>
        <div class="box-content">
            <!-- <div class="course-list" > -->
            <home-video-item
                v-for="(data, index) in data"
                :key="index"
                :item="data"
                @item-click="itemShipinClick"
            >
            </home-video-item>
            <!-- </div> -->
        </div>
    </div>
</template>
<script>
import { mapState, mapGetters } from '@utils/vuex'
import 'swiper/swiper-bundle.css'
import List from '@components/List'
import HomeVideoItem from '@components/MeetingListItem/HomeVideoItem'
export default {
    components: {
        HomeVideoItem,
        List
    },
    props: {
        data: {
            type: Array,
            require: true,
            defalut: []
        }
    },
    data() {
        return {
            loading: true,
            finished: false,
            course_hotword: [
                {
                    title: '全部',
                    id: '0'
                },
                {
                    title: '免费',
                    id: '1'
                },
                {
                    title: '付费',
                    id: '2'
                }
            ],
            current_id: 0,
            list: []
        }
    },
    computed: {
        ...mapState('home', ['collegeID']),
        ...mapGetters(['ifReviewTime'])
    },
    created() {},
    mounted() {
        this.loading = true
    },
    methods: {
        itemShipinClick(item) {
            this.$store.commit('setHomeScrollTop', document.querySelector('.scroll-main').scrollTop)
            this.$store.commit('setKeepAlive', ['home'])
            // const { playback_type, id, is_big_meeting } = item;

            if (item.is_operation_review == 1) {
                this.$router.push({
                    path: 'operationDetail',
                    query: {
                        id: item.production_id
                    }
                })
            } else {
                this.$router.push({
                    path: 'doctorSharePage',
                    query: {
                        production_id: item.production_id
                    }
                })
            }
        }
        // 全部 免费 付费
    }
}
</script>
<style lang="scss" scoped>
@import '@styles/variables.scss';
.tip-more {
    width: 100%;
    height: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0rem 3% 0rem 3%;
    .tip {
        font-size: $fsxl;
        font-weight: 500;
        color: rgba(34, 34, 34, 1);
    }
    .more {
        cursor: pointer;
        font-size: 0.51rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #888888;
    }
}
.home-list-wrap {
    // background: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
}
.box-content {
    height: 6.29rem;
    width: 94%;
    display: flex;
    align-items: center;
    background: #fff;
    box-shadow: 0rem 0.18rem 0.37rem 0rem rgba(214, 214, 214, 0.2);
    border-radius: 0.37rem;
    overflow-y: hidden;
    overflow-x: auto;
}
::v-deep .course-item:last-child {
    margin-right: 0.43rem;
}
</style>
