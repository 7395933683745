<template>
  <div class="home-list-wrap">
    <div class="tip-more" style="margin-top: 0.213rem">
      <div class="tip">近期会议</div>
      <div class="more" @click="$emit('handleClick', 1)">
        更多
        <van-icon name="arrow" />
      </div>
    </div>
    <div class="home-list-content-wrap">
      <template v-if="data.length > 0">
        <div v-for="(item, index) of data" :key="index">
          <meeting-item :item="item" @item-click="itemClick" :isCredit="true"></meeting-item>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { mapState } from '@utils/vuex'
import MeetingItem from '@components/MeetingListItem/MeetingItem'

export default {
    components: {
        MeetingItem
    },
    props: {
        hide_more_button: {
            type: Number,
            require: false,
            defalut: ''
        },

        data: {
            type: Array,
            require: true,
            defalut: []
        }
    },
    data() {
        return {}
    },
    created() {},
    mounted() {},
    methods: {
        itemClick(item) {
            const { meeting_id, is_big_meeting, selected_son_id } = item
            this.$store.commit('setHomeScrollTop', document.querySelector('.scroll-main').scrollTop)
            this.$store.commit('setKeepAlive', ['home'])
            if (is_big_meeting == 1) {
                if (selected_son_id) {
                    this.$router.push({
                        path: '/meetingDetail',
                        query: {
                            meeting_id: selected_son_id
                        }
                    })
                } else {
                    this.$router.push({
                        path: '/meetingHome',
                        query: {
                            meeting_id: meeting_id
                        }
                    })
                }
            } else {
                this.$router.push({
                    path: '/meetingDetail',
                    query: {
                        meeting_id: meeting_id
                    }
                })
            }
        }
    }
}
</script>
<style scoped src="@styles/other/home/homeHotwords.css"></style>
<style scoped lang="scss">
@import '@styles/variables.scss';
.home-list-content-wrap {
    padding: 0 3%;
}
.home-list-wrap {
    margin-bottom: 0.43rem;
}
::v-deep {
    .meet_list {
        background: #f6f7f9;
        padding-bottom: 0.6rem;
        &:last-child {
            // padding-bottom: 0;
        }
    }
}
</style>