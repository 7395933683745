import Request from '@request'
import api from '@api'
import user from '../user/state'

const userfoucstatus = async function ({
    dispatch,
    commit,
    state,
    rootState
}, params) {
    return new Promise((resolve, reject) => {
        Request.post(api.home.userfoucstatus).then(res => {
            let {
                data
            } = res
            // commit('setCollegeClose', data.college_status == 1 ? false : true)
            resolve(res)
        })
    })
}
const courselist = async function ({
    dispatch,
    commit,
    state,
    rootState
}, params) {
    return new Promise((resolve, reject) => {
        Request.post(api.home.courselist, params).then(res => {
            let {
                data
            } = res
            // commit('setCollegeClose', data.college_status == 1 ? false : true)
            resolve(res)
        })
    })
}
// 系列课程
const seriescourselist = async function ({
    dispatch,
    commit,
    state,
    rootState
}, params) {
    return new Promise((resolve, reject) => {
        Request.post(api.home.seriescourselist, params).then(res => {
            let {
                data
            } = res
            // commit('setCollegeClose', data.college_status == 1 ? false : true)
            resolve(res)
        })
    })
}
// 手术
const surgicalList = async function ({
    dispatch,
    commit,
    state,
    rootState
}, params) {
    return new Promise((resolve, reject) => {
        Request.post(api.home.surgicalList, params).then(res => {
            let {
                data
            } = res
            // commit('setCollegeClose', data.college_status == 1 ? false : true)
            resolve(res)
        })
    })
}
const seriescoursedetail = async function ({
    dispatch,
    commit,
    state,
    rootState
}, params) {
    return new Promise((resolve, reject) => {
        Request.post(api.home.seriescoursedetail, params).then(res => {
            let {
                data
            } = res
            // commit('setCollegeClose', data.college_status == 1 ? false : true)
            resolve(res)
        })
    })
}
//课程搜索
const searchCourse = async function ({
    dispatch,
    commit,
    state,
    rootState
}, params) {
    return new Promise((resolve, reject) => {
        Request.post(api.home.searchCourse, params).then(res => {
            let {
                data
            } = res
            // commit('setCollegeClose', data.college_status == 1 ? false : true)
            resolve(res)
        })
    })
}
// 系列搜索
const searchSeriesCourse = async function ({
    dispatch,
    commit,
    state,
    rootState
}, params) {
    return new Promise((resolve, reject) => {
        Request.post(api.home.searchSeriesCourse, params).then(res => {
            let {
                data
            } = res
            // commit('setCollegeClose', data.college_status == 1 ? false : true)
            resolve(res)
        })
    })
}
const coursedetail = async function ({
    dispatch,
    commit,
    state,
    rootState
}, params) {
    return new Promise((resolve, reject) => {
        Request.post(api.home.coursedetail, params).then(res => {
            let {
                data
            } = res
            // commit('setCollegeClose', data.college_status == 1 ? false : true)
            resolve(res)
        })
    })
}
const getList = async function (parameter) {
    const {
        key,
        url,
        state,
        rootState,
        commit
    } = parameter

    let {
        params
    } = parameter

    if (!params) params = {}

    const is_load_more = params.is_load_more
    delete params['is_load_more']

    return new Promise((resolve, reject) => {
        if (is_load_more) {
            commit(`add${key}Page`)
        } else {
            commit(`reset${key}Page`)
        }
        // let collegeID = ''
        // if (user.isLogin) {
        //     if (state.collegeID) {
        //         collegeID = state.collegeID
        //     } else {
        //         collegeID = -1
        //     }

        // } else {
        //     collegeID = 0
        // }
        // }
        // console.log(state.collegeID, collegeID)
        Request.post(url, {
            college_id: state.collegeID,
            disease_id: state.diseaseID,
            page: state[`${key.toLowerCase()}_page`],
            ...params
        }).then(async res => {
            let {
                data
            } = res
            if (key !== 'Playback') {
                if (url !== api.home.consulting_search_list) {
                    data = data.data
                } else if (key == 'Consulting' && url == api.home.consulting_search_list) {
                    data = data.data
                }
            } else {

                if (url == api.home.playback_search_list) {
                    data = data.data
                }
            }
            // 增加教学预告为空盘那段判断 标识Advance
            if (key == 'Advance') {
                if (is_load_more) {
                    await commit(`set${key}Data`, [...state[`${key.toLowerCase()}_data`], ...data])
                } else {
                    await commit(`set${key}Data`, data)
                }

            } else {

                if (is_load_more) {
                    commit(`set${key}Data`, [...state[`${key.toLowerCase()}_data`], ...data])
                } else {
                    commit(`set${key}Data`, data)
                }
            }
            if (data && (data.length < 20)) {
                resolve(true)
            } else {
                resolve(false)
            }
        })
    })
}

// 0 直播 1课程 2 咨询  3 大会 
const getTabKey = function (index) {
    let key
    switch (index) {
        case 0:
            key = 'Advance'
            break
        // case :
        //     key = 'Playback'
        //     break
        case 2:
            key = 'Consulting'
            break
        case 3:
            key = 'Column'
            break
    }
    return key
}

const getAdvanceList = async function ({
    dispatch,
    commit,
    state,
    rootState
}, params) {
    if (params.keyword) {
        params.is_search = 1
    } else {
        params.is_search = 0
    }
    const url = params.keyword ? api.home.advance_search : api.home.advance_list
    return getList({
        key: 'Advance',
        url: url,
        params,
        state,
        rootState,
        commit
    })
}

const getPlaybackList = async function ({
    dispatch,
    commit,
    state,
    rootState
}, params) {
    const url = params.keyword ? api.home.playback_search_list : api.home.playback_list
    return getList({
        key: 'Playback',
        url,
        params,
        state,
        rootState,
        commit
    })
}

const getColumnList = async function ({
    dispatch,
    commit,
    state,
    rootState
}, params) {

    const url = params.keyword ? api.home.column_search_list : api.home.column_list
    return getList({
        key: 'Column',
        url,
        params,
        state,
        rootState,
        commit
    })
}

const getConsultingList = async function ({
    dispatch,
    commit,
    state,
    rootState
}, params) {
    const url = params.keyword ? api.home.consulting_search_list : api.home.consulting_list
    return getList({
        key: 'Consulting',
        url,
        params,
        state,
        rootState,
        commit
    })
}
const creditmeetinglist = async ({
    dispatch,
    commit,
    state,
    rootState
}, params) => {
    const url = api.home.creditmeetinglist
    return getList({
        key: 'credit',
        url,
        params,
        state,
        rootState,
        commit
    })

}
const getCollegeList = async function ({
    dispatch,
    commit,
    state,
    rootState
}, params) {
    return new Promise((resolve, reject) => {
        Request.post(api.home.collegetree, {
            college_id: state.collegeID,
        }).then(res => {
            const {
                data
            } = res
            resolve(data)
        })
    })
}
const getDiseaselist = async function ({
    dispatch,
    commit,
    state,
    rootState
}, params) {
    return new Promise((resolve, reject) => {
        Request.post(api.common.diseaselist, {
            college_id: state.collegeID,
        }).then(res => {
            const {
                data
            } = res
            resolve(data)
        })
    })
}

const contentlist = async function ({
    dispatch,
    commit,
    state,
    rootState
}, params) {
    return new Promise((resolve, reject) => {
        Request.post(api.home.contentlist, {
            college_id: state.collegeID,
            disease_id: state.diseaseID
        }).then(res => {
            const {
                data
            } = res
            resolve(data)
        })
    })
}
const getAllCollege = function ({
    dispatch,
    commit,
    state,
    rootState
}, params) {
    return new Promise((resolve, reject) => {
        // const collegeListCache = localStorage.getItem('collegeListCache')
        // let collegeList = null
        // if (collegeListCache) {
        //     collegeList = JSON.parse(collegeListCache)
        //     resolve({
        //         data: collegeList
        //     })
        // }
        Request.post(api.home.collegelist, {
            college_id: state.collegeID,
        }).then(res => {
            // localStorage.setItem('collegeListCache', JSON.stringify(res.data))
            // if (!collegeList) {
            resolve(res)
            // } else if (collegeList.length !== res.data.length) { // 判断长度并不保险 考虑到性能跟业务暂时先这样
            //     location.reload()
            // }
        }).catch(e => reject(e))
    })
}

const getBannerData = async ({
    dispatch,
    commit,
    state,
    rootState
}, params) => {
    return new Promise((resolve, reject) => {
        Request.post(api.home.getads, {
            college: state.collegeID,
            disease_id: state.diseaseID,
            platform: 8
        }).then(res => {
            commit('setBannerData', res.data)
            resolve()
        })
    })
}
const getRecommend = async ({
    dispatch,
    commit,
    state,
    rootState
}, params) => {
    return new Promise((resolve, reject) => {
        Request.post(api.home.recommend, params).then(res => {
            resolve(res)
        })
    })

}
// menuicon
const getMenuicon = async ({
    dispatch,
    commit,
    state,
    rootState
}, params) => {
    return new Promise((resolve, reject) => {
        Request.post(api.home.menuicon, params).then(res => {
            resolve(res)
        })
    })

}
const allCourse = async ({
    dispatch,
    commit,
    state,
    rootState
}, params) => {
    return new Promise((resolve, reject) => {
        Request.post(api.home.allcourse, params).then(res => {
            resolve(res)
        })
    })

}
const informationList = async ({
    dispatch,
    commit,
    state,
    rootState
}, params) => {
    return new Promise((resolve, reject) => {
        Request.post(api.home.informationList, params).then(res => {
            resolve(res)
        })
    })

}


export default {
    getMenuicon,
    getRecommend,
    getBannerData,
    getAdvanceList,
    getPlaybackList,
    getColumnList,
    getConsultingList,
    getCollegeList,
    getAllCollege,
    userfoucstatus,
    courselist,
    seriescourselist,
    coursedetail,
    seriescoursedetail,
    searchCourse,
    searchSeriesCourse,
    surgicalList,
    getDiseaselist,
    allCourse,
    contentlist,
    informationList,
    creditmeetinglist
}