export default {
  showLoading(state) {
    state.showLoading = true;
  },
  hideLoading(state) {
    state.showLoading = false;
  },
  setShowTabbar(state, showTabbar) {
    state.showTabbar = showTabbar;
  },
  setShowLogoutAlert(state, showLogoutAlert) {
    state.showLogoutAlert = showLogoutAlert;
  },
  setInApp(state, isInApp) {
    state.isInApp = isInApp;
  },
  setShowHeaderAppTips(state, showHeaderAppTips) {
    state.showHeaderAppTips = showHeaderAppTips;
  },
  setContentLeft(state, contentLeft) {
    state.contentLeft = contentLeft;
  },
  setContentRight(state, contentRight) {
    state.contentRight = contentRight;
  },
  setContentHeight(state, contentHeight) {
    state.contentHeight = contentHeight;
  },
  setCanGoback(state, canGoback) {
    state.canGoback = canGoback;
  },
  setTabbarSelectedIndex(state, tabbarSelectedIndex) {
    state.tabbarSelectedIndex = tabbarSelectedIndex;
  },
  setTabbarCollege(state, tabbarCollege) {
    state.tabbarCollege = tabbarCollege;
  },
  setKeepAlive(state, keepAlive) {
    console.log(state.aliveList, "keepAlive")
    if (!state.aliveList.some(item => item === keepAlive[0])) {
      state.aliveList.push(keepAlive[0])
    }
    console.log(state)
  },
  deleteKeepAlive(state, routeName) {
    if (state.aliveList.includes(routeName)) {
      state.aliveList = state.aliveList.reduce((res, item) => {
        if (item !== routeName) {
          res.push(item)
        }
        return res
      }, [])
    }
  },
  clearKeepAlive(state) {
    state.aliveList = []
  },
  setHomeScrollTop(state, top) {
    state.homeScrollTop = top;
  },
  setAppMessage(state, message) {
    state.appMessage = message;
  },
  setIfReviewTime(state, flag) {
    state.ifReviewTime = flag;
  },
  setAppResume(state, isResume) {
    state.isAppResume = isResume
  },
  setisDeptUnion(state, isDeptUnion) {
    state.isDeptUnion = isDeptUnion
  },

  setverticallHung(state, verticallHung) {
    state.verticallHung = verticallHung
  },
  // deptUnionId
  setdeptUnionId(state, deptUnionId) {
    state.deptUnionId = deptUnionId
  },
  setSellerCode(state, sellerCode) {
    state.sellerCode = sellerCode
  },
  setSingleSubjectInfo(state, singleSubjectInfo) {
    state.singleSubjectInfo = singleSubjectInfo
    console.log(singleSubjectInfo, state.singleSubjectInfo)
  }
};