import store from '@/store'
const SESSION_ID = 'docbook-session'
const DOMAIN = 'docbook.com.cn'
    // 'docbook.com.cn'

export function handleCookie() {
    const result = parseCookie(document.cookie)
    const userid = store.getters.userID
    if (Object.keys(result).length > 0) { // 有结果
        result['refresh_token'] = result['refrsh-token']
        result['sys_token'] = result['accpet-encoding']
        if (result.userid != userid) store.commit('user/setUserLoginData', result)
    }
    else {
        clearCookie()
        if (userid) setCookie()
    }
}

export function setCookie() {
    // const refresh_token = localStorage.getItem('refresh_token')
    // const sys_token = localStorage.getItem('sys_token')
    // const userid = localStorage.getItem('userid')
    // const token = localStorage.getItem('token')
    // const openid = localStorage.getItem('openid')
    // const unionid = localStorage.getItem('unionid')

    const { refresh_token, sys_token, userid, token, openid, unionid} = store.state.user.tokenInfo

    const expires = new Date()
    expires.setHours(expires.getHours() + (24 * 30))
    document.cookie = `${SESSION_ID}=${userid}-${token}-${refresh_token}-${sys_token}-${unionid}-${openid}; expires=${expires.toUTCString()}; path=/; domain=${DOMAIN}`
}

export function clearCookie() {
    document.cookie = `${SESSION_ID}=0; expires=${new Date().toUTCString()}; path=/; domain=${DOMAIN}`
}

export function getCookie(cookie, name) {
    if (!cookie) return ''
    const arrStr = cookie.split('; ')
    for (let i = 0; i < arrStr.length; i++) {
        const temp = arrStr[i].split('=')
        if (temp[0] == name) return decodeURIComponent(temp[1])
    }
    return ''
}

const keys = ['userid', 'token', 'refrsh-token', 'accpet-encoding', 'unionid', 'openid']
export function parseCookie(cookie) {
    if (!cookie) return {}
    const session = getCookie(cookie, SESSION_ID)
    if (!session) return {}
    const cookieArr = session.split('=').pop().split('-')
    if (cookieArr.length < 4) return {}

    return cookieArr.reduce((param, cur, index) => {
        const key = keys[index]
        param[key] = cur
        return param
    }, {})
}