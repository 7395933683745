<template>
    <div class="expert-task-header">
        <div class="navbar" :style="{ width: contentWidth, left: contentLeft }">
            <div class="show-hide" @click="show = true" style="justify-content: flex-start">
                <img src="@images/expertTask/show.png" alt />
                <span>更多</span>
            </div>

            <div class="task-number" v-if="all_accomplish_num" @click="goTaskList">
                <img src="@images/expertTask/task-number.svg" />
                <span class="task-tag" v-if="notAccomplishNum">{{ notAccomplishNum }}</span>
            </div>
            <div class="people-center" @click="goHome" style="justify-content: flex-end">
                <img src="@images/expertTask/penpleCenter.png" alt />
                <span>我的首页</span>
            </div>
        </div>
        <van-popup
            v-model="show"
            position="left"
            :overlay-style="{ height: slideHeight - navbarHeight + 'px', top: navbarHeight + 'px' }"
        >
            <div class="popup-slide" :style="{ height: slideHeight + 'px' }">
                <div class="slide-left">
                    <div
                        class="show-hide"
                        @click="show = false"
                        style="width: 7.18rem; height: 1.64rem; background: #141923"
                    >
                        <img src="@images/expertTask/hide.png" alt />
                        <span>收起更多</span>
                    </div>
                    <div class="silde-ul">
                        <div
                            :class="['li', index == selectIndex ? 'active' : '']"
                            v-for="(item, index) in list"
                            :key="index"
                            @click="path(item, index)"
                        >
                            <span>{{ item.title }}</span>
                        </div>
                    </div>
                    <div class="contact-number">
                        <div class="contact-number-tel">
                            <img src="@images/expertTask/tel.png" alt />
                            <a href="tel:4008387010">400-838-7010</a>
                        </div>

                        <div
                            v-if="!isInApp"
                            class="contact-number-change-acount"
                            @click="changeLogin()"
                        >
                            <span>切换账号</span>
                            <img src="@images/expertTask/right-arrow.png" alt />
                        </div>
                    </div>
                </div>
            </div>
        </van-popup>
        <van-dialog
            v-model="inviteShow"
            show-cancel-button
            :confirmButtonText="$ct('大会官网')"
            :cancelButtonText="$ct('更换手机')"
            confirmButtonColor="#0089FF"
            cancelButtonColor="#0089FF"
            @close="inviteClose"
            @confirm="inviteConfirm"
        >
            <div class="dialog_content">
                <div class="dialog_tip">
                    <span v-translate>您登录的手机号非邀约讲课专家，请更换其它账号登录</span>!
                </div>
                <div class="dialog_tel" v-if="meeting_group_contact">
                    <span v-translate>如有问题，请联系会务组：{{ meeting_group_contact }}</span>
                </div>
            </div>
        </van-dialog>
        <login :show="showLogin" @handleClose="handleLoginClose" :isShowClose="false"></login>
        <expert-select-list
            :list="multipleList"
            :show="isMultiple"
            @selectItem="selectItem"
        ></expert-select-list>
    </div>
</template>

<script>
import { mapState, mapGetters } from '@utils/vuex'
import Request from '@request'
import api from '@api'
import ExpertSelectList from '@components/ExpertSelectList'
import Login from '@components/Login'
import Utils from '@utils'
export default {
    components: { Login, ExpertSelectList },
    computed: {
        ...mapGetters(['contentWidth', 'contentLeft', 'isLogin', 'isInApp']),
        ...mapState('expertTaskList', ['notAccomplishNum'])
    },
    data() {
        return {
            showLogin: false,
            show: false,
            slideHeight: 0,
            navbarHeight: 0,
            list: [],
            inviteShow: false,
            id: '',
            tcode: '',
            propaganda_url: '',
            meeting_group_contact: '',
            selectIndex: -1,
            multipleList: [],
            isMultiple: false,
            not_accomplishNumber: 0,
            all_accomplish_num: 0
        }
    },
    watch: {
        slideHeight(val) {
            this.slideHeight = val
            this.navbarHeight = document.querySelector('.navbar').getBoundingClientRect().height
        }
    },

    mounted() {
        this.$nextTick(() => {
            if (JSON.parse(localStorage.getItem('expertTaskLogin'))) {
                let obj = JSON.parse(localStorage.getItem('expertTaskLogin'))
                this.id = this.$route.query.id || Object.keys(obj)[0]
            } else {
                this.id = this.$route.query.id
            }
            this.slideHeight = document.querySelector('.wrap').getBoundingClientRect().height
            this.navbarHeight = document.querySelector('.navbar').getBoundingClientRect().height
            this.getTcode()
        })
    },
    methods: {
        // 选择嘉宾
        selectItem(data) {
            localStorage.setItem('guest_id', data.guest_id)
            this.isMultiple = false
            window.location.reload()
        },
        // 获取嘉宾列表
        getmultipleguest() {
            Request.post(api.expertTask.getmultipleguest, { id: this.$route.query.id }, false)
                .then(res => {
                    this.multipleList = res.data
                    this.isMultiple = true
                })
                .catch(e => {
                    this.$hideLoading()
                })
        },
        getTcode() {
            let expertTaskLogin = {}
            this.tcode = this.$route.query.tcode
            this.id = this.$route.query.id
            if (localStorage.getItem('expertTaskLogin')) {
                expertTaskLogin = JSON.parse(localStorage.getItem('expertTaskLogin'))
                if (this.id) {
                    if (Object.keys(expertTaskLogin)[0] != this.id) {
                        let obj = {}
                        obj[this.id] = this.tcode || ''
                        localStorage.setItem('expertTaskLogin', JSON.stringify(obj))
                    }
                } else {
                    this.id = Object.keys(expertTaskLogin)[0]
                }

                if (!this.tcode) {
                    if (expertTaskLogin && expertTaskLogin[this.id]) {
                        this.tcode = expertTaskLogin[this.id] || ''
                    }
                } else {
                    if (this.tcode != expertTaskLogin[this.id]) {
                        let obj = {}
                        obj[this.id] = this.tcode || ''
                        localStorage.setItem('expertTaskLogin', JSON.stringify(obj))
                    }
                }
            } else {
                let obj = {}
                obj[this.id] = this.tcode || ''
                localStorage.setItem('expertTaskLogin', JSON.stringify(obj))
                localStorage.setItem('tcodeTime', new Date().getTime())
            }
            if (localStorage.getItem('tcodeTime')) {
                let currenTime = new Date().getTime()
                let endTime = localStorage.getItem('tcodeTime')
                console.log(currenTime, endTime, currenTime - endTime, 1000000000)
                if (currenTime - endTime > 7200000) {
                    localStorage.removeItem('expertTaskLogin')
                    localStorage.setItem('tcodeTime', new Date().getTime())
                } else {
                    localStorage.setItem('tcodeTime', new Date().getTime())
                }
            } else {
                localStorage.setItem('tcodeTime', new Date().getTime())
            }

            this.checksubjectexpert()
            this.officalwebsite()
            this.getpage()
        },
        inviteClose() {
            this.inviteShow = false
            this.showLogin = true
        },
        getpage() {
            Request.post(api.expertTask.loginpage, {
                id: this.id
            }).then(response => {
                this.meeting_group_contact = response.data.meeting_group_contact
            })
        },
        officalwebsite() {
            // officalwebsite
            Request.post(api.expertTask.officalwebsite, { id: this.id }, false)
                .then(res => {
                    this.propaganda_url = res.data.offical_website
                })
                .catch(e => {
                    this.$hideLoading()
                })
        },
        inviteConfirm() {
            // if (this.isInApp) {
            //     this.$nativeBridge.sendMessage(
            //         new NativeMessagePush({
            //             pageName: 'newWeb',
            //             params: {
            //                 url: this.propaganda_url
            //             }
            //         })
            //     )
            // } else {
            window.location.href = this.propaganda_url
            // }
        },
        changeLogin() {
            if (JSON.parse(localStorage.getItem('expertTaskLogin'))) {
                let obj = JSON.parse(localStorage.getItem('expertTaskLogin'))
                let key = Object.keys(obj)[0]
                let newObj = {}
                newObj[Object.keys(obj)[0]] = ''
                localStorage.setItem('expertTaskLogin', JSON.stringify(newObj))
                this.show = false
            }
            this.$store.dispatch('user/logout')
            this.showLogin = true
        },
        checksubjectexpert() {
            let params = {}
            if (this.tcode) {
                params = {
                    id: this.id,
                    tcode: this.tcode,
                    userid: ''
                }
            } else {
                params = {
                    id: this.id
                }
            }
            Request.post(api.expertTask.checksubjectexpert, params, false)
                .then(res => {
                    this.topmenu()
                    this.showLogin = false
                })
                .catch(e => {
                    if (e == 4717) {
                        this.inviteShow = true
                    } else if (e == 4777) {
                        this.getmultipleguest()
                    } else {
                        this.showLogin = true
                    }
                    this.$hideLoading()
                })
        },
        goTaskList() {
            this.$router.push({
                path: '/taskList',
                query: {
                    id: this.id,
                    tcode: this.tcode,
                    isShowHeader: 1
                }
            })
        },
        goHome() {
            this.show = false
            if (this.$route.query.tcode) {
                this.$router.replace({
                    path: '/expertTaskList',
                    query: {
                        id: this.id,
                        tcode: this.tcode
                    }
                })
            } else {
                this.$router.replace({
                    path: '/expertTaskList',
                    query: {
                        id: this.id
                    }
                })
            }
        },
        handleLoginClose() {
            if (this.isLogin) {
                if (this.$route.name == 'expertTaskList' && this.tcode) {
                    this.$router.replace({
                        path: '/expertTaskList',
                        query: {
                            id: this.id
                        }
                    })
                } else {
                    window.location.reload()
                }
            }
            this.showLogin = false
        },
        topmenu() {
            Request.post(api.expertTask.topmenu, {
                id: this.id,
                tcode: this.tcode
            }).then(res => {
                let propagandaList = []
                for (let i = 0; i < res.data.menu.length; i++) {
                    let data = res.data.menu[i]
                    if (data.type == 3 && res.data.part_type == 3) {
                    } else {
                        propagandaList.push(data)
                    }
                }
                this.list = propagandaList

                this.partType = res.data.part_type
                // /notAccomplishNum
                this.$store.commit(
                    'expertTaskList/setNotAccomplishNum',
                    res.data.not_accomplish_num
                )
                this.all_accomplish_num = res.data.all_accomplish_num
                // res.data.is_multiple
                // this.list = [
                //     ...[
                //         // {
                //         //     title: res.data.test_room_name,
                //         //     is_top_menu: 1,
                //         //     content_url: '试片室'
                //         // },
                //         // {
                //         //     title: '参会入口',
                //         //     is_top_menu: 1,
                //         //     content_url: '参会入口'
                //         // }
                //     ],
                //     ...this.list
                // ]
                if (res.data.is_multiple == 1)
                    this.list = [
                        ...this.list,
                        ...[
                            {
                                title: '切换任务单',
                                is_top_menu: 1,
                                content_url: '切换任务单'
                            }
                        ]
                    ]
                this.$hideLoading()
            })
        },
        routerPath(url, is_top_menu) {
            if (!url) return false
            let path = ''
            let urlAry = url.split('/')
            path = urlAry[urlAry.length - 1]
            if (is_top_menu == 1) {
                if (/withdrawal/.test(path)) {
                    path.indexOf('?') != -1
                        ? this.$router.push(`${path}&isShowHeader=1&isTask=true`)
                        : this.$router.push(`${path}?isShowHeader=1&isTask=true`)
                } else {
                    path.indexOf('?') != -1
                        ? this.$router.push(`${path}&isShowHeader=1`)
                        : this.$router.push(`${path}?isShowHeader=1`)
                }
            } else {
                if (/withdrawal/.test(path)) {
                    path.indexOf('?') != -1
                        ? this.$router.push(`${path}&isTask=true`)
                        : this.$router.push(`${path}?isTask=true`)
                } else {
                    window.location.href = url
                }
            }
        },
        path(item, index) {
            this.show = false
            this.selectIndex = index
            if (/localhost/.test(item.content_url) || /release-m/.test(item.content_url)) {
                this.routerPath(item.content_url, item.is_top_menu)
            } else if (/m.docbook.com.cn/.test(item.content_url)) {
                this.routerPath(item.content_url, item.is_top_menu)
            } else if (item.type == 3) {
                this.$router.push({
                    path: '/specimenChamberLogin',
                    query: {
                        id: this.id,
                        isShowHeader: 1,
                        is_top_menu: item.is_top_menu
                    }
                })
            } else if (item.type == 2) {
                this.$router.push({
                    path: '/expertsSchedule',
                    query: {
                        id: this.id,
                        isShowHeader: 1,
                        is_top_menu: item.is_top_menu
                    }
                })
            } else if (item.content_url == '切换任务单') {
                this.getmultipleguest()
            } else if (item.type == 5) {
                window.location.href =
                    item.content_url + '&url=' + window.btoa(window.location.href)
            } else {
                if (item.is_top_menu == 1) {
                    this.$router.push({
                        path: 'expertIframe',
                        query: {
                            isShowHeader: 1,
                            url: item.content_url
                        }
                    })
                } else {
                    window.location.href = item.content_url
                }
            }
        }
    },
    beforeDestroy() {
        this.isMultiple = false
    }
}
</script>

<style scoped lang="scss">
.expert-task-header {
    position: relative;
    z-index: 2000;
}
.navbar {
    width: 100%;
    height: 1.46rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    background: linear-gradient(360deg, #3d4052 0%, #151923 100%);
}
.show-hide {
    padding-left: 0.47rem;
}
.people-center {
    padding-right: 0.47rem;
}
.show-hide,
.people-center {
    display: flex;
    align-items: center;
    width: 4rem;
    img {
        width: 0.62rem;
        height: 0.62rem;
        margin-right: 0.21rem;
    }
    span {
        font-size: 0.49rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
    }
}
::v-deep {
    .van-popup {
        overflow: hidden;
    }
}
.popup-slide {
    display: flex;
    flex-direction: column;
}
.slide-left {
    display: flex;
    flex-direction: column;
    width: 7.18rem;
    height: 100%;
    background: linear-gradient(360deg, #2e303f 0%, #151923 100%);
    .silde-ul {
        width: 100%;
        flex: 1;
        height: 0;
        overflow: auto;
        .li:nth-child(1) {
            border-top: 1px solid #2f3243;
        }
        .li {
            width: 100%;
            height: 1.85rem;
            padding-right: 0.62rem;
            border-bottom: 1px solid #2f3243;
            span {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 100%;
                height: 1.85rem;
                font-size: 0.64rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
            }
        }
        .active {
            background: #161923;
        }
    }
    .contact-number {
        width: 100%;
        height: 7rem;
        padding: 0.82rem 0;
        .contact-number-tel {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 6.15rem;
            height: 1.03rem;
            margin: 0 auto;
            // padding: 0 0.29rem;
            border-radius: 0.51rem;
            border: 0.02rem solid #979797;
            img {
                width: 0.54rem;
                height: 0.54rem;
                margin-right: 0.21rem;
            }
            a {
                font-size: 0.44 rem;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #ffffff;
            }
        }
        .contact-number-change-acount {
            margin-top: 0.82rem;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: 0.29rem;
                height: 0.49rem;
                margin-left: 0.31rem;
            }
            span {
                font-size: 0.57rem;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #ffffff;
            }
        }
    }
}
.task-number {
    position: relative;
    width: 0.68rem;
    height: 0.68rem;
    img {
        width: 0.68rem;
        height: 0.68rem;
    }
    .task-tag {
        position: absolute;
        right: -0.32rem;
        top: -0.32rem;
        width: 0.64rem;
        height: 0.64rem;
        text-align: center;
        line-height: 0.64rem;
        background: #f5222d;
        font-size: 0.43rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        border-radius: 100%;
    }
}
.dialog_content {
    padding: 0.6rem;
    box-sizing: border-box;
}

.dialog_tip {
    color: red;
    font-size: 0.55rem;
    font-family: PingFang SC;
    font-weight: bold;
}

.dialog_tel {
    display: flex;
    align-items: center;
    margin-top: 0.1rem;
    display: flex;
    font-size: 0.45rem;
    font-family: PingFang SC;
}
</style>