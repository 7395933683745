export default {
    getuserinfo: '/api/web/v3/doctor/getuserinfo', // 获取用户科室主页信息
    departmentmeetinglist: '/api/web/v3/meeting/departmentmeetinglist', //会议列表
    departmentcourse: '/api/web/v3/production/departmentcourse', // 课程列表
    departmentsurgicalList: '/api/web/v3/production/departmentsurgicallist', // 手术列表
    departmentinformation: '/api/web/v2/meeting/departmentinformation', // 资讯列表
    selectYiPuDepartmentsDoctorPage:
        '/MedicalCollege/departments/home/selectYiPuDepartmentsDoctorPage', // 科室主页认证成员分页(app列表)
    departmentsHomeDetails: '/MedicalCollege/departments/home/departmentsHomeDetails', //科室主页详情查询(app主页)
    addSubscribe: '/MedicalCollege/departments/home/addSubscribe',
    cancelSubscribe: '/MedicalCollege/departments/home/cancelSubscribe', // 订阅
    selectAllContent: '/MedicalCollege/departments/menu/selectAllContent', //查询全部菜单内容分页接口(app主页)
    getLiveMeeting: '/MedicalCollege/departments/menu/getLiveMeeting',
    saveDepartmentsApply: '/MedicalCollege/departments/apply/saveDepartmentsApply', // 提交申请创建科室主页申请记录
    selectDepartmentsAdvertisingPage:
        '/MedicalCollege/departments/advertising/selectDepartmentsAdvertisingList', // 查询学院科室广告(分页)
    selectDepartmentsHomePage: '/MedicalCollege/departments/home/selectPageByCollegeId', // 科室主页列表查询
}
