// import Vue from 'vue'
// import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace

VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

VueRouter.prototype.replace = function replace(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const modulesFiles = require.context('./modules', true, /\.js$/)

export default new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes: modulesFiles.keys().reduce((modules, modulePath) => [...modules, ...modulesFiles(modulePath).default], [])
})