

export default [
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录'
    },
    component: () => import('@/views/login')
  },
  {
    path: '/changePassword',
    name: 'changePassword',
    meta: {
      title: '更改密码'
    },
    component: () => import('@/views/password/changePassword')
  },
  {
    path: '/forgetPassword',
    name: 'forgetPassword',
    meta: {
      title: '重置密码'
    },
    component: () => import('@/views/password/forgetPassword')
  },
  {
    path: '/informationComplete',
    name: 'informationComplete',
    meta: {
      title: '完善信息'
    },
    component: () => import('@/views/informationComplete')
  },
  {
    path: '/bindMobile',
    name: 'bindMobile',
    meta: {
      title: '绑定手机号'
    },
    component: () => import('@/views/informationComplete/bindMobile')
  }
]