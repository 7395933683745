<template>
    <div>
        <van-index-bar class="my-self-bar__sidebar">
            <template v-for="(key, index) in Object.keys(nationalData)">
                <van-index-anchor :index="key" :key="index" />
                <div
                    v-for="(item, itemIndex) in nationalData[key]"
                    :key="key + itemIndex"
                    @click="choose(item)"
                    class="item-list"
                >
                    <!-- <van-cell :title="item.codeName + '-' + item.englishName"> -->
                    <van-cell :title="item.englishName">
                        <span>{{ '+' + item.areaNumber }}</span>
                        <span
                            class="f32"
                            slot="icon"
                            width="24"
                            height="24"
                            :style="{ 'background-position': item.position }"
                        ></span>
                    </van-cell>
                </div>
            </template>
        </van-index-bar>
    </div>
</template>
<script>
// import { IndexBar, IndexAnchor, Cell } from 'vant'
import nationalData from '@utils/nationalData/index'

export default {
    components: {
        // IndexBar,
        // IndexAnchor,
        // Cell
    },
    data() {
        return {
            nationalData
        }
    },

    created() {},
    mounted() {},
    methods: {
        choose(item) {
            this.$emit('handleSelect', item)
        }
    }
}
</script>
<style lang="scss" scoped>
.item-list {
    width: 90%;
    display: flex;
    cursor: pointer;
    align-items: center;
    img {
        margin-right: 0.3rem;
    }
    ::v-deep .van-cell {
        align-items: center;
    }
}

.my-self-bar__sidebar {
    ::v-deep {
        .van-index-bar__sidebar {
            position: absolute;
            top: 50%;
        }
        .van-index-anchor--sticky {
            position: fixed !important;
        }
    }
}

.back {
    width: 0.8rem;
    height: 1rem;
}

.nationnalName {
    color: #bebebe;
}
.f32 {
    background: url('../../assets/images/login/sprite_gj.png') no-repeat top left;
    width: 32px;
    height: 32px;
    display: inline-block;
    margin-right: 10px;
    // background-position: 0 0;
}
</style>