<template>
    <div @click.stop="toDownload" v-if="showHeaderAppTips" class="header-app-tips">
        <p class="van-ellipsis">{{ text ? text : $ct('下载APP，体验更多....') }}...</p>
        <img @click.stop="close" src="@images/common/appTipsClose.png" />
    </div>
</template>

<script>
import { mapGetters } from '@utils/vuex'
import os from '@utils/os'
export default {
    props: {
        text: {
            type: String,
            required: false
        }
    },
    computed: {
        ...mapGetters(['showHeaderAppTips']),
        isPC() {
            return os.isPC()
        }
    },
    methods: {
        close() {
            this.$store.commit('setShowHeaderAppTips', false)
        },
        toDownload() {
            if (this.isPC) {
                location.href = 'https://docbook.com.cn/download'
            } else {
                this.$router.push('/download')
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.header-app-tips {
    width: 100%;
    height: 1.5rem;
    background: #6089f3;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    > p {
        width: 78%;
        color: #fff;
        line-height: 1.5rem;
        text-align: center;
    }

    > img {
        width: 0.72rem;
        height: 0.72rem;
        position: absolute;
        right: 4%;
        top: 0.42rem;
        // z-index: 1000;
        cursor: pointer;
    }
}
</style>