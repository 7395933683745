export default {
    setSmall(state, isSmall) {
        state.isSmall = isSmall
    },
    setShowBar(state) {
        state.isShowBar = true
    },
    setHideBar(state) {
        state.isShowBar = false
    },
    setClarityOptions(state, clarityOptions) {
        state.clarityOptions = clarityOptions
    },
    toggleBarrage(state) {
        state.isBarrageOpen = !state.isBarrageOpen
    },
    startLikeAnimation(state) {
        state.likeAnimation = true
    },
    stopLikeAnimation(state) {
        state.likeAnimation = false
    },
    setDefaultComments(state, defaultComments) {
        const oldComments = [...state.defaultComments]
        const newComments = [...defaultComments]
        const comments = []

        if (oldComments.length > 0) {
            do {
                const randomNum = parseInt(Math.random() * 100) % 2
                oldComments.length > 0 && randomNum && comments.push(...oldComments.splice(0, 1))
                newComments.length > 0 && randomNum && comments.push(...newComments.splice(0, 1))
            } while (oldComments.length > 0 || newComments.length > 0)
            state.defaultComments = comments
        } else {
            state.defaultComments = defaultComments
        }

    },
    addNewComment(state, newComment) {
        if (Array.isArray(newComment)) {
            state.newComment = newComment[0]
        }
        else {
            state.newComment = newComment
        }
    },
    clearComments(state) {
        state.defaultComments = []
    },
    setDefaultLikes(state, defaultLikes) {
        state.defaultLikes = defaultLikes
    },
    addNewLike(state, newLike) {
        state.newLike = newLike
    },
    clearLikes(state) {
        state.defaultLikes = []
    },
    setMarqueeData(state, marqueeData) {
        state.marqueeData = marqueeData
    },
    setRouterPath(state, path) {
        const {
            home,
            back
        } = path
        state.homePath = home
        state.backPath = back
    },
    setShowSpeed(state, showSpeed) {
        state.showSpeed = showSpeed
    },
    setShowPlayerSwitch(state, showPlayerSwitch) {
        state.showPlayerSwitch = showPlayerSwitch
    },
    setMeetingInfo(state, meetingInfo) {
        if (meetingInfo && meetingInfo.list) {
            state.meetingInfo = {
                index: meetingInfo.index,
                list: meetingInfo.list.map(info => {
                    return {
                        title: info.palybackname,
                        url: info.palybackurl,
                        id: info.l_r_id
                    }
                })
            }
        } else {
            state.meetingInfo = {}
            console.warn('meetingInfo 为空')
        }
    },
    setHiddenAllBarrage(state, hiddenAllBarrage) {
        state.hiddenAllBarrage = hiddenAllBarrage
    },
    setOperationInfo(state, operationInfo) {
        state.operationInfo = {
            index: operationInfo.index,
            allList: operationInfo.list,
            list: operationInfo.list
        }
        // .slice(operationInfo.index == 0 ? 0 : operationInfo.index - 1, operationInfo.index == 0 ? 3 : operationInfo.index + 2),
    },
    setOperationData(state, operationData) {
        state.operationData = operationData
    },
    setCurrentPlayTime(state, currentPlayTime) {
        state.currentPlayTime = currentPlayTime
    },
    setOperationTabIndex(state, index) {
        state.operationTabIndex = index
    },
    setIsPlayerDrag(state, isPlayerDrag) {
        state.isPlayerDrag = isPlayerDrag
    },
    clean(state) {
        state.isSmall = false
        state.clarityOptions = []
        state.isBarrageOpen = true
        state.likeAnimation = false
        state.defaultComments = []
        state.newComment = {}
        state.defaultLikes = []
        state.newLike = {}
        state.marqueeData = []
        state.hiddenAllBarrage = false
        state.showPlayerSwitch = true
        state.homePath = ''
        state.backPath = ''
    }
}