import store from '@/store'
export default {
    type: 'redirect',
    path: [{
        path: '/meetingHome',
        query: ['subject_id']
    }],
    handler(info) {
        return new Promise(async (resolve, reject) => {
            const {
                to,
                from,
                next
            } = info
            const meeting_id = await store.dispatch('meeting/checkSubject', {
                subject_id: to.query.subject_id
            })
            const query = {
                ...to.query,
                meeting_id
            }
            delete query['subject_id']

            resolve({
                ...to,
                query
            })
        })
    }
}