<template>
    <div class="block" v-if="data && data.length">
        <swiper :options="swiperOption" ref="mySwiper" class="my-swipe">
            <swiper-slide v-for="(item, index) in data" :key="index" :item="item">
                <img
                    :key="index"
                    :src="item.img + '?x-oss-process=image/resize,w_750'"
                    style="width: 100%"
                    :id="index"
                />
            </swiper-slide>
        </swiper>
    </div>
</template>
<script>
import os from '@utils/os'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
export default {
    components: {
        Swiper,
        SwiperSlide
    },
    props: {
        data: {
            type: Array,
            require: false,
            default: _ => []
        }
    },
    data() {
        let _this = this
        return {
            swiperOption: {
                direction: 'horizontal',
                loop: true,
                loopedSlides: 3,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false
                },
                slidesPerView: 'auto',
                centeredSlides: true,
                on: {
                    click(swiper, e) {
                        _this.$emit('itemClick', e.target.id)
                    }
                }
            },
            timer: undefined
        }
    },
    watch: {
        data(val) {
            this.$nextTick(() => {
                console.log('val', val)
                this.$refs.mySwiper.swiperInstance.update()
            })
        }
    },
    created() {},
    destroyed() {
        this.timer && clearInterval(this.timer)
    },
    mounted() {
        if (os.isPC()) {
            // 修复windows展示错误
            let index = 0
            this.timer = setInterval(() => {
                if (++index == 50) {
                    clearInterval(this.timer)
                    this.timer = undefined
                }
                this.$refs.mySwiper.swiperInstance.update()
            }, 100)
        }
    },
    computed: {},
    methods: {
        // itemClick() {
        //   console.log(11111)
        // },
        // play(live_info) {
        //   // this.$emit('play', live_info)
        // },
    }
}
</script>
<style scoped lang="scss">
.my-swipe {
    height: 5.7rem;
    width: 100%;
    overflow: hidden;
}

.swiper-slide {
    width: 85%;
    overflow: hidden;
    // height: 5.7rem;
    transform: translate3d(0, 0, 0);
    border-radius: 4px;

    img {
        cursor: pointer;
        width: 100%;
        // height: 100%;
        transform: translate3d(0, 0, 0);
        border-radius: 4px;
    }
}

.swiper-slide-next {
    transform: scale(0.9);
}

.swiper-slide-prev {
    transform: scale(0.9);
}
</style>