// handlers 授权需要永远在第一个
class RouterHandler {
    constructor(options) {
        const {
            handlers
        } = options

        this.handlers = handlers
        this.handlerIndex = 0
        this.routerInfo = {}
        this.handlerMap = {}
        this.handlerKeyMap = {}
        this._handleMap(handlers)
    }

    _handleMap(handlers) {
        if (!handlers) return
        let i = 0
        this.handlerKeyMap = handlers.reduce((map, cur) => {
            cur.index = this.handlerIndex++
            this.handlerMap[cur.type] = cur
            cur.path && cur.path.forEach(item => {
                let path = item
                const isPathObject = Object.prototype.toString.call(path) === '[object Object]'
                if (isPathObject) path = item.path // 如果是对象
                if (!map[path]) map[path] = {}
                const keyMap = map[path]
                keyMap[cur.type] = {
                    ...cur,
                    path: isPathObject ? item : path
                }
            })
            return map
        }, this.handlerKeyMap)
    }

    _getHandleFlag(handleInfoTo, to) {
        let handleFlag = false
        if (handleInfoTo.path !== to.path) {
            handleFlag = true
        } else {
            const toQueryKeys = Object.keys(to.query)
            const handleQuerykeys = Object.keys(handleInfoTo.query)
            if (toQueryKeys.length === handleQuerykeys.length) {
                const keys = Object.keys(handleInfoTo.query)
                for (let i = 0, len = keys.length; i < len; i++) {
                    const key = keys[i]
                    if (to.query[key] !== handleInfoTo.query[key]) {
                        handleFlag = true
                        break
                    }
                }
            } else {
                handleFlag = true
            }
        }
        return handleFlag
    }

    _checkQuery(checkQuery, toQuery) {
        if (!checkQuery) return flag
        let flag = false
        checkQuery.forEach(query=>{
            if (!flag && Object.hasOwnProperty.call(toQuery, query)) flag = true
        })
        return flag
    }

    addHandlers(handler) {
        this._handleMap(Array.isArray(handler) ? handler : [handler])
    }

    async handle(to, from, next) {
        const allMatchMap = this.handlerKeyMap['*'] || {}
        let keyMap = this.handlerKeyMap[to.path] || {}
        keyMap = {
            ...keyMap,
            ...allMatchMap
        }
        // console.log('参与本页面配置的config',keyMap)
        const keyMapKeys = Object.keys(keyMap)
        for (let i = 0; i < keyMapKeys.length; i++) {
            const key = keyMapKeys[i]
            const item = keyMap[key]
            if (item) {
                const {
                    extra
                } = item
                const isPathObject = Object.prototype.toString.call(item.path) === '[object Object]'
                if (isPathObject && !this._checkQuery(item.path.query, to.query)) {
                    delete keyMap[key]
                    continue
                }
                extra && Object.keys(extra).map(key => {
                    if (extra[key] === false) {
                        delete keyMap[key]
                    }
                })
            }
        }

        let handleInfo = {
            to,
            from,
            next
        }

        const keys = Object.keys(keyMap).sort((a,b)=>keyMap[a].index-keyMap[b].index)
        for (let i = 0, len = keys.length; i < len; i++) {
            const handlerKey = keys[i]
            if (keyMap[handlerKey]) {
                const handler = this.handlerMap[handlerKey]
                if (to.path === handleInfo.to.path) {
                    const result = await handler.handler(handleInfo)
                    if (result) {
                        handleInfo = {
                            to: result,
                            from,
                            next
                        }
                    }
                } else break
            }
        }

        const handleInfoTo = handleInfo.to
        if (this._getHandleFlag(handleInfoTo, to)) next(handleInfoTo)
        else next()
    }
}

export default RouterHandler