export default [{
  path: '/openingPublicity',
  name: 'openingPublicity',
  meta: {
    title: '科普创作'
  },
  component: () => import('@/views/openingPublicity')
},
{
  path: '/openingPublicitySuccess',
  name: 'openingPublicitySuccess',
  meta: {
    title: '科普创作'
  },
  component: () => import('@/views/openingPublicitySuccess')
}]