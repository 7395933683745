<template>
  <div class="subject-select-list" v-if="show" @click="$emit('cancel')">
    <!-- <van-overlay :show="show" @click="$emit('cancel')" class="subject-select-list-overlay"> -->
    <div class="wrapper-dialog">
      <div class="wrapper-header">
        <img class="subject-select-text" src="@images/common/subject_select_text.svg" alt />
      </div>
      <!-- <div class="subject-list"> -->
      <div class="subject-list-scroll">
        <div
          class="subject-list-item"
          v-for="item in data"
          :key="item.college_id"
          @click.stop="goCompany(item)"
        >
          <div class="subject-list-item-name">
            <span class="van-ellipsis">{{item.college_name}}</span>
          </div>
          <div class="subject-list-item-btn">
            <span>进入</span>
            <img src="@images/common/goIn.svg" alt />
          </div>
        </div>
        <!-- </div> -->
      </div>
    </div>
    <!-- </van-overlay> -->
  </div>
</template>

<script>
export default {
    props: {
        show: {},
        data: {}
    },
    methods: {
        goCompany(item) {
            this.$router.push({
                path: '/encyclopediasHome',
                query: {
                    college_id: item.college_id,
                    college_name: item.college_name
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.subject-select-list {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 222222;
}
.wrapper-dialog {
    display: flex;
    flex-direction: column;
    width: 13.89rem;
    padding: 0.38rem 0.64rem 0 0.64rem;
    background: linear-gradient(180deg, #b4dcff 0%, #ffffff 100%);
    border-radius: 0.43rem;

    .wrapper-header {
        display: flex;
        // align-items: center;
        height: 2.44rem;
        justify-content: space-between;
        background: url('~@images/common/subject_select.svg') no-repeat;
        background-position: 100% 0;
        background-size: 2.76rem 3.04rem;
        .subject-select-text {
            margin-top: -0.42rem;
            width: 6.81rem;
        }
    }
    // .subject-list {
    //     width: 100%;
    //     height: 5rem;

    .subject-list-scroll {
        width: 100%;
        max-height: 11.33rem;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }
    .subject-list-item {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0.43rem;
        width: 100%;
        height: 2.14rem;
        padding: 0 0.56rem 0 0.85rem;
        background: linear-gradient(360deg, #ebf1ff 0%, #ffffff 100%);
        box-shadow: 0rem 0.09rem 0.26rem 0rem rgba(73, 86, 139, 0.1);
        border-radius: 0.26rem;
        border: 0.02rem solid #bbdaff;
        .subject-list-item-name {
            display: flex;
            flex: 1;
            span {
                width: 8rem;
                font-size: 0.68rem;
                font-weight: 400;
                color: #222222;
            }
        }
        .subject-list-item-btn {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            span {
                font-size: 0.6rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #0089ff;
                margin-right: 0.3rem;
            }
            img {
                width: 0.73rem;
                height: 0.73rem;
            }
        }
    }
    .subject-list-item:last-child {
        margin-bottom: 0.85rem;
    }
    // }
}
</style>