<template>
    <div class="select-channel">
        <!-- <van-popup
            v-model="showPopupBox"
            position="bottom"
            class="popup-box"
            closeable
            :style="{ width: contentWidth, left: contentLeft }"
            @closed="coursePopClosed"
            @click-overlay="overlayPopup"
        > -->
        <div class="cut-couser">
            <p>全部频道<span>点击进入频道</span></p>
        </div>

        <ul class="popup-list">
            <li
                v-for="(disease, index) in diseaselist"
                :key="index"
                :class="[diseaseID == disease.disease_id ? 'popup-li-active' : '', 'popup-li']"
                :style="'width: ' + dealNumFontSize(disease)"
                @click="changePopupCollege(disease)"
            >
                <p
                    :style="'width: ' + dealNumFontWidth(disease)"
                    :class="[diseaseID == disease.disease_id ? 'popup-p-active' : '', 'popup-p']"
                >
                    {{ disease.disease_name }}
                </p>
            </li>
        </ul>
        <!-- </van-popup> -->
    </div>
</template>
<script>
import { mapGetters, mapState } from '@utils/vuex'
export default {
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isCourseClosed: true,
            imgshow: true,
            showPopupBox: false
        }
    },
    computed: {
        ...mapState('home', ['allCollege', 'collegeID', 'diseaselist', 'diseaseID']),
        ...mapGetters(['contentWidth', 'contentLeft'])
    },
    mounted() {
        this.$hideLoading()
        console.log(this.collegeID, this.diseaseID)
    },
    methods: {
        changePopupCollege(item) {
            console.log(item)
            // this.$store.commit('home/setDiseaseID', item.disease_id)
            this.$emit('close', item)
        },
        dealNumFontSize(num) {
            let numLength = num.disease_name.toString().length
            if (numLength <= 5) {
                return '4.27rem'
            } else if (5 < numLength && numLength <= 12) {
                return '9.39rem'
            } else if (numLength > 12) {
                return '14.72rem'
            }
        },
        dealNumFontWidth(num) {
            if (num.disease_name.toString().length > 12) {
                return '12rem'
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.select-channel {
    width: 100%;
    height: 100%;
    background: #fff;
}
.popup-box ::v-deep {
    position: absolute;
    top: 0rem;
}
.cut-couser {
    display: flex;
    justify-content: space-between;
    padding: 0 0.64rem;
    margin: 0.55rem 0 1.41rem 0;
    p {
        font-size: 0.85rem;
        font-weight: 600;
        color: #222222;
        span {
            margin-left: 0.43rem;
            font-size: 0.47rem;
            font-weight: 400;
            color: #888888;
        }
    }
}
.popup-list {
    padding: 0 0rem 1.28rem 0.64rem;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    .popup-li {
        cursor: pointer;
        display: flex;
        margin: 0 0.85rem 0.64rem 0rem;
        height: 1.28rem;
        border-radius: 0.64rem;
        background: #f1f1f5;
        .popup-p {
            margin: auto;
            font-size: 0.6rem;
            font-weight: 400;
            line-height: 0.7rem;
            color: #222222;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
        .popup-p-active {
            color: #ffffff;
        }
    }
    .popup-li-active {
        background: #0089ff;
    }
}
.active-img {
    transform: rotate(180deg);
}
</style>