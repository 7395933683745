

export default [
  {
    path: '/meetingPlaybackDetail',
    name: 'meetingPlaybackDetail',
    meta: {
        title: '直播回放'
    },
    component: () => import('@/views/meetingPlaybackDetail')
  }
]