<template>
    <div class="agreement">
        <van-checkbox
            v-model="checked"
            class="agreement-checkbox"
            @change="handleChange"
            v-bind:checked="checked"
        >
            <span class="checkbox-span">{{ $ct('同意') }}</span>
            <template v-for="(agreement, index) in agreements">
                <span :key="index" @click.stop="service_notice(agreement)" class="agree-tiao"
                    >《{{ agreement.agreement_name }}》</span
                >
            </template>
        </van-checkbox>
    </div>
</template>
<script>
// import { Checkbox, CheckboxGroup } from 'vant'
export default {
    model: {
        prop: 'checked',
        event: 'handleCheck'
    },
    props: {
        agreementId: {
            type: Number,
            require: true
        }
    },
    data() {
        return {
            agreements: [],
            checked: null,
            checked1: true
        }
    },
    components: {
        // Checkbox
    },
    watch: {
        agreementId(val) {
            this.getAgreement(val)
        }
    },
    created() {
        this.getAgreement(this.agreementId)
    },
    methods: {
        handleChange() {
            this.$emit('handleCheck', this.checked)
        },
        getAgreement(id) {
            this.$store
                .dispatch('getAgreement', {
                    id: id
                })
                .then(res => {
                    this.agreements = res.data
                })
        },
        service_notice(agreement) {
            this.$router.push('/privacyTreaty?agreement_id=' + agreement.agreement_id)
        }
    }
}
</script>
<style lang="scss" scoped>
@import '@styles/variables.scss';
.agreement ::v-deep {
    .van-checkbox {
        align-items: flex-start !important;
        .van-checkbox__label {
            margin-top: -3px;
        }
    }
}
.agreement {
    margin-top: 0.7rem;
}
.agreement-checkbox {
    ::v-deep {
        .van-checkbox__label {
            margin-left: 0.7rem;
        }
        .van-checkbox__icon {
            font-size: 0.64rem !important;
            -webkit-box-flex: 0;
            -webkit-flex: none;
            flex: none;
            height: 1 em;
            font-size: 20px;
            line-height: 1em;
            cursor: pointer;
        }
    }
}
.checkbox-span {
    font-size: 0.46rem !important;
    color: #999 !important;
}

.agree-tiao {
    display: inline;
    width: auto;
    margin-left: 0.2rem;
    margin-bottom: 0.3rem;
    font-size: 0.46rem;
    color: #0089ff;
}
</style>