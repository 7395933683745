import Request from "@request";
import api from "@api";
import os from '@utils/os'
import {
    NativeMessageNotice
} from '@utils/nativeBridge/nativeMessage'

export default {
    orderCreate({
        dispatch,
        commit,
        state,
        rootState
    }, params) {

        return new Promise((resolve, reject) => {
            if (os.isIOS() && rootState.common.isInApp) {
                vant.Dialog({
                        message: '请点击下方【立即购买】按钮\n在浏览器中购买',
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: '立即购买',
                        getContainer: 'body',
                    }).then(() => {

                        rootState.nativeBridge.nativeBridge.sendMessage(new NativeMessageNotice({
                            type: 'openBrowser',
                            params: {
                                url: window.location.href
                            }
                        }))

                        reject()
                    })
                    .catch(() => {

                    })

            } else {
                Request.post(api.order.orderCreate, params).then((res) => {
                    resolve(res);
                })
            }

        })
    },
    detail({
        dispatch,
        commit,
        state,
        rootState
    }, params) {
        return new Promise((resolve, reject) => {
            Request.post(api.order.detail, params).then(res => {
                resolve(res)
            }).catch(() => {
                reject()
            })
        })
    },
    charge({
        dispatch,
        commit,
        state,
        rootState
    }, params) {
        return new Promise((resolve, reject) => {
            Request.post(api.order.charge, params).then(res => {
                resolve(res)
            }).catch((status) => {
                reject(status)
            })
        })
    },
    lists({
        dispatch,
        commit,
        state,
        rootState
    }, params) {
        return new Promise((resolve, reject) => {
            Request.post(api.order.lists, params).then(res => {
                resolve(res)
            }).catch(() => {
                reject()
            })
        })
    },
    delete({
        dispatch,
        commit,
        state,
        rootState
    }, params) {
        return new Promise((resolve, reject) => {
            Request.post(api.order.delete, params).then(res => {
                resolve()
            }).catch(() => {
                reject()
            })
        })
    },
    revoke({
        dispatch,
        commit,
        state,
        rootState
    }, params) {
        return new Promise((resolve, reject) => {
            Request.post(api.order.revoke, params).then(res => {
                resolve()
            }).catch(() => {
                reject()
            })
        })
    },
    retrieve({
        dispatch,
        commit,
        state,
        rootState
    }, params) {
        return new Promise((resolve, reject) => {
            Request.post(api.order.retrieve, params).then(res => {
                resolve(res)
            }).catch(() => {
                reject()
            })
        })
    },
}