// import {
//     Dialog
// } from 'vant'
import router from '@/router'
import {
    NativeMessageNotice
} from '@utils/nativeBridge/nativeMessage'
export default {
    login({
        dispatch,
        commit,
        state,
        rootState
    }, params) {
        state.socket.login()
        if (!state.isListeningAccount) {
            dispatch('setIsListeningAccount', true)
        }

        commit('setIsLogin', true)
    },
    setIsListeningAccount({
        dispatch,
        commit,
        state,
        rootState
    }, isListeningAccount) {
        return new Promise((resolve, reject) => {
            if (isListeningAccount) {
                state.socket.addComponents([{
                    type: 'changeAccount',
                    handler() {
                        location.reload()
                    }
                }, {
                    type: 'outsideAccount',
                    handler() {
                        dispatch('user/logout', {
                            elsewhere: true
                        }, {
                            root: true
                        })
                        // if (rootState.common.isInApp) {
                        //     rootState.nativeBridge.nativeBridge.sendMessage(new NativeMessageNotice({
                        //         type: 'logout',
                        //         params: {
                        //             elsewhere: true
                        //         }
                        //     }))
                        // }
                        // vant.Dialog({
                        //     message: '您当前账号已在其它地方登录，是否重新登录？',
                        //     showConfirmButton: true,
                        //     showCancelButton: true,
                        //     className: 'shofOffLogin',
                        //     confirmButtonText: '切换帐号',
                        //     confirmButtonColor: '#0089FF',
                        //     cancelButtonText: '我知道了',
                        //     cancelButtonColor: '#888888',
                        //     overlayStyle: { zIndex: 9999 },
                        //     getContainer: 'body',

                        // }).then(() => {
                        //     router.push('/login')
                        // })
                        //     .catch(() => {
                        //         location.reload()
                        //     })
                    }
                }])
            } else {
                state.socket.removeComponents([{
                    type: 'changeAccount'
                }, {
                    type: 'outsideAccount'
                }])
            }

            commit('setIsListeningAccount', isListeningAccount)
            resolve()
        })

    },
}