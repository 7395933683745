import axios from 'axios'
import host from './host'
import md5 from 'js-md5'
import store from '@/store'
import api from './api'
import os from '@utils/os'
import vm from '@/main'
// import {
//   Toast
// } from 'vant'

const service = axios.create({
  baseURL: '',
  timeout: 10000,
})


service.interceptors.request.use(
  config => {
    const {
      method,
    } = config
    const headers = {
      ...config.headers
    }

    if (method === 'post') {
      const data = {
        ...config.data,
      }
      config = {
        ...config,
        headers,
        data,
      }
    }
    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.token) localStorage.setItem('token', res.token)
    if (res.status != 200) {

      if (res.message && response.config.showTips) vant.Toast.fail(vm.$ct(res.message))
      let url = response.config.url
      let urlArray = url.split('/')
      console.log(urlArray, urlArray.length - 1)
      if (urlArray[urlArray.length - 1] == 'login' || urlArray[urlArray.length - 1] == 'codeLogin' || urlArray[urlArray.length - 1] == 'initialpastpassword') {
        return Promise.reject(res)
      } else {
        return Promise.reject(res.status)
      }
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    vant.Toast.fail(error.message)
    return Promise.reject(error)
  }
)

class Request {
  static post(url, params, showTips = true) {

    url = url.indexOf('https') != -1 ? url : (params ? host.saasDomain : host.domain) + url
    //签名
    const timestamp = new Date() * 1
    let platform = 12
    const sign = md5('encryption=1&platform=' + platform + '&timestamp=' + timestamp)
    const userid = store.getters.userID
      // localStorage.getItem('userid')
    if (!params) {
      params = {}
    }
    params.platform = platform
    params.sign = sign
    params.userid = userid || 0
    params.imei = 'c7422902ad65e8c7'

    return service({
      url,
      method: 'post',
      data: params,
      showTips
    })
  }


  static get(url, params, showTips = true) {
    url = url.indexOf('https') != -1 ? url : (params ? host.saasDomain : host.domain) + url
    return service({
      url,
      method: 'get',
      params,
      showTips
    })
  }
}

export default Request