export default [{
    path: '/subjectTalkHomepage',
    name: 'subjectTalkHomepage',
    meta: {
        title: "话题主页",
    },
    component: () => import("@/views/subjectTalkHomepage"),
}, {
    path: '/subjectTalkList',
    name: 'subjectTalkList',
    meta: {
        title: "话题列表",
    },
    component: () => import("@/views/subjectTalkList"),
}]