import axios from 'axios'
import host from './host'
import md5 from 'js-md5'
import store from '@/store'
import api from './api'
import os from '@utils/os'
import vm from '@/main'
import Type from '@utils/type'
import { doEncrypt, doDecrypt } from '@utils/crypto'
// import {
//   Toast
// } from 'vant'

const encryptKey = 'encryptData'

const service = axios.create({
  baseURL: '',
  timeout: 10000,
})


service.interceptors.request.use(
  config => {
    const {
      method,
      gatConfig = {}
    } = config

    const { token, refresh_token, sys_token } = store.state.user.tokenInfo
    const headers = {
      ...config.headers,
      'TOKEN': token,
      'REFRSH-TOKEN': refresh_token,
      'ACCPET-ENCODING': sys_token,
    }

    if (gatConfig.encrypt) headers['X-Encrypt'] = encryptKey

    if (method === 'post') {
      const timestamp = new Date() * 1
      let platform = 8
      if (store.getters.isInApp && !os.isPC()) {
        if (os.isIOS()) {
          platform = 10
        } else if (os.isAndroid()) {
          platform = 12
        }
      }
     
      if (config.data && config.data.platform) platform = config.data.platform

      const sign = md5('platform=' + platform + '&timestamp=' + timestamp)
      const { userid, openid, unionid } = store.state.user.tokenInfo
      const guest_id = localStorage.getItem('guest_id')

      let data = {
        platform,
        sign,
        timestamp,
        userid,
        openid,
        unionid,
        guest_id: guest_id,
        is_wechat: os.isWechat() == 3 ? 2 : 1,
        ...config.data,
      }

      if (gatConfig.encrypt) {
        data = {
          [encryptKey]: doEncrypt(data)
        }
      }

      config = {
        ...config,
        headers,
        data: Object.keys(data).reduce((formData, curKey) => {
          if (!data[curKey]) data[curKey] = ''
          formData.append(curKey, data[curKey])
          return formData
        }, new FormData()),
      }

      // console.log(config, data)
    }
    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    const res = response.data
    const { gatConfig } = response.config
    if (res.token) localStorage.setItem('token', res.token)
    if (res.status != 200) {
      if (res.status == 401 || res.status == 4055) {
        store.dispatch('user/logout')
        res.message = '您的登录状态已失效，请重新登录！'
      }
      if (res.message && gatConfig.showTips) vant.Toast.fail(vm.$ct(res.message))
      let url = response.config.url
      let urlArray = url.split('/')
      // console.log(urlArray, urlArray.length - 1)
      if (urlArray[urlArray.length - 1] == 'login' || urlArray[urlArray.length - 1] == 'codeLogin' || urlArray[urlArray.length - 1] == 'initialpastpassword') {
        return Promise.reject(res)
      } else {
        return Promise.reject(res.status, res.message)
      }
    } else {
      return res
    }
  },
  error => {
    if (!os.isIOS() || error.message !== 'Network Error') {
      if (/code 500/.test(error.message)) error.message = '系统服务开小差了，请您稍后再试！'
      vant.Toast.fail(error.message)
    }

    return Promise.reject(error)
  }
)

// config option keys
// showTips
// encrypt
function handleConfig(config) {
  return Type.isObject(config) ? {
    showTips: true,
    ...config
  } : {
    showTips: config
  }
}

class Request {
  static post(url, params, showTips = true) {
    console.log(url, params, showTips)
    url = url.indexOf('https') != -1 ? url : host.domain + url
    return service({
      url,
      method: 'post',
      data: params,
      gatConfig: handleConfig(showTips)
    })
  }

  static get(url, params, showTips = true) {
    url = url.indexOf('https') != -1 ? url : host.domain + url
    return service({
      url,
      method: 'get',
      params,
      gatConfig: handleConfig(showTips)
    })
  }

  static lightGet(url, params) {
    return axios.get(url, {
      params
    })
  }

  static lightPost(url, params) {
    return axios.post(url, {
      data: params
    })
  }

  static upload(params) {
    return service(host.domain + api.common.upload, {
      timeout: 999999,
      data: params,
      method: 'post',
    })
  }
}

export default Request