export class NativeMessage {
    constructor() {
        this.actionName = ''
        this.params = {}
    }

    extend(extendData) {
        this.params = {
            ...this.params,
            ...extendData
        }

        return this
    }
}

export class NativeMessageShare extends NativeMessage {
    constructor(params, target) {
        super(params)
        this.actionName = 'share'
        const isObject = Object.prototype.toString.call(params) === '[object Object]'

        let type = 'link'
        if (!isObject) { // image
            type = 'image'
        }

        this.params = {
            type,
            target: target ? target : 'appMessage',
            data: isObject ? {
                ...params
            } : params
        }
    }
}

export class NativeMessagePush extends NativeMessage {
    constructor(params) {
        super()
        this.actionName = 'pushNative'
        this.params = {
            data: {
                ...params
            }
        }
    }
}

export class NativeMessageData extends NativeMessage {
    constructor(params) {
        super()
        this.actionName = 'getData'
        this.params = params
    }
}

export class NativeMessageNotice extends NativeMessage {
    constructor(params) {
        super()
        this.actionName = 'notice'
        this.params = params
    }
}

export class NativeMessagePay extends NativeMessage {
    constructor(params, callback) {
        super()
        this.actionName = 'pay'
        this.params = params
        if (callback) this.callback = callback
    }
}

export class NativeMessageResourceCache extends NativeMessage {
    constructor(params, callback) {
        super()
        this.actionName = 'resourceCache'
        this.params = params
    }
}