<!--
 * @Author: your name
 * @Date: 2021-06-23 13:04:28
 * @LastEditTime: 2021-06-29 16:16:07
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /m/src/components/Login/index.vue
-->
<template>
  <div class="login-dialog">
    <van-action-sheet
      v-model="show"
      class="login-sheet"
      :close-on-click-overlay="false"
      get-container="body"
      :style="{ width: contentWidth, left: contentLeft }"
    >
      <account-login
        v-if="isType == 1"
        @handleLoginType="handleLoginType"
        v-on="$listeners"
        v-bind="$attrs"
      ></account-login>
      <code-login
        v-else
        @handleLoginType="handleLoginType"
        v-on="$listeners"
        v-bind="$attrs"
        :signatures="signatures"
      ></code-login>
    </van-action-sheet>
  </div>
</template>

<script>
// import { ActionSheet } from 'vant'
import AccountLogin from './AccountLogin'
import CodeLogin from './CodeLogin'

import { mapState, mapGetters } from '@utils/vuex'
export default {
    components: { AccountLogin, CodeLogin },
    props: {
        show: {
            type: Boolean,
            require: false,
            default: false
        },
        signatures: {}
    },
    data() {
        return {
            isType: 2,
            ischeckImg: false
        }
    },
    watch: {
        // loginFlag(val) {
        //   this.loginFlag = val;
        // },
    },
    computed: { ...mapGetters(['contentWidth', 'contentLeft']) },

    methods: {
        // open() {
        //   this.loginFlag = true
        //   return this.$nextTick()
        // },
        // handleClose() {
        //   this.loginFlag = false
        // },
        handleLoginType(data) {
            console.log(data, '切换登录方式')
            this.isType = data
        }
    }
}
</script>

<style scoped>
.login-sheet {
    /* position: relative; */
    z-index: 10001;
    min-height: 80%;
}
</style>
