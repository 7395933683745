<template>
  <div class="message-push-dialog">
    <van-overlay :show="show" class="wrapper" z-index="999">
      <div class="message-push-wrapper" @click.stop>
        <div class="message-push-close" @click="$emit('dialogClose')">
          <van-icon name="close" />
        </div>
        <div class="message-push-content">
          <div class="header">
            <img src="@images/home/message-push.svg" alt />
            <span>您有重要的消息待查看</span>
          </div>
          <div class="main">
            <div class="main-title">{{data.pushTitle}}</div>
            <div class="main-content">{{data.pushContent}}</div>
          </div>
          <div class="bottom-button" @click="handleViewNow">
            <span>立即查看</span>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { NativeMessagePush } from '@utils/nativeBridge/nativeMessage'
import { mapGetters, mapState } from '@utils/vuex'
export default {
    props: {
        show: {},
        data: {}
    },
    data() {
        return {}
    },
    watch: {
        show(val) {
            this.show = val
        }
    },
    computed: {
        ...mapGetters([
            'isInApp',
            'showHeaderAppTips',
            'isMain',
            'isLogin',
            'userIdentity',
            'contentWidth',
            'contentLeft',
            'userInfo'
        ])
    },
    mounted() {},
    methods: {
        // 跳转内容类型:0=三方链接、1=富文本内容、2=单场会、3=框架会、4=分会场、5=精品课程（单个课程）、6=精品课程（系列课程）、7=医学资讯、8=手术示教、9=医生主页
        handleViewNow() {
            this.$emit('handleViewNow', this.data)
        }
    }
}
</script>
<style lang="scss" scoped>
.message-push-dialog {
    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .message-push-wrapper {
        width: 12.4rem;
        // height: 10.73rem;
        .message-push-close {
            font-size: 1rem;
            color: rgba(255, 255, 255, 0.85);
        }
        .message-push-content {
            width: 100%;
            padding-bottom: 0.85rem;
            margin-top: 0.64rem;
            background: #fff;
            border-radius: 0.26rem;
            .header {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 1.71rem;
                background: rgba(0, 137, 255, 0.2);
                text-align: center;
                // border-radius: 0.26rem 0.26rem 0rem 0rem;
                img {
                    width: 0.68rem;
                    height: 0.68rem;
                    margin-right: 0.26rem;
                }
                span {
                    font-size: 0.6rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #0089ff;
                }
            }
            .main {
                padding: 0.64rem 0.68rem 0.64rem 0.68rem;
                .main-title {
                    font-size: 0.77rem;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #222222;
                    text-align: center;
                }
                .main-content {
                    margin-top: 0.64rem;
                    font-size: 0.6rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #888888;
                    text-align: center;
                }
            }
            .bottom-button {
                width: 9.32rem;
                height: 1.54rem;
                line-height: 1.54rem;
                text-align: center;
                margin: 0 auto;
                background: #0089ff;
                box-shadow: 0rem 0.09rem 0.21rem 0rem rgba(0, 137, 255, 0.4);
                border-radius: 0.09rem;
                span {
                    font-size: 0.6rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: rgba(255, 255, 255, 0.9);
                }
            }
        }
    }
}
</style>