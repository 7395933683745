export default {
    isShowBar: false,
    clarityOptions: [],
    isBarrageOpen: true,
    likeAnimation: false,
    defaultComments: [],
    newComment: {},
    defaultLikes: [],
    newLike: {},
    marqueeData: [],
    isSmall: false,
    meetingInfo: [], // session
    homePath: '', // 播放器组件 主页按钮
    backPath: '', // 播放器组件 返回按钮
    showSpeed: false,
    showPlayerSwitch: true, // 是否显示切换播放器
    hiddenAllBarrage: true,
    operationInfo: {}, // 手术复盘
    currentPlayTime: 0, // 当前视频播放秒数
    operationTabIndex: 0,// 手术详情菜单索引
    isPlayerDrag: false,
    operationData: {}
}