class Utils {
  constructor() { }

  static isEmojiCharacter(substring) {
    for (var i = 0; i < substring.length; i++) {
      var hs = substring.charCodeAt(i);
      if (0xd800 <= hs && hs <= 0xdbff) {
        if (substring.length > 1) {
          var ls = substring.charCodeAt(i + 1);
          var uc = ((hs - 0xd800) * 0x400) + (ls - 0xdc00) + 0x10000;
          if (0x1d000 <= uc && uc <= 0x1f77f) {
            return true;
          }
        }
      } else if (substring.length > 1) {
        var ls = substring.charCodeAt(i + 1);
        if (ls == 0x20e3) {
          return true;
        }
      } else {
        if (0x2100 <= hs && hs <= 0x27ff) {
          return true;
        } else if (0x2B05 <= hs && hs <= 0x2b07) {
          return true;
        } else if (0x2934 <= hs && hs <= 0x2935) {
          return true;
        } else if (0x3297 <= hs && hs <= 0x3299) {
          return true;
        } else if (hs == 0xa9 || hs == 0xae || hs == 0x303d || hs == 0x3030 ||
          hs == 0x2b55 || hs == 0x2b1c || hs == 0x2b1b ||
          hs == 0x2b50) {
          return true;
        }
      }
    }
  }

  static randomNum(max) {
    // 0-9的随机数
    var arr = [] //容器
    var str = ''
    for (var i = 0; i < max; i++) { //循环六次
      var num = Math.random() * 10 //Math.random();每次生成(0-1)之间的数;
      num = parseInt(num)
      arr.push(num)
    }
    str = arr.join("")
    return parseInt(str)
  }

  static getQueryString(key) {
    //获取地址栏参数
    var reg = new RegExp("(^|&)" + key + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg)
    if (r != null) {
      //   return unescape(r[2])
      return decodeURIComponent(r[2]);
    }
    return null;
  }
  static getUrlParam(urlStr, urlKey) {
    const url = new URL(urlStr)
    var reg = new RegExp('[\?\&]' + urlKey + '=([^\&]*)(\&?)', 'i')
    var r = url.search.match(reg)
    return r ? r[1] : ''
  }
  static getAllQuery() {
    return location.search.substring(1).split('&').reduce((queryMap, item) => {
      const pair = item.split('=')
      if (pair[1]) queryMap[pair[0]] = pair[1]
      return queryMap
    }, {})
  }

  static cutString(str, len) {
    //length属性读出来的汉字长度为1
    if (str.length * 2 <= len) {
      return str;
    }
    var strlen = 0;
    var s = "";
    for (var i = 0; i < str.length; i++) {
      s = s + str.charAt(i);
      if (str.charCodeAt(i) > 128) {
        strlen = strlen + 2;
        if (strlen >= len) {
          return s.substring(0, s.length - 1) + "...";
        }
      } else {
        strlen = strlen + 1;
        if (strlen >= len) {
          return s.substring(0, s.length - 2) + "...";
        }
      }
    }
    return s
  }

  static getStringLength(str) {
    var reg = /^[\u4e00-\u9fa5]{0,}$/
    var len = 0
    for (var i = 0; i < str.length; i++) {
      if (reg.test(str[i])) {
        len += 1
      } else {
        len += 0.5
      }
    }
    return len
  }

  static getReplaceString(str, len) {

    let length = Utils.getStringLength(str)
    if (length >= len) {
      str = str.substring(0, len - 3) + '...'
    }
    return str
  }

  static trim(str) {
    if (!str) return ''
    return str.replace(/(^\s*)|(\s*$)/g, "")
  }

  // 防抖 wait 秒后只会执行一次，如果 wait 秒内事件再次触发，则会重新计时 
  // immediate 是否要立即执行 
  // 调用 cancel 可取消
  static debounce(func, wait, immediate) {
    let timeout, result

    const debounced = function () {

      const context = this
      const args = arguments
      if (timeout) clearTimeout(timeout)
      if (immediate) {
        // 如果已经执行过，不再执行
        const callNow = !timeout
        timeout = setTimeout(function () {
          timeout = null
        }, wait)
        if (callNow) result = func.apply(context, args)
      } else {
        timeout = setTimeout(function () {
          func.apply(context, args)
        }, wait)
      }
      return result
    }

    // 取消
    debounced.cancel = function () {
      clearTimeout(timeout)
      timeout = null
    }

    return debounced
  }

  // func wait 同节流
  // options.leading 来表示是否可以立即执行一次
  // opitons.trailing 表示结束调用的时候是否还要执行一次，默认都是 true
  // 设置的时候不能同时将 leading 或 trailing 设置为 false
  static throttle(func, wait, options) {
    let timeout, context, args
    let previous = 0
    if (!options) options = {}

    const later = function () {
      previous = options.leading === false ? 0 : new Date().getTime()
      timeout = null
      func.apply(context, args)
      if (!timeout) context = args = null
    };

    const throttled = function () {
      const now = new Date().getTime()
      if (!previous && options.leading === false) previous = now
      const remaining = wait - (now - previous)
      context = this
      args = arguments
      if (remaining <= 0 || remaining > wait) {
        if (timeout) {
          clearTimeout(timeout)
          timeout = null
        }
        previous = now;
        func.apply(context, args)
        if (!timeout) context = args = null
      } else if (!timeout && options.trailing !== false) {
        timeout = setTimeout(later, remaining)
      }
    };

    throttled.cancel = function () {
      clearTimeout(timeout)
      previous = 0
      timeout = null
    }
    return throttled;
  }

  static findCssRule(ruleName) {
    const priorityHighStyleSheets = document.styleSheets[17]
    if (priorityHighStyleSheets) {
      const rules = priorityHighStyleSheets.cssRules
      for (let i = 0, len = rules.length; i < len; i++) {
        const rule = rules[i]
        if (rule.selectorText === ruleName) {
          return rule
        }
      }
    }
    const styleSheets = document.styleSheets
    for (let i = 0, len = styleSheets.length; i < len; i++) {
      if (i === 17) continue
      const rules = styleSheets[i].cssRules
      for (let j = 0, jLen = rules.length; j < jLen; j++) {
        const rule = rules[j]
        if (rule.selectorText === ruleName) {
          return rule
        }
      }
    }
  }

}

export default Utils