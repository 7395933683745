<template>
    <div
        class="yipu"
        v-if="diseaselist && diseaselist.length"
        :style="{ width: contentWidth, background: stickyBg, margin: '0 auto' }"
    >
        <van-tabs
            v-model="active"
            color="#fff"
            title-active-color="#fff"
            title-inactive-color="#222"
            swipe-threshold="4"
            class="collegeStyle"
        >
            <van-tab
                v-for="(item, index) in diseaselist"
                :key="index"
                :title="item.disease_name"
                :name="item.disease_id"
            >
                <template #title>
                    <p class="home-tab-text ellipsis" @click="changeSelectedDisease(item, index)">
                        {{ item.disease_name }}
                    </p>
                </template>
            </van-tab>
        </van-tabs>
        <div class="right_more" :style="{ background: stickyBg }" @click="$emit('diseaseMore')">
            <img src="@images/home/home_more.png" alt="" />
        </div>
    </div>
</template>
<script>
import { mapGetters, mapState } from '@utils/vuex'
export default {
    props: {
        isSticky: {
            type: Boolean,
            default: false
        },
        diseaseActive: {}
    },
    data() {
        return {}
    },
    created() {
        this.$nextTick(() => {
            this.active = this.diseaseID || 0
        })
    },
    computed: {
        active() {
            return parseInt(this.diseaseActive)
        },
        stickyBg() {
            if (this.isSticky) {
                return '#0089ff'
            }
        },
        ...mapState('home', ['diseaselist', 'diseaseID'])
    },
    methods: {
        changeSelectedDisease(item) {
            this.$emit('changeSelectedDisease', item)
        }
    }
}
</script>
<style scoped lang="scss">
@import '@styles/variables.scss';
.yipu ::v-deep {
    .van-tabs__nav {
        background: none;
    }
}
.yipu {
    padding: 0 0 0 0.39rem;
    display: flex;
    align-items: center;
    width: 100%;
    // background: #fff;
    overflow: hidden;
    margin: 0 auto 0.43rem auto;
}
.collegeStyle {
    // flex: 1;
    width: calc(100% - 2.24rem);
}
.right_more {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.24rem;
    height: 1.37rem;
    // background: linear-gradient(180deg, #309efd 0%, #62b4fb 100%);
    img {
        width: 0.6rem;
        // height: 100%;
    }
}
.collegeStyle ::v-deep {
    .van-tab {
        flex: none;
        min-width: 2rem;
        position: relative;
        height: 1.42rem;
        line-height: 1.42rem;
        margin-left: 0.3rem;
        font-size: $fsxl !important;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.65) !important;
        border-radius: 4px;
        overflow: hidden;
        padding: 0 0.3rem;
        &:nth-child(1) {
            margin-left: 0;
        }
    }

    .van-tabs__nav--complete {
        padding: 0;
    }
    .van-tabs__line {
        background: transparent !important;
    }
    .van-tab--active {
        color: #fff !important;
        font-weight: 500 !important;
    }

    .van-tabs__wrap {
        height: 1.42rem !important;
        line-height: 1.42rem !important;
    }
    .van-tab__text .home-tab-text {
        font-size: $fslg !important;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .van-tab--active .van-tab__text .home-tab-text {
        font-size: 0.69rem !important;
    }
}
@media screen and (min-width: 750px) {
    .collegeStyle {
        width: calc(100% - 2.24rem);
    }
}
</style>