import store from "@/store";
import os from "@utils/os";
import utils from '@utils'
import { NativeMessagePush, NativeMessageData, NativeMessageNotice } from '@utils/nativeBridge/nativeMessage'

let isFirst = true // 判断是不是第一次进入
let isCollegeSelected = true // 默认选中心血管学科
let onLineTimer = null;

// let customUrlLinks = []


// if (store.getters.isInApp) {
//   customUrlLinks = store.getters.nativeBridge.sendMessage(
//     new NativeMessageData({
//       type: 'getTabbarUrlLinks',
//     })
//   ) || []
// }

// 如果是首页六小龙
let homeTabbarPage = ['/', '/departmentHomePage', '/videoConferencing', '/openingPublicity', '/openingPublicitySuccess', '/personalCenter', '/doctorMainPage']
// const isPageIgnore = /pageIgnore/.test(navigator.userAgent)
export default {
    type: 'pageConfig',
    path: ['*'],
    handler(info) {
        return new Promise(async (resolve, reject) => {
            const { getters } = store
            const { to, from } = info
            if (getters.isLogin === undefined) {
                await store.dispatch('user/getLoginStatus').catch((_) => _)
            }

            if (!store.state.socket.isLogin) store.dispatch('socket/login')
            if (getters.isLogin) {
                await store.dispatch("user/getUserInfos", isCollegeSelected).catch(e => e);
                isCollegeSelected = false
            }

            if (os.isWechat() != 3 && !getters.wxConfig)
                /*await*/
                store.dispatch('wechat/setWXConfig').catch((_) => _)
            // if (getters.isLogin && getters.userIdentity == -1)
            //   /*await*/ store.dispatch("user/checkUserIdentity").catch((_) => _);
            if (os.isIOS() && getters.isInApp) {
                await store.dispatch('getappversion').catch((_) => _)
            }

            // store.commit('hideLoading')
            store.commit('showLoading')
            // 百度统计
            if (window._hmt) {
                if (to.path) {
                    window._hmt.push(['_trackPageview', '/#' + to.fullPath])
                }
            }


            // store.commit(
            //   "setShowTabbar",
            //   store.getters.isInApp && (to.path === "/videoConferencing" || to.path === "/personalCenter" || to.path === "/" || to.path === "/test" || to.path === "/openingPublicity" || to.path === '/stCommonwealth' || (to.path === "/openingPublicitySuccess" && to.query.showTabbar == 0) || (to.path === "/doctorMainPage" && to.query.showTabbar == 0)
            //   )
            // )
            // 处理默认选中科联体

            // if (store.getters.isInApp && getters.isLogin && isFirst && !to.query.isHome) {
            //   console.log(store.state.common.isDeptUnion, '000000')
            //   isFirst = false
            //   if (to.path === "/") {
            //     await store.dispatch("getisDeptUnion", {
            //       doctorId: store.state.user.userInfos.doctor_id
            //     }).then(res => {
            //       if (res.result.total) {
            //         store.commit('setdeptUnionId', res.result.records[0].id)
            //         console.log(store.state.common.isDeptUnion, '0000001')
            //         if (!store.state.common.verticallHung) { store.commit('setTabbarSelectedIndex', 2) } else {
            //           store.commit(
            //             "setShowTabbar",
            //             false)
            //         }
            //       } else {
            //         store.dispatch('stCommonwealth/getStatus').then(res => {
            //           if (res.result.records && res.result.records.length) {
            //             console.log(store.state.common.verticallHung, '0000002')
            //             if (!store.state.common.verticallHung) { store.commit('setTabbarSelectedIndex', 2) } else {
            //               store.commit(
            //                 "setShowTabbar",
            //                 false)
            //             }
            //             store.commit('setdeptUnionId', res.result.records[0].deptUnionId)
            //           }
            //         })
            //       }
            //     })
            //   }
            // }


            if (store.getters.isInApp && getters.isLogin) {
                let number = store.getters.nativeBridge.sendMessage(
                    new NativeMessageData({
                        type: 'getConsultantChatUnreadMessageNumber',
                    })
                )
                console.log(number, '获取顾问在线未读消息数')
                store.commit('messagePush/setUnreadMessages', number)
            }
            setTimeout(() => {
                if (!onLineTimer && getters.isLogin) {
                    store.dispatch('user/iOnLine')
                    onLineTimer = setInterval(() => {
                        store.dispatch('user/iOnLine')
                    }, 4 * 60 * 1000)
                } else if (!getters.isLogin && onLineTimer) {
                    clearInterval(onLineTimer)
                    onLineTimer = null
                }
            }, 0)

            if (isFirst) {
                if (getters.isInApp && store.getters.isAppHomePage) {
                    let customUrlLinks = store.getters.nativeBridge.sendMessage(
                        new NativeMessageData({
                            type: 'getTabbarUrlLinks',
                        })
                    )
                    homeTabbarPage = [...homeTabbarPage, ...JSON.parse(customUrlLinks || []).map(link => {

                        if (!/m\.docbook\.com\.cn/.test(link)) return undefined
                        let result = ''
                        if (/\?/.test(link)) {
                            let regResult = link.match(/\.cn\/(\S+)\?/g) || ['']
                            result = regResult[0].replace('.cn', '').replace('?', '')
                        }
                        else {
                            let regResult = link.match(/\.cn\/(\S+)/g) || ['']
                            result = regResult[0].replace('.cn', '')
                        }

                        return result
                    }).filter(item => item)]
                }
                isFirst = false
            }

            if (!os.isPC() && getters.isInApp && to.path !== '/login') {
                const { path, meta, fullPath, query } = from
                const { path: toPath, meta: toMeta, fullPath: toFullPath, query: toQuery } = to

                // 拦截规则
                const isIntercept = store.getters.isAppHomePage && !homeTabbarPage.includes(to.path)
                if (!store.getters.isAppHomePage || isIntercept) {
                    store.getters.nativeBridge.sendMessage(
                        new NativeMessageNotice({
                            type: 'routerBeforeChange',
                            params: {
                                from: { path, meta, fullPath, query },
                                to: { path: toPath, meta: toMeta, fullPath: toFullPath, query: toQuery }
                            }
                        })
                    )
                }

                if (isIntercept) {

                    return store.commit('hideLoading')
                }
            }

            const { title = '' } = to.meta

            document.title = '医谱 - 专业的医生学术交流平台'
            store.getters.wx.share({
                title,
            })

            if (getters.isLogin) {
                const { alert_userinfo_frame } = getters.userInfo

                if (alert_userinfo_frame == 1) {
                    let needComplete = true
                    if (
                        to.path === '/meetingDetail' ||
                        to.path === '/doctorSharePage' ||
                        to.path === '/doctorMainPage' ||
                        to.path === '/operationDetail' ||
                        to.path === '/doctorSeriesCourses'
                    ) {
                        if (to.path === '/meetingDetail') {
                            const isOpenCheck = await store.dispatch(
                                'meeting/getcompletioninfo',
                                {
                                    meeting_id: to.query.meeting_id,
                                }
                            )
                            if (!isOpenCheck) needComplete = false
                        }

                        if (needComplete) {
                            return resolve({
                                path: '/informationComplete',
                                query: {
                                    ...to.query,
                                    toPath: to.path.replace('/', ''),
                                },
                            })
                        } else {
                            resolve()
                        }
                    } else {
                        resolve()
                    }
                } else {
                    resolve()
                }
            } else {
                resolve()
            }

        })
    },
}
