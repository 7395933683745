import md5 from 'js-md5'
import {getVisitor} from '@utils/user'
import store from '@/store'

const SocketTypePrefix = {
    docworks: 'docworks_',
    video: 'video_'
}
class SocketMessageMaker {

    static make(params) {
        const userid = store.getters.isLogin ? store.getters.userID : getVisitor()
        const timestamp = new Date().getTime()
        let platform = 'web'
        if (store.getters.isInApp) platform = 'YPAPP'
        const signature = md5(userid + platform + 'docbooksockettokenvalue' + timestamp)
        return {
            platform,
            signature,
            userid,
            version: '1.0',
            timestamp,
            ...params
        }
    }

    static makes(params) {
        if (!params) return []
        return params.map(param => {
            return SocketMessageMaker.make(param)
        })
    }
}

export {
    SocketTypePrefix,
    SocketMessageMaker,
}