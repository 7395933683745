

export default [
  {
    path: '/ypComment',
    name: 'ypComment',
    meta: {
        title: '评论'
    },
    component: () => import('@/views/ypLeftMenu/ypComment')
  },
  {
      path: '/ypExperts',
      name: 'ypExperts',
      meta: {
          title: '专家'
      },
      component: () => import('@/views/ypLeftMenu/ypExperts')
  },
  {
    path: '/ypSchedule',
    name: 'ypSchedule',
    meta: {
        title: '日程'
    },
    component: () => import('@/views/ypLeftMenu/ypSchedule')
}
]