import javaRequest from '@utils/request/javaRequest'
import { NativeMessageNotice } from '@utils/nativeBridge/nativeMessage'
import api from '@api'
import { unreadGrounMsgStatToStorage } from '@views/baseDoctor/utils'
export default {
  // 查询科联体加加入信息
  getStatus({
    dispatch,
    commit,
    state,
    rootState,
    getters
  }, params) {
    return new Promise((resolve, reject) => {
      javaRequest
        .get('/MedicalCollege/departments/union/join/application', {
          userId: getters.userID,
          status: 0
        })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  asyncInitIMUnreadMessageGroup({ commit }, state = new Set()) {
    commit('initIMUnreadMessageGroup', state)
  },
  asyncSetIMUnreadMessageGroup({
    commit,
    state
  }, imGroupId) {
    commit('setIMUnreadMessageGroup', imGroupId)
    unreadGrounMsgStatToStorage(state.unreadMessageGroup)
  },
  asyncDeleteIMUnreadMessageGroup({
    commit,
    state }, imGroupId) {
    if (state.unreadMessageGroup.has(imGroupId)) {
      commit('deleteIMUnreadMessageGroup', imGroupId)
      unreadGrounMsgStatToStorage(state.unreadMessageGroup)
    }
  }
}