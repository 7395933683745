
export default [
    {
        path: '/electronicDataPackage',
        name: 'ElectronicDataPackage',
        meta: {
            title: '电子资料包'
        },
        component: () => import('@/views/electronicDataPackage')
    },
]