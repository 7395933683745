<template>
  <div class="dragVerifyImgChip">
    <van-overlay :show="true" @click.prevent="closeOverlay" z-index="3000">
      <div class="verify-content">
        <div class="title">请完成安全验证</div>
        <slide-verify
          class="slide-verify"
          ref="slideblock"
          :imgs="imgs"
          :h="canvasHeight"
          @again="onAgain"
          @fulfilled="onFulfilled"
          @success="onSuccess"
          @fail="onFail"
          :w="canvasWidth"
          @refresh="onRefresh"
          :accuracy="accuracy"
          :slider-text="text"
        ></slide-verify>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { mapState, mapGetters } from '@utils/vuex'
import os from '@utils/os'
export default {
    data() {
        return {
            msg: '',
            text: '向右拖拽滑块填充拼图',
            // 精确度小，可允许的误差范围小；为1时，则表示滑块要与凹槽完全重叠，才能验证成功。默认值为5
            accuracy: 10,
            imgs: [
                require('@images/login/1.jpeg'),
                require('@images/login/2.jpeg'),
                require('@images/login/3.jpeg'),
                require('@images/login/4.jpeg'),
                require('@images/login/5.jpg')
            ],
            canvasWidth: 0,
            canvasHeight: 0
        }
    },
    computed: {
        ispc() {
            return os.isPC() ? true : false
        },
        ...mapGetters(['contentWidth', 'contentLeft', 'isLogin', 'userIdentity', 'visitor'])
    },
    mounted() {
        console.log(document.querySelector('.verify-content').getBoundingClientRect().width)
        this.$nextTick(() => {
            this.canvasWidth =
                document.querySelector('.verify-content').getBoundingClientRect().width - 30
            this.canvasHeight =
                document.querySelector('.verify-content').getBoundingClientRect().height - 140
        })
    },
    methods: {
        closeOverlay(e) {
            if (e.target.getAttribute('class') == 'van-overlay') {
                this.$emit('closeOverlay')
            }
        },
        onSuccess(times) {
            console.log('验证通过，耗时 ' + times + '毫秒')
            this.msg = 'login success, 耗时${(times / 1000).toFixed(1)}s'
            this.$emit('onSuccess', true)
        },
        onFail() {
            console.log('验证不通过')
            this.msg = ''
        },
        onRefresh() {
            console.log('点击了刷新小图标')
            this.msg = ''
        },
        onFulfilled() {
            console.log('刷新成功啦！')
        },
        onAgain() {
            console.log('检测到非人为操作的哦！')
            this.msg = 'try again'
            // 刷新
            this.$refs.slideblock.reset()
        },
        handleClick() {
            // 父组件直接可以调用刷新方法
            this.$refs.slideblock.reset()
        }
    }
}
</script>
<style lang="scss" scoped>
.van-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
}
.verify-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 13.33rem;
    height: 11.39rem;
    // height: 100%;
    background: #fff;
    padding: 0 0.5rem 0.5rem 0.5rem;
    overflow: hidden;
    .title {
        width: 100%;
        height: 1.4rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 0.6rem;
        font-weight: 500;
        color: #222;
        border-bottom: 1px solid #ececec;
    }
    .slide-verify {
        margin-top: 0.4rem;
    }
}
::v-deep {
    .slide-verify-slider {
        overflow: hidden;
    }
}
</style>