// import Vue from 'vue'
import router from './router'
import store from './store'
import os from '@utils/os'
import utils from '@utils'

import RouterHanlder from '@utils/routerHandler'
import AuthorizationHandler from '@utils/routerHandler/handlers/authorizationHandler'
import PlatformHandler from '@utils/routerHandler/handlers/platformHandler'
import PageConfigHandler from '@utils/routerHandler/handlers/pageConfigHandler'
import LoginHandler from '@utils/routerHandler/handlers/loginHandler'
import RedirectHandler from '@utils/routerHandler/handlers/redirectHandler'

import GATSocket from '@utils/socket/GATSocket'
import WechatUtils from '@utils/wechat'
import host from '@utils/request/host'
import NativeBridge from '@utils/nativeBridge'
import {
    NativeMessageData,
} from '@utils/nativeBridge/nativeMessage'

import {
    handleCookie
} from '@utils/cookie'
// import GATLocalStorage from '@utils/GATLocalStorage'

const socket = new GATSocket({
    url: host.socket,
})
Vue.prototype.$socket = socket
store.commit('socket/setSocket', socket)

const wechatUtils = new WechatUtils() //方便使用 也可以从 store.getters.wx 得到相同的对象
Vue.prototype.$wx = wechatUtils
store.commit('wechat/setWX', wechatUtils)

const bridge = new NativeBridge()
Vue.prototype.$nativeBridge = bridge
store.commit('nativeBridge/setNativeBridge', bridge)


// const storage =  new GATLocalStorage()
// Vue.prototype.$storage = storage
// store.commit('utils/setStorage', storage)

// wxopenid	o6kGn6DIZ6ShyRgpx3ClV4DriFDg	
// unionid	oPMAb1TPqHkrwdvUyS80209hjXwI	
// refresh_token	d84b1b16d857668e16c6fcf68b203241	
// sys_token	949233bf7f2270b4fc287a733e80c1cb	
// userid	35997	
// token	638e21c721838df36593bcc4ad7a6dd2	


Vue.prototype.$showLoading = (_) => store.commit('showLoading')
Vue.prototype.$hideLoading = (_) => store.commit('hideLoading')


Vue.config.ignoredElements = [
    ...Vue.config.ignoredElements,
    'wx-open-launch-app',
]
//医谱pc 获取登录信息登录
let mapPcAppLogin = ['/doctorMainPage', '/channelClass', '/departmentHomePage', '/encyclopediasHome']
if (mapPcAppLogin.includes(window.location.pathname) && utils.getAllQuery().token) {
    const tokenInfo = ['wxopenid', 'unionid', 'refresh_token', 'sys_token', 'userid', 'token'].reduce((info, key) => {
        info[key] = utils.getUrlParam(window.location.href, key) || ''
        return info
    }, {})
    store.commit('user/setUserLoginData', tokenInfo)
    store.commit('user/setLogin', true)
    const { origin, pathname } = window.location
    if (pathname == '/encyclopediasHome') {
        window.location.href = `${origin}${pathname}?college_name=${utils.getAllQuery().college_name}&college_id=${utils.getAllQuery().college_id}`
    } else if (pathname == '/departmentHomePage') {
        window.location.href = `${origin}${pathname}?type=${utils.getAllQuery().type}&name=${utils.getAllQuery().name}&userid=${utils.getAllQuery().userid}&college_id=${utils.getAllQuery().college_id}`
    }
    if (os.isPC()) handleCookie()
} else {
    if (os.isPC()) handleCookie()
}
const routerHandler = new RouterHanlder({
    handlers: os.isMiniprogramPc() ? [] : [AuthorizationHandler],
})

routerHandler.addHandlers([
    PlatformHandler,
    PageConfigHandler,
    LoginHandler,
    RedirectHandler,
])

router.beforeEach(routerHandler.handle.bind(routerHandler))
const routerMap = {}

router.afterEach((to, from) => {
    if (window.history.length === 1) routerMap[to.path] = true
    if (!window.sessionStorage.firstUrl) {
        window.sessionStorage.firstUrl = window.location.href
    }


    store.commit('setCanGoback', store.getters.isInApp || !routerMap[to.path])
    console.log(store.getters.isInApp, store.getters.canGoback)
    if (to.path === from.path && from.name) {
        router.go(0) // 跳转自己处理
    }
})
// mac window 嵌入登录处理
const routePath = window.location.pathname
const url = window.location.href
// && store.getters.isInPcApp
if (routePath == '/videoConferencing' && store.getters.isInPcApp) {

    const tokenInfo = ['wxopenid', 'unionid', 'refresh_token', 'sys_token', 'userid', 'token'].reduce((info, key) => {
        info[key] = utils.getUrlParam(url, key) || ''
        return info
    }, {})

    store.commit('user/setUserLoginData', tokenInfo)
    store.commit('user/setLogin', true)
}

// 百度统计
const _hmt = _hmt || []
window._hmt = _hmt;
(function () {
    const hm = document.createElement("script")
    hm.src = "https://hm.baidu.com/hm.js?f6b6cc171e705d978a7679c068fdbd67"
    const s = document.getElementsByTagName("script")[0]
    s.parentNode.insertBefore(hm, s)
})()

setTimeout((_) => {
    const node = document.querySelector('.wrap')
    if (node) {
        const rect = node.getBoundingClientRect()
        store.commit('setContentLeft', rect.left + 'px')
        store.commit('setContentRight', rect.right + 'px')
        store.commit('setContentHeight', rect.height + 'px')
    } else {
        store.commit('setContentLeft', '0')
        store.commit('setContentRight', '0')
        store.commit('setContentHeight', '0')
    }
    window.addEventListener('resize', (_) => {
        const rect = node.getBoundingClientRect()
        store.commit('setContentLeft', rect.left + 'px')
        store.commit('setContentRight', rect.right + 'px')
        store.commit('setContentHeight', rect.height + 'px')
    })
}, 0)



if (os.isProduct()) {
    console.log = function () { }
    console.warn = function () { }
    console.error = function () { }
}


// 根据在 APP 还是 微网站切换登录信息获取方式
if (store.getters.isInApp && !os.isPC()) {

    const tokenInfo = bridge.sendMessage(
        new NativeMessageData({
            type: 'tokenInfo'
        })
    )

    try {
        store.commit('user/setUserLoginData', JSON.parse(tokenInfo || {}))
    } catch (e) {
        console.log(e)
    }
} else {
    const tokenInfo = ['unionid', 'refresh_token', 'sys_token', 'userid', 'token'].reduce((info, key) => {
        info[key] = localStorage.getItem(key)
        return info
    }, {
        openid: localStorage.getItem('wxopenid')
    })
    store.commit('user/setUserLoginData', tokenInfo)

    if (os.isPC()) handleCookie()
}