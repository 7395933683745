export default [{
    path: '/operationHomepage',
    name: 'operationHomepage',
    meta: {
        title: "手术主页",
    },
    component: () => import("@/views/operationHomepage"),
}, {
    path: '/operationList',
    name: 'operationList',
    meta: {
        title: "手术列表",
    },
    component: () => import("@/views/operationList"),
}]