export default {
  praise: '/api/web/v2/meeting/information/praise', //点赞
  unsetcollect: '/api/web/v2/meeting/information/unsetcollect',
  collect: '/api/web/v2/meeting/information/collect',
  detail: '/api/web/v2/meeting/information/detail',
  share: '/api/web/v2/meeting/information/share',
  getcollectlist: '/api/web/v2/meeting/information/getcollectlist',
  batchunsetcollect: '/api/web/v2/meeting/information/batchunsetcollect',
  getHotInformation:'/api/web/v2/meeting/information/gethotinformation'//资讯热门推荐

}