import Request from '@request'
import api from '@api'
import os from '@utils/os.js'
import {
    NativeMessageNotice,
    NativeMessageData
} from '@utils/nativeBridge/nativeMessage'
import { clearCookie } from '@utils/cookie'

function loginBase({
    dispatch,
    commit,
    params,
    state,
    rootState,
    url
}) {
    // if (os.isWechat() === 3) {
    //     delete params['openid']
    //     delete params['unionid']
    // }
    return new Promise(async (resolve, reject) => {
        if (state.isLogin) {
            await dispatch('socket/setIsListeningAccount', false, {
                root: true
            })
        }
        Request.post(url, params).then(async res => {
            commit('setUserLoginData', res.data)
            commit('setLogin', true)
            // 更新设备id

            /*
            if (rootState.common.isInApp) {
                let systemInfo = rootState.nativeBridge.nativeBridge.sendMessage(new NativeMessageData({
                    type: 'systemInfo'
                }))

                let device_token = ''
                if (os.isAndroid()) {
                    device_token = JSON.parse(systemInfo).device_token
                }
                if (os.isIOS()) {
                    device_token = JSON.parse(JSON.parse(systemInfo).systemInfo).device_token
                }
                dispatch('messagePush/insertEquipmentRecord', {
                    equipmentCode: device_token,
                    userType: state.isLogin ? 0 : 1,
                    equipmentType: os.getDevice() == 'ios' ? 1 : 0
                }, { root: true })
            }
            

            // 获取未读消息数
            dispatch('messagePush/getMessagePush', {
                pageNum: 1,
                pageSize: 10,
                messageRecordStatus: 0
            }, { root: true })
            */
            // 重新获取身份
            await dispatch('getUserInfos', true).catch(_ => _)
            dispatch('socket/login', undefined, {
                root: true
            }).catch(_ => _)
            // await dispatch('checkUserIdentity').catch(_ => _)

            /*
            rootState.nativeBridge.nativeBridge.sendMessage(new NativeMessageNotice({
                type: 'login',
                params: {
                    userInfo: {
                        ...res.data,
                        ...state.userInfos
                    }
                }
            }))
            */

            resolve(res)
        }).catch((res) => {
            dispatch('socket/login', undefined, {
                root: true
            }).catch(_ => _)
            reject(res)
        })
    })
}

export default {
    login({
        dispatch,
        commit,
        state,
        rootState
    }, params) {
        return loginBase({
            dispatch,
            commit,
            params,
            state,
            rootState,
            url: api.common.login,
        })
    },
    loginCode({
        dispatch,
        commit,
        state,
        rootState
    }, params) {
        return loginBase({
            dispatch,
            commit,
            params,
            state,
            rootState,
            url: api.common.codeLogin,
        })
    },
    phoneVerifyLogin({
        dispatch,
        commit,
        state,
        rootState
    }, params) {
        return loginBase({
            dispatch,
            commit,
            params,
            state,
            rootState,
            url: api.common.phoneVerifyLogin,
        })
    },
    logout({
        dispatch,
        commit,
        state,
        rootState
    }, params = {intercept: false}) {
        return new Promise((resolve, reject) => {
            commit('clearUserLoginData')
            clearCookie()

            commit('setLogin', false)
            commit('setUserInfos', undefined)
            dispatch('socket/login', undefined, {
                root: true
            }).catch(_ => _)
            if (rootState.common.isInApp && !params.intercept) {
                rootState.nativeBridge.nativeBridge.sendMessage(new NativeMessageNotice({
                    type: 'logout',
                    params
                }))
                /*
                rootState.nativeBridge.nativeBridge.sendMessage(new NativeMessageNotice({
                    type: 'badge',
                    params: {
                        count: 0
                    }
                }))
                // 更新设备id
                let systemInfo = rootState.nativeBridge.nativeBridge.sendMessage(new NativeMessageData({
                    type: 'systemInfo'
                }))
                let device_token = ''
                if (os.isAndroid()) {
                    device_token = JSON.parse(systemInfo).device_token
                }
                if (os.isIOS()) {
                    device_token = JSON.parse(JSON.parse(systemInfo).systemInfo).device_token
                }
                dispatch('messagePush/deleteEquipmentRecord', {
                    equipmentCode: device_token,
                    equipmentType: os.getDevice() == 'ios' ? 1 : 0
                }, { root: true })
                */
            }

            resolve()
        })
    },
    getLoginStatus({
        dispatch,
        commit,
        state,
        rootState
    }, info) {
        return new Promise((resolve, reject) => {
            if (!state.userID) {
                commit('setLogin', false)
                resolve()
                /*
                if (rootState.common.isInApp) {
                    rootState.nativeBridge.nativeBridge.sendMessage(new NativeMessageNotice({
                        type: 'badge',
                        params: {
                            count: 0
                        }
                    }))
                }
                */
            } else {
                Request.post(
                    api.common.getLoginStatus, {}, false).then(res => {
                        commit('setLogin', true)

                        resolve(true)
                    }).catch(status => {
                        if (status == 401) {
                            dispatch('logout')
                            /*
                            rootState.nativeBridge.nativeBridge.sendMessage(new NativeMessageNotice({
                                type: 'badge',
                                params: {
                                    count: 0
                                }
                            }))
                            */
                            commit('setLogin', false)
                            reject(status)
                        }
                        else {
                            resolve(true)
                        }
                    })
            }

        })
    },
    getUserInfos({
        dispatch,
        commit,
        state,
        rootState
    }, needSelectCollege) {
        return new Promise((resolve, reject) => {
            Request.post(api.common.getUserInfos).then(res => {
                commit('setUserInfos', res.data)
                // if (res.data.user_type || res.data.user_type == 0) {
                commit('setUserIdentity', res.data.user_type)
                // resolve(state.user_identity)
                // } else {
                //     resolve(-1)
                // }

                if (rootState.common.isInApp) {
                    // 心血管默认选中学科
                    if (needSelectCollege && res.data.home_selected_college == 3 && rootState.home.collegeID == 0) {
                        commit('home/setCollegeID', res.data.home_selected_college, {
                            root: true
                        })
                    }
                    /*
                    rootState.nativeBridge.nativeBridge.sendMessage(new NativeMessageNotice({
                        type: 'userInfo',
                        params: {
                            userInfo: {
                                ...res.data,
                                deptUnionId: rootState.common.deptUnionId,
                                userid: localStorage.getItem('userid')
                            }
                        }
                    }))
                    */
                }
                resolve(res)
            }).catch(e=>reject(e))
        })
    },
    setUserMessage({
        dispatch,
        commit,
        state,
        rootState
    }, params) {
        return new Promise((resolve, reject) => {
            Request.post(api.user.setUserMessage, params).then(res => {
                resolve(res)
            })
        })
    },
    uploadImg({
        dispatch,
        commit,
        state,
        rootState
    }, params) {
        return new Promise((resolve, reject) => {
            Request.post(api.common.uploadImg, params).then(res => {
                resolve(res)
            }).catch(e => {
                reject(e)
            })
        })
    },
    setUserInfos({
        dispatch,
        commit,
        state,
        rootState
    }, params) {
        return new Promise((resolve, reject) => {
            Request.post(api.common.setUserInfos, params).then(res => {
                resolve(res)
            })
        })
    },
    // 获取用户身份状态
    checkUserIdentity({
        dispatch,
        commit,
        state
    }, info) {
        return new Promise((resolve, reject) => {
            Request.post(
                api.common.getUserType, {}).then(res => {
                    if (res.data.user_type || res.data.user_type == 0) {
                        commit('setUserIdentity', res.data.user_type)
                        resolve(state.user_identity)
                    } else {
                        resolve(-1)
                    }
                }).catch((status) => {
                    if (status == 401) {
                        location.reload()
                    }
                    else {
                        reject()
                    }
                })
        })
    },
    // 设置用户身份状态
    setUserIdentity({
        dispatch,
        commit,
        state
    }, info) {
        return new Promise((resolve, reject) => {
            Request.post(
                api.common.setUserType, {
                user_type: info.user_type
            }).then(res => {
                commit("setUserIdentity", info.user_type)
                resolve(info.user_type)
            })
        })
    },
    iOnLine({
        dispatch,
        commit,
        state
    }, info) {
        return new Promise((resolve, reject) => {
            Request.post(api.common.iOnLine, {
                // platform: 'm',
                refresh_token: state.tokenInfo.refresh_token
                //   localStorage.getItem('refresh_token')
            }).then(_ => {
                resolve()
            }).catch(_ => _)
        })
    },
    destory({
        dispatch,
        commit,
        state
    }, info) {
        return new Promise((resolve, reject) => {
            Request.post(api.common.destory).then(res => {
                dispatch('logout')
                resolve(res)
            })
        })
    },
}