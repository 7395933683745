export default {
    "v": "5.7.6",
    "fr": 25,
    "ip": 0,
    "op": 40,
    "w": 60,
    "h": 60,
    "nm": "预合成 1",
    "ddd": 0,
    "assets": [],
    "layers": [{
        "ddd": 0,
        "ind": 1,
        "ty": 4,
        "nm": "3",
        "sr": 1,
        "ks": {
            "o": {
                "a": 1,
                "k": [{
                    "i": {
                        "x": [0.52],
                        "y": [0.96]
                    },
                    "o": {
                        "x": [0.48],
                        "y": [0.04]
                    },
                    "t": 21,
                    "s": [10]
                }, {
                    "i": {
                        "x": [0.52],
                        "y": [1]
                    },
                    "o": {
                        "x": [0.48],
                        "y": [0]
                    },
                    "t": 26,
                    "s": [100]
                }, {
                    "i": {
                        "x": [0.52],
                        "y": [0.96]
                    },
                    "o": {
                        "x": [0.48],
                        "y": [0.04]
                    },
                    "t": 35,
                    "s": [100]
                }, {
                    "t": 39,
                    "s": [10]
                }],
                "ix": 11
            },
            "r": {
                "a": 0,
                "k": 0,
                "ix": 10
            },
            "p": {
                "a": 0,
                "k": [48, 30, 0],
                "ix": 2,
                "l": 2
            },
            "a": {
                "a": 0,
                "k": [0, 0, 0],
                "ix": 1,
                "l": 2
            },
            "s": {
                "a": 1,
                "k": [{
                    "i": {
                        "x": [0.52, 0.52, 0.52],
                        "y": [1, 1, 1]
                    },
                    "o": {
                        "x": [0.48, 0.48, 0.48],
                        "y": [0, 0, 0]
                    },
                    "t": 26,
                    "s": [100, 100, 100]
                }, {
                    "i": {
                        "x": [0.52, 0.52, 0.52],
                        "y": [1, 0.96, 1]
                    },
                    "o": {
                        "x": [0.48, 0.48, 0.48],
                        "y": [0, 0.04, 0]
                    },
                    "t": 29,
                    "s": [100, 100, 100]
                }, {
                    "i": {
                        "x": [0.52, 0.52, 0.52],
                        "y": [1, 0.96, 1]
                    },
                    "o": {
                        "x": [0.48, 0.48, 0.48],
                        "y": [0, 0.04, 0]
                    },
                    "t": 31,
                    "s": [100, 60, 100]
                }, {
                    "i": {
                        "x": [0.52, 0.52, 0.52],
                        "y": [1, 0.96, 1]
                    },
                    "o": {
                        "x": [0.48, 0.48, 0.48],
                        "y": [0, 0.04, 0]
                    },
                    "t": 35,
                    "s": [100, 130, 100]
                }, {
                    "t": 39,
                    "s": [100, 100, 100]
                }],
                "ix": 6,
                "l": 2
            }
        },
        "ao": 0,
        "shapes": [{
            "ty": "rc",
            "d": 1,
            "s": {
                "a": 0,
                "k": [8, 36],
                "ix": 2
            },
            "p": {
                "a": 0,
                "k": [0, 0],
                "ix": 3
            },
            "r": {
                "a": 0,
                "k": 1,
                "ix": 4
            },
            "nm": "矩形路径 1",
            "mn": "ADBE Vector Shape - Rect",
            "hd": false
        }, {
            "ty": "fl",
            "c": {
                "a": 0,
                "k": [0, 0.537254929543, 1, 1],
                "ix": 4
            },
            "o": {
                "a": 0,
                "k": 100,
                "ix": 5
            },
            "r": 1,
            "bm": 0,
            "nm": "填充 1",
            "mn": "ADBE Vector Graphic - Fill",
            "hd": false
        }],
        "ip": 0,
        "op": 40,
        "st": 0,
        "bm": 0
    }, {
        "ddd": 0,
        "ind": 2,
        "ty": 4,
        "nm": "2",
        "sr": 1,
        "ks": {
            "o": {
                "a": 1,
                "k": [{
                    "i": {
                        "x": [0.52],
                        "y": [0.96]
                    },
                    "o": {
                        "x": [0.48],
                        "y": [0.04]
                    },
                    "t": 8,
                    "s": [20]
                }, {
                    "i": {
                        "x": [0.52],
                        "y": [1]
                    },
                    "o": {
                        "x": [0.48],
                        "y": [0]
                    },
                    "t": 13,
                    "s": [100]
                }, {
                    "i": {
                        "x": [0.52],
                        "y": [0.96]
                    },
                    "o": {
                        "x": [0.48],
                        "y": [0.04]
                    },
                    "t": 26,
                    "s": [100]
                }, {
                    "t": 31,
                    "s": [20]
                }],
                "ix": 11
            },
            "r": {
                "a": 0,
                "k": 0,
                "ix": 10
            },
            "p": {
                "a": 0,
                "k": [30, 30, 0],
                "ix": 2,
                "l": 2
            },
            "a": {
                "a": 0,
                "k": [0, 0, 0],
                "ix": 1,
                "l": 2
            },
            "s": {
                "a": 1,
                "k": [{
                    "i": {
                        "x": [0.52, 0.52, 0.52],
                        "y": [1, 1, 1]
                    },
                    "o": {
                        "x": [0.48, 0.48, 0.48],
                        "y": [0, 0, 0]
                    },
                    "t": 13,
                    "s": [100, 100, 100]
                }, {
                    "i": {
                        "x": [0.52, 0.52, 0.52],
                        "y": [1, 0.96, 1]
                    },
                    "o": {
                        "x": [0.48, 0.48, 0.48],
                        "y": [0, 0.04, 0]
                    },
                    "t": 16,
                    "s": [100, 100, 100]
                }, {
                    "i": {
                        "x": [0.52, 0.52, 0.52],
                        "y": [1, 0.96, 1]
                    },
                    "o": {
                        "x": [0.48, 0.48, 0.48],
                        "y": [0, 0.04, 0]
                    },
                    "t": 18,
                    "s": [100, 60, 100]
                }, {
                    "i": {
                        "x": [0.52, 0.52, 0.52],
                        "y": [1, 0.96, 1]
                    },
                    "o": {
                        "x": [0.48, 0.48, 0.48],
                        "y": [0, 0.04, 0]
                    },
                    "t": 22,
                    "s": [100, 130, 100]
                }, {
                    "t": 26,
                    "s": [100, 100, 100]
                }],
                "ix": 6,
                "l": 2
            }
        },
        "ao": 0,
        "shapes": [{
            "ty": "rc",
            "d": 1,
            "s": {
                "a": 0,
                "k": [8, 36],
                "ix": 2
            },
            "p": {
                "a": 0,
                "k": [0, 0],
                "ix": 3
            },
            "r": {
                "a": 0,
                "k": 1,
                "ix": 4
            },
            "nm": "矩形路径 1",
            "mn": "ADBE Vector Shape - Rect",
            "hd": false
        }, {
            "ty": "fl",
            "c": {
                "a": 0,
                "k": [0, 0.537254929543, 1, 1],
                "ix": 4
            },
            "o": {
                "a": 0,
                "k": 100,
                "ix": 5
            },
            "r": 1,
            "bm": 0,
            "nm": "填充 1",
            "mn": "ADBE Vector Graphic - Fill",
            "hd": false
        }],
        "ip": 0,
        "op": 40,
        "st": 0,
        "bm": 0
    }, {
        "ddd": 0,
        "ind": 3,
        "ty": 4,
        "nm": "1",
        "sr": 1,
        "ks": {
            "o": {
                "a": 1,
                "k": [{
                    "i": {
                        "x": [0.52],
                        "y": [1]
                    },
                    "o": {
                        "x": [0.48],
                        "y": [0]
                    },
                    "t": 0,
                    "s": [100]
                }, {
                    "i": {
                        "x": [0.52],
                        "y": [0.96]
                    },
                    "o": {
                        "x": [0.48],
                        "y": [0.04]
                    },
                    "t": 13,
                    "s": [100]
                }, {
                    "i": {
                        "x": [0.52],
                        "y": [0.96]
                    },
                    "o": {
                        "x": [0.48],
                        "y": [0.04]
                    },
                    "t": 18,
                    "s": [20]
                }, {
                    "t": 37,
                    "s": [30]
                }],
                "ix": 11
            },
            "r": {
                "a": 0,
                "k": 0,
                "ix": 10
            },
            "p": {
                "a": 0,
                "k": [12, 30, 0],
                "ix": 2,
                "l": 2
            },
            "a": {
                "a": 0,
                "k": [0, 0, 0],
                "ix": 1,
                "l": 2
            },
            "s": {
                "a": 1,
                "k": [{
                    "i": {
                        "x": [0.52, 0.52, 0.52],
                        "y": [1, 1, 1]
                    },
                    "o": {
                        "x": [0.48, 0.48, 0.48],
                        "y": [0, 0, 0]
                    },
                    "t": 0,
                    "s": [100, 100, 100]
                }, {
                    "i": {
                        "x": [0.52, 0.52, 0.52],
                        "y": [1, 0.96, 1]
                    },
                    "o": {
                        "x": [0.48, 0.48, 0.48],
                        "y": [0, 0.04, 0]
                    },
                    "t": 3,
                    "s": [100, 100, 100]
                }, {
                    "i": {
                        "x": [0.52, 0.52, 0.52],
                        "y": [1, 0.96, 1]
                    },
                    "o": {
                        "x": [0.48, 0.48, 0.48],
                        "y": [0, 0.04, 0]
                    },
                    "t": 5,
                    "s": [100, 60, 100]
                }, {
                    "i": {
                        "x": [0.52, 0.52, 0.52],
                        "y": [1, 0.96, 1]
                    },
                    "o": {
                        "x": [0.48, 0.48, 0.48],
                        "y": [0, 0.04, 0]
                    },
                    "t": 9,
                    "s": [100, 130, 100]
                }, {
                    "t": 13,
                    "s": [100, 100, 100]
                }],
                "ix": 6,
                "l": 2
            }
        },
        "ao": 0,
        "shapes": [{
            "ty": "rc",
            "d": 1,
            "s": {
                "a": 0,
                "k": [8, 36],
                "ix": 2
            },
            "p": {
                "a": 0,
                "k": [0, 0],
                "ix": 3
            },
            "r": {
                "a": 0,
                "k": 1,
                "ix": 4
            },
            "nm": "矩形路径 1",
            "mn": "ADBE Vector Shape - Rect",
            "hd": false
        }, {
            "ty": "fl",
            "c": {
                "a": 0,
                "k": [0, 0.537254929543, 1, 1],
                "ix": 4
            },
            "o": {
                "a": 0,
                "k": 100,
                "ix": 5
            },
            "r": 1,
            "bm": 0,
            "nm": "填充 1",
            "mn": "ADBE Vector Graphic - Fill",
            "hd": false
        }],
        "ip": 0,
        "op": 40,
        "st": 0,
        "bm": 0
    }],
    "markers": []
}