<template>
  <div class="home-consulting-item" @click="$emit('item-click', item)">
    <div class="item-line">
      <div class="line-item-text">
        <span class="item-text van-multi-ellipsis--l2">{{ item.title }}</span>
        <span class="time">{{ item.own_time | formatDate }}</span>
      </div>
      <div class="line-itme-img">
        <van-image
          v-resize="200"
          class="consulting-img"
          alt="封面图片"
          lazy-load
          :src="item.cover"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: {}
    }
  },
  filters: {
    formatDate(val) {
      if (!val) return false
      let date = val.split(' ')[0].split('/')
      return `${date[1]}月${date[2]}日 ${val.split(' ')[1]}`
    }
  },
  mounted() { }
}
</script>
<style scoped lang="scss">
@import "@styles/variables.scss";
.home-consulting-item {
  cursor: pointer;
  padding: 0 0.43rem;

  .item-line {
    padding: 0.4rem 0;
    display: flex;
    border-top: 0.02rem solid #e1e1e1;
  }
  .line-item-text {
    width: 100%;
    display: flex;
    flex-direction: column;

    .item-cricle {
      width: 0.21rem;
      height: 0.21rem;
      background: #000;
      margin-right: 0.21rem;
      border-radius: 100%;
    }
    .item-text {
      margin-top: 0.13rem;
      margin-bottom: 0.15rem;
      height: 1.92rem;
      flex: 1;
      line-height: 0.96rem;
      font-size: 0.68rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
    }
    .time {
      font-weight: 400;
      font-size: 0.51rem;
      color: rgba(22, 43, 64, 0.45);
    }
  }
  .line-itme-img {
    margin-left: 0.43rem;
    .consulting-img {
      width: 4.91rem;
      height: 2.77rem;
    }
    .consulting-img ::v-deep {
      .van-image__img {
        width: 4.91rem;
        height: 2.77rem;
        border-radius: 0.17rem;
      }
    }
  }
}
</style>