export default [
  {
    path: "/privacyTreaty",
    name: "privacyTreaty",
    meta: {
      title: "协议详情",
    },
    component: () => import("@/views/privacyTreaty"),
  },

  {
    path: "/confirmation",
    name: "confirmation",
    meta: {
      title: "会议任务确认单",
    },
    component: () => import("@/views/confirmation"),
  },
  {
    path: "/player",
    name: "player",
    meta: {
      title: "播放器",
    },
    component: () => import("@/views/player"),
  },
  {
    path: "/channelHome",
    name: "channelHome",
    meta: {
      title: "频道详情",
    },
    component: () => import("@/views/channelHome"),
  },
  {
    // ÷slideVerify
    path: "/slideVerify",
    name: "slideVerify",
    meta: {
      title: "图片验证",
    },
    component: () => import("@/views/slideVerify"),
  },
  {
    path: '/richText',
    name: "richText",
    meta: {
      title: "消息信息",
    },
    component: () => import("@/views/richText"),
  },
  {
    path: '/openYpApp',
    name: "openYpApp",
    meta: {
      title: "打开App",
    },
    component: () => import("@/views/openYpApp"),
  },
  {
    path: '/videoConferenceInvitation',
    name: "videoConferenceInvitation",
    meta: {
      title: "视频会议邀请",
    },
    component: () => import("@/views/videoConferenceInvitation"),
  },
  {
    path: '/videoConferenceHistory',
    name: "videoConferenceHistory",
    meta: {
      title: "视频会议历史记录",
    },
    component: () => import("@/views/videoConferenceHistory"),
  },
  {
    path: '/scheduleDetails',
    name: "scheduleDetails",
    meta: {
      title: "日程详情",
    },
    component: () => import("@/views/scheduleDetails"),
  },
  {
    path: '/videoMeetingDetail',
    name: "videoMeetingDetail",
    meta: {
      title: "会议详情",
    },
    component: () => import("@/views/videoMeetingDetail"),
  },
  {
    path: '/attendee',
    name: "attendee",
    meta: {
      title: "参会人",
    },
    component: () => import("@/views/attendee"),
  },
  {
    path: '/videoConferencing',
    name: "videoConferencing",
    meta: {
      title: "视频会议",
    },
    component: () => import("@/views/videoConferencing"),
  },
  {
    path: '/calendar',
    name: "calendar",
    meta: {
      title: "日历",
    },
    component: () => import("@/views/calendar"),
  },
  {
    path: "/alonePlayer",
    name: "alonePlayer",
    meta: {
      title: "单独播放器",
    },
    component: () => import("@/views/alonePlayer"),
  },
];
