export default {
  meetingHomeIntroduce: '/api/web/v3/frame/introduce', // 框架会首页介绍
  meetingHomeMenu: '/api/web/v3/frame/menu', // 框架会菜单栏
  liveDate: '/api/web/v3/frame/livedate', // 框架会日期信息
  testLiveDate: '/api/web/v3/frame/testlivedate', // 测试框架会日期信息
  expertlist: '/api/web/v3/meeting/expertlist', // 专家列表
  meetingDetail: '/api/web/v3/meeting/detail', // 会议详情
  expertsession: '/api/web/v3/frame/expertsession', // 专家信息
  shareMeeting: '/api/web/v3/meeting/sharemeeting', // 会议分享
  meetingCarefullyChosen: '/api/web/v3/meeting/meetingplayback', // 会议精选
  liveinfo: '/api/web/v3/frame/liveinfo', // 日程
  meetingLiveinfo: '/api/web/v3/meeting/liveinfo', // 直播间日程
  expertInfo: '/api/web/v3/meeting/expertinfo', // 专家信息
  praise: '/api/web/v3/meeting/praise',
  reserve: '/api/web/v2/meeting/reserve', // 预约
  getVideo: '/api/web/v2/meetingplayback/getVideo', // 观看密码
  collect: '/api/web/v3/meeting/collect', // 收藏
  checkinConfig: '/api/web/v3/meeting/checkinconfig', // 签到配置项
  watchPlayback: '/api/web/v3/meeting/watchplayback', // 回放统计接口
  watchLive: '/api/web/v3/meeting/watchlive', // 直播统计
  whitelistCheck: '/api/web/v2/whitelist/check',
  whiteConfig: '/api/web/v2/whitelist/config',
  praiselist: '/api/web/v3/meeting/praiselist',
  buttonConfig: '/api/web/v3/frame/buttonconfig', // 框架会按钮配置
  enterpriseLogo: '/api/web/v3/subject/enterpriselogo', // 获取企业跑马灯
  checkSubject: '/api/web/v3/meeting/checksubject', // 校验专题
  checkFrame: '/api/web/v3/meeting/checkframe', // 校验框架
  checkWatchPwd: '/api/web/v3/meeting/checkpwd', // 验证密码
  banner: '/api/web/v2/subject/banner', // 底图
  usercenter: '/api/web/v3/subject/usercenter', // 数据中心
  authinfo: '/api/web/v3/meeting/authinfo', // 分享信息
  useraboutmeetingstatus: '/api/web/v3/meeting/useraboutmeetingstatus',
  getCertificate: '/api/web/v3/onlineexam/getCertificate',
  onlineexam: '/api/web/v3/onlineexam/exam',
  inviteranklist: '/api/web/v3/meeting/inviteranklist',
  inviteuserinfo: '/api/web/v3/meeting/inviteuserinfo',
  invitecount: '/api/web/v3/meeting/invitecount',
  onlineAnswer: '/api/web/v3/meeting/onlineanswer',//在线问答
  startOnlineAnswer: '/api/web/v3/meeting/startonlineanswer',//开始在线问答
  // https://mapi.docbook.com.cn/api/v2/getzoomconfig
  getZoomConfig: '/api/v2/getzoomconfig',//sass获取会议配置情况
  getSaasMeetingInfo: '/api/v2/getsaasmeetinginfo',//sass获取会议配置情况
  getCheckinStatus: '/api/web/v3/meeting/getcheckinstatus', // 获取会议是否需要签到
  getcompletioninfo: '/api/web/v3/meeting/getcompletioninfo', // 会议完善信息状态
  getscrolluser: '/api/web/v3/meeting/getscrolluser'
}