<template>
    <div class="list-bg" :style="scrollStyle" @scroll="scroll">
        <template v-if="listData && listData.length > 0">
            <van-list
                v-model="loading"
                :finished="finished"
                finished-text="- 我也是有底线的 -"
                @load="onLoad"
            >
                <template v-for="(item, index) in listData">
                    <slot v-bind="{ data: item, index }"></slot>
                </template>
            </van-list>
        </template>
        <div class="no-data" v-if="listData.length == 0 && isNoData">
            <img :src="noDataImg" alt />
            <p>{{ noDataText }}</p>
        </div>
    </div>
</template>
<script>
export default {
    components: {},
    props: {
        scrollable: {
            type: Boolean,
            require: false,
            default: true
        },
        noDataText: {
            type: String,
            required: false,
            default: '暂无数据'
        },
        noDataImg: {
            type: String,
            required: false,
            default: require('@images/common/zanwu.png')
        },
        data: {
            type: Array,
            required: false,
            default: () => []
        },
        isNoData: {
            type: Array,
            require: false,
            default: true
        }
    },
    data() {
        return {
            listData: [],
            loading: false,
            finished: true,
            page: 1
        }
    },
    computed: {
        scrollStyle() {
            return this.scrollable
                ? {
                      height: '100%',
                      overflowY: 'auto'
                  }
                : {}
        }
    },
    created() {
        this.refresh()
    },
    methods: {
        scroll(e) {
            this.$emit('scroll', e)
        },
        onLoad() {
            this.$emit('load', loadTask => {
                loadTask(++this.page)
                    .then(({ data }) => {
                        this.handleDataStatus(data, true)
                    })
                    .catch(e => {
                        this.loading = false
                        this.finished = true
                    })
            })
        },
        handleDataStatus(res, loadMore) {
            this.loading = false
            const { data = res.list, last_page, total, realNumber } = res
            this.finished = this.page == last_page || last_page == 0
            this.listData = loadMore ? [...this.listData, ...data] : data

            if (!last_page && last_page != 0) {
                if (total) {
                    this.finished = total <= this.listData.length
                } else {
                    this.finished = data.length < 20 ? true : false
                }
            }
            this.$emit('update:data', this.listData)
            console.log(this.listData, data, res)
        },
        refresh() {
            this.$emit('load', loadTask => {
                loadTask((this.page = 1))
                    .then(({ data }) => {
                        this.handleDataStatus(data, false)
                    })
                    .catch(e => {
                        this.loading = false
                        this.finished = true
                    })
            })
        }
    }
}
</script>
<style lang="scss" scoped>
@import '@styles/variables.scss';

.list-bg {
    // height: 100%;
    width: 100%;
    // overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.no-data {
    flex: 1;
    height: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    > img {
        width: 4.74rem;
        height: 4.74rem;
    }

    > p {
        color: #999999;
    }
}
</style>
