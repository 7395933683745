

export default [
  {
    path: '/meetingDetail',
    name: 'meetingDetail',
    meta: {
        title: '详情'
    },
    component: () => import('@/views/meetingDetail')
  }
]