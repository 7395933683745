export default {
    newContentList: '/MedicalCollege/attentionDoctor/content/newContentList', // 关注医生最新内容列表
    getLiveMeeting: '/MedicalCollege/label/getLiveMeeting', //获取直播会议
    selectAllContent: '/MedicalCollege/label/selectAllContent', // 获取动态其余信息
    selectLabelInformationPage: '/MedicalCollege/information/selectLabelInformationPage', // 标签资讯列表
    productionList: '/MedicalCollege/label/content/productionList', // 标签课程、手术列表
    doctorList: '/MedicalCollege/label/disease/doctorList', //疾病关联的医生列表
    diseaseDetail: '/MedicalCollege/label/disease/detail', // 疾病详情
    otherDisease: '/MedicalCollege/label/disease/otherDisease', // 其他疾病
    selectTagsCategroyDetails: '/MedicalCollege/tags/categories/selectTagsCategroyDetails', //查询特殊分类基础信息
    selectLabelPage: '/MedicalCollege/tags/categories/selectLabelPage', // 获取其他分类
    selectPartsDetails: '/MedicalCollege/tags/parts/selectPartsDetails', // 查询部位基础信息
    partsLabelPage: '/MedicalCollege/tags/parts/selectLabelPage', // 部位其他列表
    operationDetail: '/MedicalCollege/label/operation/detail', //手术详情
    otherOperation: '/MedicalCollege/label/operation/otherOperation', // 手术其他列表
    getRecentMeeting: '/MedicalCollege/meeting/getRecentMeeting', // 近期列表
    tagsDiseases: '/MedicalCollege/tags/diseases', // 根据学科查询疾病标签按照亚专业分组
    tagsOperations: '/MedicalCollege/tags/operations', //根据一级学科查询手术标签按照亚专科分组
    tagsParts: '/MedicalCollege/tags/parts',//根据部位标签按照内容排序
    tagsCategories: '/MedicalCollege/tags/categories',//MedicalCollege/tags/categories
    all: '/MedicalCollege/label/content/all',
    tree: '/MedicalCollege/college/tree', // 根据标签查询学科和亚专业
}