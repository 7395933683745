

export default [
  {
    path: '/expertSharePage',
    name: 'expertSharePage',
    meta: {
        title: '专家主页'
    },
    component: () => import('@/views/expertSharePage')
  }
]