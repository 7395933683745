import { NativeMessageNotice } from '@utils/nativeBridge/nativeMessage'
import store from '@/store'

// 性别字典表
export const gender = num => {
    const hash = {
        1: '男',
        2: '女'
    }
    return hash[num] || ''
}

// 协同门诊-订单状态字典表
export const orderStatus = status => {
    const hash = {
        1: '待接诊',
        2: '就诊中',
        3: '已结诊',
        4: '已取消'
    }
    return hash[status] || ''
}

// 协同门诊-进入诊室（im聊天） 我的预约 subDoctor  收到的预约 supDoctor
export const enterChatRoom = (conversationDetail, currentRole = 'subDoctor') => {
    // 进入诊室
    console.log('进入诊室detail: ', conversationDetail)
    console.log('进入诊室用户是下级医院: ', currentRole)
    const { imGroupId } = conversationDetail || {}
    // 清除待回复标识
    imGroupId && store.dispatch('stCommonwealth/asyncDeleteIMUnreadMessageGroup', imGroupId)
    setTimeout(() => {
        const message = new NativeMessageNotice({
            type: 'joinConsulting',
            params: {
                ...conversationDetail,
                currentRole // 我的预约 'subDoctor'  收到的预约 'supDoctor'
            }
        })
        store.getters.nativeBridge.sendMessage(message)
    }, 200)
}

// 本地缓存im未读消息key
const UNREAD_IM_GROUP = 'unread_im_group'

// 同步数据到本地缓存 updateLocalStore
export const unreadGrounMsgStatToStorage = data => {
    if (!data) {
        return
    }
    const arr = []
    for (let item of data) {
        arr.push(item)
    }
    localStorage.setItem(UNREAD_IM_GROUP, arr.join(','))
}

// 从本次缓存同步数据到vuex
export const unreadGrounpMsgStorageToState = () => {
    const data = localStorage.getItem(UNREAD_IM_GROUP) || ''
    return data.split(',')
}

// 协同门诊-详情测试数据
export const mockDetail = {
    id: '1', // 订单id
    deptUnionId: '1', // 科联体id
    imGroupId: '123', // im群组id
    subDoctorId: '124', // 基层医生id
    subDoctorName: '张伟', // 基层医生姓名
    subDoctorImId: '123123', // 基层医生imid
    supDoctorId: '123', // 上级医生id
    supDoctorName: '苏雅拉', // 上级医生姓名
    supDoctorImId: '', // 上级医生
    orderState: (~~(Math.random() * 10) % 4) + 1, // 订单状态：接诊状态，1 待接诊，2 就诊中， 3 已结诊，4 已取消'
    patientName: '**灿', // 患者姓名
    patientSex: '1', // 患者性别,1男2女
    patientAge: '23', // 患者年龄
    diseaseDesc:
        '患者头痛，做了核磁共振，但并没有检测出明显问题，考虑可能是其他原因引起的头痛，但是一一排除了，希望专家能协助解决一下', // 病情描述
    preDiagnosis: '癫痫', // 初步诊断
    whatHelp:
        '希望专家帮忙确诊下希望专家帮忙确诊下希望专家帮忙确诊下希望专家帮忙确诊下希望专家帮忙确诊下希望专家帮忙确诊下', // 希望获得帮助
    pics: [
        'https://img01.yzcdn.cn/vant/cat.jpeg',
        'https://img01.yzcdn.cn/vant/cat.jpeg',
        'https://img01.yzcdn.cn/vant/cat.jpeg',
        'https://img01.yzcdn.cn/vant/cat.jpeg',
        'https://img01.yzcdn.cn/vant/cat.jpeg'
    ], // 咨询图片列表，JSON数组格式 ["https://p1.jpg","https://p2.jpg"]'
    orderAmount: '100', // 订单金额
    createAt: '2023-02-02 12:30:02', // 下单时间
    supHospitalName: '天津市北辰医院', // 上级医院名称
    supDepartmentName: '心血管内科', // 上级医生科室
    supDoctorPositionName: '主任医师', // 上级医生职称
    appointmentDateTime: '2023/01/06 12:00-12:33', // 预约时间
    queueNumber: '2/5' // 展示队列人数 2/5
}

// 协同门诊-列表测试数据
export const mockData = [
    {
        id: '1', // 订单id
        deptUnionId: '1', // 科联体id
        imGroupId: '123', // im群组id
        subDoctorId: '124', // 基层医生id
        subDoctorName: '张伟', // 基层医生姓名
        subDoctorImId: '123123', // 基层医生imid
        supDoctorId: '123', // 上级医生id
        supDoctorName: '苏雅拉', // 上级医生姓名
        supDoctorImId: '', // 上级医生
        orderState: '1', // 订单状态：接诊状态，1 待接诊，2 就诊中， 3 已结诊，4 已取消'
        patientName: '**灿', // 患者姓名
        patientSex: '1', // 患者性别,1男2女
        patientAge: '23', // 患者年龄
        diseaseDesc:
            '患者头痛，做了核磁共振，但并没有检测出明显问题，考虑可能是其他原因引起的头痛，但是一一排除了，希望专家能协助解决一下', // 病情描述
        preDiagnosis: '癫痫', // 初步诊断
        whatHelp:
            '希望专家帮忙确诊下希望专家帮忙确诊下希望专家帮忙确诊下希望专家帮忙确诊下希望专家帮忙确诊下希望专家帮忙确诊下', // 希望获得帮助
        pics: [
            'https://img01.yzcdn.cn/vant/cat.jpeg',
            'https://img01.yzcdn.cn/vant/cat.jpeg',
            'https://img01.yzcdn.cn/vant/cat.jpeg',
            'https://img01.yzcdn.cn/vant/cat.jpeg',
            'https://img01.yzcdn.cn/vant/cat.jpeg'
        ], // 咨询图片列表，JSON数组格式 ["https://p1.jpg","https://p2.jpg"]'
        orderAmount: '100', // 订单金额
        createAt: '2023-02-02 12:30:02', // 下单时间
        supHospitalName: '天津市北辰医院', // 上级医院名称
        supDepartmentName: '心血管内科', // 上级医生科室
        supDoctorPositionName: '主任医师', // 上级医生职称
        appointmentDateTime: '2023/01/06 12:00-12:33', // 预约时间
        queueNumber: '2/5' // 展示队列人数 2/5
    },
    {
        id: '1', // 订单id
        deptUnionId: '1', // 科联体id
        imGroupId: '123', // im群组id
        subDoctorId: '124', // 基层医生id
        subDoctorName: '张伟', // 基层医生姓名
        subDoctorImId: '123123', // 基层医生imid
        supDoctorId: '123', // 上级医生id
        supDoctorName: '苏雅拉', // 上级医生姓名
        supDoctorImId: '', // 上级医生
        orderState: '2', // 订单状态：接诊状态，1 待接诊，2 就诊中， 3 已结诊，4 已取消'
        patientName: '**灿', // 患者姓名
        patientSex: '1', // 患者性别,1男2女
        patientAge: '23', // 患者年龄
        diseaseDesc:
            '患者头痛，做了核磁共振，但并没有检测出明显问题，考虑可能是其他原因引起的头痛，但是一一排除了，希望专家能协助解决一下', // 病情描述
        preDiagnosis: '癫痫', // 初步诊断
        whatHelp:
            '希望专家帮忙确诊下希望专家帮忙确诊下希望专家帮忙确诊下希望专家帮忙确诊下希望专家帮忙确诊下希望专家帮忙确诊下', // 希望获得帮助
        pics: [
            'https://img01.yzcdn.cn/vant/cat.jpeg',
            'https://img01.yzcdn.cn/vant/cat.jpeg',
            'https://img01.yzcdn.cn/vant/cat.jpeg',
            'https://img01.yzcdn.cn/vant/cat.jpeg',
            'https://img01.yzcdn.cn/vant/cat.jpeg'
        ], // 咨询图片列表，JSON数组格式 ["https://p1.jpg","https://p2.jpg"]'
        orderAmount: '100', // 订单金额
        createAt: '2023-02-02 12:30:02', // 下单时间
        supHospitalName: '天津市北辰医院', // 上级医院名称
        supDepartmentName: '心血管内科', // 上级医生科室
        supDoctorPositionName: '主任医师', // 上级医生职称
        appointmentDateTime: '2023/01/06 12:00-12:33', // 预约时间
        queueNumber: '2/5' // 展示队列人数 2/5
    },
    {
        id: '1', // 订单id
        deptUnionId: '1', // 科联体id
        imGroupId: '123', // im群组id
        subDoctorId: '124', // 基层医生id
        subDoctorName: '张伟', // 基层医生姓名
        subDoctorImId: '123123', // 基层医生imid
        supDoctorId: '123', // 上级医生id
        supDoctorName: '苏雅拉', // 上级医生姓名
        supDoctorImId: '', // 上级医生
        orderState: '3', // 订单状态：接诊状态，1 待接诊，2 就诊中， 3 已结诊，4 已取消'
        patientName: '**灿', // 患者姓名
        patientSex: '1', // 患者性别,1男2女
        patientAge: '23', // 患者年龄
        diseaseDesc:
            '患者头痛，做了核磁共振，但并没有检测出明显问题，考虑可能是其他原因引起的头痛，但是一一排除了，希望专家能协助解决一下', // 病情描述
        preDiagnosis: '癫痫', // 初步诊断
        whatHelp:
            '希望专家帮忙确诊下希望专家帮忙确诊下希望专家帮忙确诊下希望专家帮忙确诊下希望专家帮忙确诊下希望专家帮忙确诊下', // 希望获得帮助
        pics: [
            'https://img01.yzcdn.cn/vant/cat.jpeg',
            'https://img01.yzcdn.cn/vant/cat.jpeg',
            'https://img01.yzcdn.cn/vant/cat.jpeg',
            'https://img01.yzcdn.cn/vant/cat.jpeg',
            'https://img01.yzcdn.cn/vant/cat.jpeg'
        ], // 咨询图片列表，JSON数组格式 ["https://p1.jpg","https://p2.jpg"]'
        orderAmount: '100', // 订单金额
        createAt: '2023-02-02 12:30:02', // 下单时间
        supHospitalName: '天津市北辰医院', // 上级医院名称
        supDepartmentName: '心血管内科', // 上级医生科室
        supDoctorPositionName: '主任医师', // 上级医生职称
        appointmentDateTime: '2023/01/06 12:00-12:33', // 预约时间
        queueNumber: '2/5' // 展示队列人数 2/5
    },
    {
        id: '1', // 订单id
        deptUnionId: '1', // 科联体id
        imGroupId: '123', // im群组id
        subDoctorId: '124', // 基层医生id
        subDoctorName: '张伟', // 基层医生姓名
        subDoctorImId: '123123', // 基层医生imid
        supDoctorId: '123', // 上级医生id
        supDoctorName: '苏雅拉', // 上级医生姓名
        supDoctorImId: '', // 上级医生
        orderState: '4', // 订单状态：接诊状态，1 待接诊，2 就诊中， 3 已结诊，4 已取消'
        patientName: '**灿', // 患者姓名
        patientSex: '1', // 患者性别,1男2女
        patientAge: '23', // 患者年龄
        diseaseDesc:
            '患者头痛，做了核磁共振，但并没有检测出明显问题，考虑可能是其他原因引起的头痛，但是一一排除了，希望专家能协助解决一下', // 病情描述
        preDiagnosis: '癫痫', // 初步诊断
        whatHelp:
            '希望专家帮忙确诊下希望专家帮忙确诊下希望专家帮忙确诊下希望专家帮忙确诊下希望专家帮忙确诊下希望专家帮忙确诊下', // 希望获得帮助
        pics: [
            'https://img01.yzcdn.cn/vant/cat.jpeg',
            'https://img01.yzcdn.cn/vant/cat.jpeg',
            'https://img01.yzcdn.cn/vant/cat.jpeg',
            'https://img01.yzcdn.cn/vant/cat.jpeg',
            'https://img01.yzcdn.cn/vant/cat.jpeg'
        ], // 咨询图片列表，JSON数组格式 ["https://p1.jpg","https://p2.jpg"]'
        orderAmount: '100', // 订单金额
        createAt: '2023-02-02 12:30:02', // 下单时间
        supHospitalName: '天津市北辰医院', // 上级医院名称
        supDepartmentName: '心血管内科', // 上级医生科室
        supDoctorPositionName: '主任医师', // 上级医生职称
        appointmentDateTime: '2023/01/06 12:00-12:33', // 预约时间
        queueNumber: '2/5' // 展示队列人数 2/5
    }
]