export default [
  {
    path: "/personalCenter",
    name: "personalCenter",
    meta: {
      title: "个人中心",
    },
    component: () => import("@/views/personalCenter"),
  },
  {
    path: "/collectTeachs",
    name: "collectTeachs",
    meta: {
      title: "我的收藏",
    },
    component: () => import("@/views/collectTeachs"),
  },
  {
    path: "/myAppointment",
    name: "myAppointment",
    meta: {
      title: "我的预约",
    },
    component: () => import("@/views/myAppointment"),
  },
  {
    path: "/adviceFeedback",
    name: "adviceFeedback",
    meta: {
      title: "意见反馈",
    },
    component: () => import("@/views/adviceFeedback"),
  },
  {
    path: "/aboutUs",
    name: "aboutUs",
    meta: {
      title: "关于我们",
    },
    component: () => import("@/views/aboutUs"),
  },
  {
    path: "/setting",
    name: "setting",
    meta: {
      title: "设置",
    },
    component: () => import("@/views/personalCenter/setting"),
  },
  {
    path: "/personInfor",
    name: "personInfor",
    meta: {
      title: "个人信息",
    },
    component: () => import("@/views/personInfor"),
  },
  {
    path: "/personGoodAt",
    name: "personGoodAt",
    meta: {
      title: "擅长",
    },
    component: () => import("@/views/personInfor/infoEdit"),
  },
  {
    path: "/personIntroduce",
    name: "personIntroduce",
    meta: {
      title: "简介",
    },
    component: () => import("@/views/personInfor/infoEdit"),
  },
  {
    path: "/statusVerification",
    name: "statusVerification",
    meta: {
      title: "身份认证",
    },
    component: () => import("@/views/statusVerification"),
  },
  {
    path: "/taskOrders",
    name: "taskOrders",
    meta: {
      title: "我的任务单",
    },
    component: () => import("@/views/taskOrders"),
  },
  // purchasedCourses
  {
    path: "/purchasedCourses",
    name: "purchasedCourses",
    meta: {
      title: "已购课程",
    },
    component: () => import("@/views/purchasedCourses"),
  },
  {
    path: "/messageList",
    name: "messageList",
    meta: {
      title: "我的消息",
    },
    component: () => import("@/views/messageList"),
  },
];
