import Request from '@request'
import api from '@api'
export default {
  getExpertlist({
    dispatch,
    commit,
    state,
    rootState
  }, params) {
    return new Promise((resolve, reject) => {
      Request.post(api.meetingDetail.expertlist, params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  inviteranklist({
    dispatch,
    commit,
    state,
    rootState
  }, params) {
    return new Promise((resolve, reject) => {
      Request.post(api.meeting.inviteranklist, params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getMeetingDetail({
    dispatch,
    commit,
    state,
    rootState
  }, params) {
    return new Promise((resolve, reject) => {
      Request.post(api.meetingDetail.meetingDetail, params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
}