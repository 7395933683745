
export default [
    {
      path: '/questionnaire',
      name: 'questionnaire',
      meta: {
          title: '问卷调查'
      },
      component: () => import('@/views/questionnaire')
    },
  ]