export default {
    sendCode: "/api/web/v2/account/sendCode",
    wxShare: "/api/web/v3/wechat/share", // 微信分享
    upload: "/api/web/v3/common/upload", // 上传
    getUserType: "/api/web/v2/account/getusertype", // 获取用户身份
    setUserType: "/api/web/v2/account/setusertype",
    login: "/api/web/v2/account/login", // 帐号密码登录 // 0 getUserInfo 0 sendcode 1
    codeLogin: "/api/web/v2/account/codeLogin",
    phoneVerifyLogin: '/api/web/v3/getui/phoneverifylogin', // 一键登录
    getWXAuthorizeInfo: '/api/web/v3/wechat/getappwxauthorizeinfo', // APP内微信登录
    getinsuranceconfig: "/api/insurance/v1/getinsuranceconfig", // 获取等保配置
    getcountrycode: "/api/insurance/v1/getcountrycode", // 根据账号获取对应的区号
    changepassword: "/api/insurance/v1/changepassword", // 密码修改
    forgetpassword: "/api/insurance/v1/forgetpassword", // 忘记密码
    initialpastpassword: '/api/insurance/v1/initialpastpassword',
    agreementDetail: "/api/v2/agreementdetail", // 协议详情
    getRegisterOptions: "/api/v2/account/getRegisterOptions",
    usertype: "/api/v2/account/usertype",
    checkuidlogin: "/api/web/v2/meeting/checkuidlogin",
    agreement: "/api/v2/agreement",
    startmeeting: "/api/web/v2/client/startmeeting",
    getUserInfos: "/api/web/v2/account/getuserinfo", // 获取用户信息
    getUserEncryptInfo: '/api/web/v2/account/getuserencryptinfo',
    setUserInfos: "/api/web/v2/account/setUserInfos", // 设置用户信息
    uploadImg: "/api/web/v2/account/uploadimg",// 上传图片
    getLoginStatus: "/api/web/v2/account/getloginstatus", // 获取登录状态
    searchHospital: "/api/web/v2/checkin/searchHospital", // 查询医院
    getDepartmentLevelOne: "/api/web/v2/checkin/getDepartmentLevelOne", // 获取科室
    getDepartmentLevelTwo: "/api/web/v2/checkin/getDepartmentLevelTwo",
    getDepartmentLevel: '/api/web/v2/checkin/getDepartmentLevel', // 获取所有科室
    getTitleOptions: "/api/web/v2/checkin/getTitleOptions",
    getAuthAddress: "/api/web/v3/wechat/getauthaddress", // 微信授权
    getWXInfo: "/api/web/v2/operate/getwxinfo", // 获取微信信息
    iOnLine: "/api/iOnLine", // 登录在线
    attentionDoctor: "/api/web/v3/doctor/attentiondoctor", // 关注
    doctorproduction: "/api/web/v2/doctorhomepage/comment/doctorproduction",
    givepraise: "/api/web/v3/doctorhomepage/production/givepraise",
    editdetail: "/api/web/v3/doctorhomepage/production/editdetail",
    productionDelete: "/api/web/v3/doctorhomepage/production/delete",
    productionEdit: "/api/web/v3/doctorhomepage/production/edit",
    uploadconfig: "/api/v2/sts/uploadconfig",
    inviteJoinMeeting: '/api/consultation/invite/joinmeeting', // 通过 order ID 受邀 入会


    productionAdd: "/api/web/v3/doctorhomepage/production/add",
    tasklist: "/api/web/v3/doctorhomepage/tasklist",
    serviceconfig: "/api/web/v3/doctor/serviceconfig",
    getappversion: "/api/web/v3/common/getappversion",
    seriescoursedetail: "/api/web/v3/production/seriescoursedetail",
    consultlist: '/api/web/v3/doctorhomepage/consultlist',
    clicklinklog: '/api/web/v2/doctorhomepage/clicklinklog',
    getusertemplateinfo: '/api/v2/account/getusertemplateinfo', //获取模板注册用户数据
    destory: '/api/web/v2/account/destory',//注销
    subjectreportpraise: '/api/statistics/v3/article/subjectreportpraise',
    subjectreportcollections: '/api/statistics/v3/article/subjectreportcollections',
    subjectreportshare: '/api/statistics/v3/article/subjectreportshare',
    subjectreportdetails: '/api/statistics/v3/article/subjectreportdetails',
    subjectreportcollectremove: '/api/statistics/v3/article/subjectreportcollectremove',
    collect: '/api/web/v3/production/collect', // 课程收藏
    collectlist: '/api/web/v3/production/collectlist', // 课程收藏列表
    diseaselist: '/api/web/v3/college/diseaselist', //疾病列表
    getDiseaseMenu: '/api/web/v3/meeting/menu', // 疾病详情菜单
    applysciencecreation: '/api/web/v3/doctor/applysciencecreation',
    getAnswerStatus: '/api/web/v2/questionnaire/getanswerstatus',//查询问卷状态
    seoconfig: '/MedicalCollege/site/seoconfig/query', //获取首页seo 配置
    contentmenu: '/api/web/v3/doctor/contentmenu', // 医生主页菜单
    getDepartmentsHome: '/api/web/v3/doctor/getdepartmentshome',//医生关联的科室主页
    getSellerName: '/api/web/v2/account/getsellername',//通过邀请码获取人名
    sessionwatchastrict: '/api/web/v3/meeting/sessionwatchastrict',// session 缴费查询
    tipoff: '/api/web/v3/live/tip-off', //评论举报
    channelmenu: '/api/web/v3/meeting/channelmenu', //频道菜单

}
