export default {
  selectColunmDetailsPage: '/MedicalCollege/company/colunm/selectColunmDetailsPage', //企业专栏详情页
  selectCompanyColunmList: '/MedicalCollege/company/colunm/selectCompanyColunmList', // 企业专栏列表
  selectProductLis: '/MedicalCollege/company/association/selectProductLis', //关联产品及对应菜单列表
  selectProductCategoryLevel1: '/MedicalCollege/product/category/selectProductCategoryLevel1',
  selectProductList: '/MedicalCollege/company/association/selectProductList',
  selectAdvertisingList: '/MedicalCollege/company/advertising/selectAdvertisingList',
  addSubscribe: '/MedicalCollege/user/subscribe/addSubscribe', //订阅
  cancelSubscribe: '/MedicalCollege/user/subscribe/cancelSubscribe',
  visitColunmDetails: '/MedicalCollege/company/colunm/visitColunmDetails', //统计
  visitApparatusProduct: '/MedicalCollege/apparatus/product/visitApparatusProduct',//统计
  selectApparatusProductDetails: '/MedicalCollege/apparatus/product/selectApparatusProductDetails', //产品详情
  selectMenuList: '/MedicalCollege/company/menu/selectMenuList', // 菜单
  selectColunmListAZ: '/MedicalCollege/company/colunm/selectColunmListAZ',
  selectApparatusProductPage: '/MedicalCollege/apparatus/product/selectApparatusProductPage',
  selectClassifyProductList: '/MedicalCollege/company/association/selectClassifyProductList'
}