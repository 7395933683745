
<template>
    <div class="login-container">
        <img :src="data.photo" alt="" />
        <span class="name">{{ data.truename || data.sysusers_name }}</span>
        <span class="mobile">{{ data.account }}</span>

        <div class="login-button" @click.stop="clipboardOneLogin">
            <span>一键登录</span>
        </div>

        <agreement v-model="isAgreementChecked" agreementId="6"></agreement>
    </div>
</template>

<script>
import { mapState, mapGetters } from '@utils/vuex'
import Agreement from './Agreement'
export default {
    components: { Agreement },
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            show: true,
            isAgreementChecked: false
        }
    },

    methods: {
        clipboardOneLogin() {
            if (this.isAgreementChecked) this.$emit('submit', this.data)
            else this.$toast('请勾选医谱服务协议')
        }
    }
}
</script>

<style lang="scss" scoped>
.login-container {
    padding: 2rem 1.3rem;
    padding-bottom: 2.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    > img {
        width: 1.72rem;
        height: 1.72rem;
        border: 1px solid #f4f4f4;
        border-radius: 0.86rem;
    }

    .name {
        margin-top: 0.22rem;

        font-size: 0.69rem;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
    }

    .mobile {
        margin-top: 0.65rem;

        font-size: 0.86rem;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
    }

    .login-button {
        margin-top: 0.4rem;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 1.85rem;
        background: #0072f5;
        box-shadow: 0rem 0.13rem 0.35rem 0rem rgba(0, 114, 245, 0.35);
        border-radius: 0.2rem;
        cursor: pointer;

        > span {
            font-size: 0.69rem;
            font-weight: 400;
            color: #ffffff;
        }
    }
}
</style>
