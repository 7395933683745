export default [
  {
    path: "/doctorMainPage",
    name: "doctorMainPage",
    meta: {
      title: "医生主页",
    },
    component: () => import("@/views/doctor/doctorMainPage"),
  },
  {
    path: "/doctorMainPageTest",
    name: "doctorMainPageTest",
    meta: {
      title: "医生主页TEST",
    },
    component: () => import("@/views/doctor/doctorMainPageTest"),
  },
  {
    path: "/doctorSharePage",
    name: "doctorSharePage",
    meta: {
      title: "作品详情",
    },
    component: () => import("@/views/doctor/doctorSharePage"),
  },
  {
    path: "/doctorEditPage",
    name: "doctorEditPage",
    meta: {
      title: "编辑资料",
    },
    component: () => import("@/views/doctor/doctorEditPage"),
  },
  {
    path: "/videoUpload",
    name: "videoUpload",
    meta: {
      title: "上传作品",
    },
    component: () => import("@/views/doctor/videoUpload"),
  },
  {
    path: "/doctorSeriesCourses",
    name: "doctorSeriesCourses",
    meta: {
      title: "课程详情",
    },
    component: () => import("@/views/doctor/doctorSeriesCourses"),
  },
  {
    path: '/DoctorShareEditPage',
    name: "DoctorShareEditPage",
    meta: {
      title: "医生简介",
    },
    component: () => import("@/views/doctor/DoctorShareEditPage"),
  }
];
