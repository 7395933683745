import {
    setCookie
} from '@utils/cookie'
import os from '@utils/os'

export default {
    setUserInfos(state, userInfos) {
        state.userInfos = userInfos
    },
    setLogin(state, isLogin) {
        state.isLogin = isLogin
    },
    setUserIdentity(state, userIdentity) {
        state.userIdentity = userIdentity
    },
    setUserLoginData(state, infos) {
        state.tokenInfo = infos
        state.userID = infos.userid
        if (infos.openid) localStorage.setItem('wxopenid', infos.openid);
        ['unionid', 'refresh_token', 'sys_token', 'userid', 'token'].forEach(key => {
            const val = infos[key]
            val && localStorage.setItem(key, val)
        })

        if (os.isPC()) setCookie()
    },
    clearUserLoginData(state) {
        state.tokenInfo = {}
        state.userID = '';
        ['userid', 'guest_id', 'refresh_token', 'sys_token', 'token', 'unread_im_group'].forEach(key => {
            localStorage.removeItem(key)
        })

        // 非微信环境进行清理
        if (os.isWechat() === 3) {
            localStorage.removeItem('wxopenid')
            localStorage.removeItem('unionid')
        }
    }
}