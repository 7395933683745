export default [{
    path: '/diseaseHomepage',
    name: 'diseaseHomepage',
    meta: {
        title: "疾病主页",
    },
    component: () => import("@/views/diseaseHomepage"),
}, {
    path: '/diseaseList',
    name: 'diseaseList',
    meta: {
        title: "疾病列表",
    },
    component: () => import("@/views/diseaseList"),
}]