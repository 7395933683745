<template>
  <div class="Home-Expert-List">
    <div class="HomeExpert" ref="HomeExpert">
      <div
        v-for="(item, index) in expertList"
        :key="index"
        @click="handleClick(item.doctor_id)"
        :title="item.doctor_name"
        class="expert-item"
      >
        <!-- <template #title> -->
        <div class="expert-item van-ellipsis">
          <img
            :src="item.doctor_cover || morenPhoto"
            :class="{ imgBorder: item.live_status == 1 }"
            alt
          />
          <span class="van-ellipsis">{{ item.doctor_name }}</span>
          <div class="new" v-if="item.new_production_num">{{ item.new_production_num }}</div>
          <div class="tip" v-if="item.live_status == 1">
            <lottie class="schedule-studio-item-playing" v-model="lottie" :options="options" />
            <div class="live-text">直播中</div>
          </div>
        </div>
        <!-- </template> -->
      </div>
    </div>
  </div>
</template>

<script>
import { Tab, Tabs } from 'vant'
import Lottie from '@components/Lottie'
import LottiePlaying from '@/assets/animation/baiPlaying.js'
import loading from '@components/Loading'
export default {
    components: {
        Tab,
        Tabs,
        Lottie,
        loading
    },
    props: {
        expertList: {
            type: Array,
            require: false,
            default: _ => []
        },
        expertLoading: {},
        loading: {}
    },
    watch: {
        expertLoading(val) {
            this.expertLoading = val
        }
    },
    data() {
        return {
            morenPhoto: require('@/assets/images/common/morenPhoto.png'),
            active: 0,
            lottie: null,
            options: {
                animationData: LottiePlaying
            }
        }
    },
    mounted() {
        this.addScroll()
    },
    beforeDestroy() {
        this.removeSrcoll()
    },
    methods: {
        addScroll() {
            this.$el.addEventListener('scroll', this.handleScroll, true)
        },
        removeSrcoll() {
            //   debugger;
            this.$el.removeEventListener('scroll', this.handleScroll, true)
        },
        handleClick(name) {
            this.$emit('handleClick', name)
        },
        handleScroll() {
            let innerWidth = this.$refs.HomeExpert.scrollWidth
            let outerWidth = document.documentElement.clientWidth
            const dom = document.querySelector('.HomeExpert')
            let scrollLeft = dom ? dom.scrollLeft : -1
            if (scrollLeft != -1) {
                if (scrollLeft == 0) {
                    innerHeight = 10000
                }
                if (innerWidth <= outerWidth + scrollLeft + 20) {
                    if (!this.expertLoading) {
                        this.$emit('leftLoad')
                    }
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@styles/variables.scss';
.loading {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Home-Expert-List {
    position: relative;
    overflow: hidden;
    // margin-left: 0.53rem;
}

.HomeExpert {
    padding: 0.3rem 0 0.3rem 0rem;
    box-sizing: border-box;
    display: flex;
    overflow-x: auto;
}

.new {
    position: absolute;
    right: 0.18rem;
    top: 0;
    width: 0.6rem;
    height: 0.6rem;
    text-align: center;
    line-height: 0.6rem;
    background: #f95352;
    border-radius: 100%;
    font-size: 0.5rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
}

.expert-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
        width: 2.4rem;
        height: 2.4rem;
        border-radius: 100%;
    }

    span {
        text-align: center;
        display: inline-block;
        width: 3rem;
        word-break: break-all;
        margin-top: 0.2rem;
        font-size: $fslg;
        font-weight: 400;
        color: #222;
    }
}
.imgBorder {
    border: 0.05rem solid #fea602;
}
.tip {
    width: 2.4rem;
    position: absolute;
    bottom: 0.7rem;
    left: -1.2rem;
    margin-left: 50%;
    background: #fea602;
    border-radius: 12px;
    border: 1px solid #ffffff;
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;

    .live-text {
        color: #fff;
        font-size: 12px !important;
        font-weight: 400;
    }
}

.schedule-studio-item-playing {
    width: 0.5rem;
    height: 0.5rem;
    margin-bottom: 0.1rem;
}
</style>
