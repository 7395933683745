export default [
    {
      path: '/meetingHome',
      name: 'meetingHome',
      meta: {
          title: '首页'
      },
      component: () => import('@/views/meetingHome')
  },
  {
    path: '/meetingHomeTest',
    name: 'meetingHomeTest',
    meta: {
        title: '首页TEST'
    },
    component: () => import('@/views/meetingHome/meetingHomeTest')
  },
    {
      path: '/studyList',
      name: 'studyList',
      meta: {
          title: '未完成学习课程'
      },
      component: () => import('@/views/studyList')
    },
    {
      
      path: '/studyRequest',
      name: 'studyRequest',
      meta: {
          title: '考试成绩单'
      },
      component: () => import('@/views/studyRequest')
    }
  ]