//微信公众号的分装
//配置与服务端一致
const devappid = [
  'wx74af4d4a763ec3bb', //远大康城
  'wx74af4d4a763ec3bb', //心血管
  'wx74af4d4a763ec3bb', //麻醉学院  
  'wx74af4d4a763ec3bb', //结直肠     
]

const testappid = [
  'wx6d4209fc6913e42d', //远大康城
  'wx6d4209fc6913e42d', //心血管
  'wx6d4209fc6913e42d', //麻醉学院  
  'wx6d4209fc6913e42d', //结直肠     
]

const releaseAppid = [
  'wx5d64885cdf4ac1c0', //远大康城
  'wx5d64885cdf4ac1c0', //心血管
  'wx5d64885cdf4ac1c0', //麻醉学院  
  'wx5d64885cdf4ac1c0', //结直肠     
]

const onlineAppid = [
  'wxb49762e9d61b1955', //远大康城
  'wxb49762e9d61b1955', //心血管
  'wx1af4af9dfa4b84e2', //麻醉学院
  'wxc9f31539e845778b', //结直肠
  'wx85657b1219199d3f', //神经交流
  'wx0de06f999b20eb79', //CA学术交流
  '',//外科远程服务平台
  '', //没有8
  'wx8c5ca9394d78c3b1', //医谱学术
  'wx9e8b28207cfa9d1d', //血管时讯
  'wx14d3e4c405a50a3f', // 医谱健康
  'wxf2c8dcab717b3185', //医谱心外
]

if (/dev-m/.test(window.location.href)) {
  var appid = devappid;
} else if (/test-m/.test(window.location.href)) {
  var appid = testappid;
} else if (/release-m/.test(window.location.href)) {
  var appid = releaseAppid;
} else if (/localhost/.test(window.location.href)) {
  var appid = devappid;
} else if (/192.168/.test(window.location.href)) {
  var appid = devappid;
} else if (/test-heartm.docbook.com.cn/.test(window.location.href)) {
  var appid = testappid;
} else if (/release-heartm.docbook.com.cn/.test(window.location.href)) {
  var appid = releaseAppid;
} else if (/heartm.docbook.com.cn/.test(window.location.href)) {
  var appid = onlineAppid;
} else {
  var appid = onlineAppid;
}


export default {
  testUrl: appid
}