import store from '@/store'
import os from '@utils/os'

export default {
    type: 'platform',
    extra: {
        authorization: false,
    },
    path: [{
        path: '/meetingDetail',
        query: ['uid']
    }],
    handler(info) {
        return new Promise(async (resolve, reject) => {
            const {
                to,
                from,
                next
            } = info

            // if (to.query.p) {
                // if (to.query.p == 'o') {
                //     if (!store.state.platform.platformInfo) {
                //         const abbottInfo = await store.dispatch('platform/abbott', {
                //             meeting_id: to.query.meeting_id
                //         })
                //         if (abbottInfo.url) {
                //             const uid = localStorage.getItem(to.query.meeting_id + '_uid_login')
                //             if (uid) {
                //                 store.commit('platform/setPlatformInfo', {
                //                     Abbott: uid
                //                 })
                //                 store.commit('platform/setPlatformUrl', abbottInfo.url)
                //                 store.commit('player/setRouterPath',{
                //                     back: abbottInfo.url
                //                 })
                //             }
                //             else {
                //                 location.href = abbottInfo.url
                //                 return
                //             }
                //         }
                //         else {
                //             localStorage.removeItem(to.query.meeting_id + '_uid_login')
                //         }
                //     }
                // }
                // else if (to.query.p == 's') {
                // const userInfoStr = sessionStorage.getItem(to.query.meeting_id + '_uid_userinfo')
                // if (userInfoStr) {
                //     const userInfo = JSON.parse(userInfoStr)
                //     store.commit('platform/setPlatformInfo', userInfo)

                //     // 需要根据接口处理
                //     //store.commit('platform/setCanGoback', false)
                // }
                // }
            // } else {
                // if (to.query.platform === 'Abbott') {
                //     const abbottInfo = await store.dispatch('platform/abbott', {
                //         meeting_id: to.query.meeting_id
                //     })
                //     if (abbottInfo.url) {
                //         await store.dispatch('platform/checkUidLogin', {
                //             uid: to.query.uid,
                //             enterprisePlat: 'Abbott'
                //         })
                //         localStorage.setItem(to.query.meeting_id + '_uid_login', to.query.uid)
                //         const query = {
                //             ...to.query
                //         }
                //         delete query['uid']
                //         delete query['platform']
                //         resolve({
                //             ...to,
                //             query: {
                //                 ...query,
                //                 p: 'o'
                //             }
                //         })
                //     }
                //     else {
                //         localStorage.removeItem(to.query.meeting_id + '_uid_login')
                //     }
                // }
                // else {
                if (os.isPC()) return
                await store.dispatch('platform/checkUidLogin', {
                    uid: to.query.uid,
                    enterprisePlat: to.query.platform,
                    scheid: to.query.scheid || '',
                    meeting_id: to.query.meeting_id
                })
                // store.commit('platform/setCanGoback', false)
                // sessionStorage.setItem(to.query.meeting_id + '_uid_userinfo', JSON.stringify({
                //     uid: to.query.uid,
                //     platform: to.query.platform,
                //     scheid: to.query.scheid || ''
                // }))
                const query = {
                    ...to.query
                }
                delete query['uid']
                delete query['platform']
                delete query['scheid']
                resolve({
                    ...to,
                    query
                    // query: {
                    //     ...query,
                    //     p: 1
                    // }
                })
            // }

            // }

            // resolve()
        })
    }
}