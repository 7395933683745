export default {
  setUnreadCount(state, data) {
    state.unreadCount = data
  },
  setNotificationLimit(state, data) {
    state.notification_limit = data
  },
  setUnreadMessages(state, data) {
    state.unreadMessages = data
  },
  setQuantity(state, data) {
    state.quantity = data
  },
  setWaitNum(state, data) {
    state.waitNum = data
  },
}
