export default {
    setBannerData(state, bannerData) {
        state.bannerData = bannerData
    },
    setAdvanceData(state, advance_data) {
        state.advance_data = advance_data
    },
    addAdvancePage(state) {
        state.advance_page++
    },
    resetAdvancePage(state) {
        state.advance_page = 1
    },
    setCollegeID(state, collegeID) {
        state.collegeID = collegeID
    },
    setDiseaseID(state, diseaseID) {
        state.diseaseID = diseaseID
    },
    setPlaybackData(state, playback_data) {
        state.playback_data = playback_data
    },
    addPlaybackPage(state) {
        state.playback_page++
    },
    resetPlaybackPage(state) {
        state.playback_page = 1
    },
    setColumnData(state, column_data) {
        const temp_data = []
        const old_data = []
        for (let i = 0, len = column_data.length; i < len; i++) {
            const item = column_data[i]
            const { subject_id, subject_theme, subject_cover, type, meeting_type, begin_time, end_time, speaker, meeting_id, view_num, view_display_status, is_credit_meeting } = item
            if (!subject_id) {// 已经转换过了
                old_data.push(item)
                continue
            }
            let temp_item
            if (item.meeting_type) {
                temp_item = {
                    meeting_id,
                    id: subject_id,
                    cover: subject_cover,
                    type,
                    meeting_type,
                    title: subject_theme,
                    begin_time,
                    end_time,
                    speaker,
                    view_num,
                    view_display_status,
                    is_credit_meeting
                }
            } else {
                temp_item = {
                    meeting_id,
                    id: subject_id,
                    img: subject_cover,
                    title: subject_theme,
                    time: `${begin_time}-${end_time}`
                }
            }
            temp_data.push(temp_item)
        }
        state.column_data = [...old_data, ...temp_data]
    },
    setCollegeClose(state, data) {
        state.isCollegeClose = data
    },
    addColumnPage(state) {
        state.column_page++
    },
    resetColumnPage(state) {
        state.column_page = 1
    },
    setConsultingData(state, consulting_data) {
        state.consulting_data = consulting_data
    },
    addConsultingPage(state) {
        state.consulting_page++
    },
    resetConsultingPage(state) {
        state.consulting_page = 1
    },
    setCurrentKeyword(state, current_keyword) {
        if (current_keyword === '全部') {
            current_keyword = ''
        }
        state.current_keyword = current_keyword
    },
    setAllCollege(state, allCollege) {
        // 因没有第一个首页item数据，所以自定义添加第一条
        state.allCollege = allCollege
    },
    setSearchText(state, searchText) {
        state.searchText = searchText
    },
    setSearchTabIndex(state, searchTabIndex) {
        state.searchTabIndex = searchTabIndex
    },
    setDiseaselist(state, diseaselist) {
        state.diseaselist = diseaselist
    },
    setcreditData(state, credit_data) {
        state.credit_data = credit_data
    },
    addcreditPage(state) {
        state.credit_page++
    },
    resetcreditPage(state) {
        state.credit_page = 1
    },
}