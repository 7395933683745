import Request from "@request";
import appid from "@request/appid";
import api from "@api";
import utils from "@utils";
import router from '../../../router'
export default {
  // 专家列表
  getExpertlist({
    dispatch,
    commit,
    state,
    rootState
  }, params) {
    return new Promise((resolve, reject) => {
      Request.post(api.meeting.expertlist, params)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  // 会议详情接口
  getMeetingDetail({
    dispatch,
    commit,
    state,
    rootState
  }, params) {
    return new Promise((resolve, reject) => {
      Request.post(api.meeting.meetingDetail, params)
        .then((res) => {
          let {
            data
          } = res;
          resolve(res);
        })
        .catch((err) => {
          reject(err)
        });
    });
  },
  // 获取专家日程
  getExpertSession({
    dispatch,
    commit,
    state,
    rootState
  }, params) {
    return new Promise((resolve, reject) => {
      Request.post(api.meeting.expertsession, params, false)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  // 获取日程中日期
  getScheduleDate({
    dispatch,
    commit,
    state,
    rootState
  }, params) {
    return new Promise((resolve, reject) => {
      Request.post(api.meeting.liveDate, params, false).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  // 测试获取日程中日期
  getScheduleDateTest({
    dispatch,
    commit,
    state,
    rootState
  }, params) {
    return new Promise((resolve, reject) => {
      Request.post(api.meeting.testLiveDate, params, false).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  // 会议分享
  shareMeeting({
    dispatch,
    commit,
    state,
    rootState
  }, params) {
    return new Promise((resolve, reject) => {
      Request.post(api.meeting.shareMeeting, params)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  // 会议首页接口
  getMeetingHomeIntroduce({
    dispatch,
    commit,
    state,
    rootState
  }, params) {
    return new Promise((resolve, reject) => {
      Request.post(api.meeting.meetingHomeIntroduce, params)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  // 会议课程精选
  getMeetingCarefullyChosen({
    dispatch,
    commit,
    state,
    rootState
  }, params) {
    return new Promise((resolve, reject) => {
      Request.post(api.meeting.meetingCarefullyChosen, params)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  geLiveinfo({
    dispatch,
    commit,
    state,
    rootState
  }, params) {
    return new Promise((resolve, reject) => {
      Request.post(api.meeting.liveinfo, params)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  // 专家信息
  expertInfo({
    dispatch,
    commit,
    state,
    rootState
  }, params) {
    return new Promise((resolve, reject) => {
      Request.post(api.meeting.expertInfo, params, false)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  // 点赞
  handlePraise({
    dispatch,
    commit,
    state,
    rootState
  }, params) {
    return new Promise((resolve, reject) => {
      Request.post(api.meeting.praise, params)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  // 收藏
  handleCollect({
    dispatch,
    commit,
    state,
    rootState
  }, params) {
    return new Promise((resolve, reject) => {
      Request.post(api.meeting.collect, params)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  // 获取菜单
  getMenu({
    dispatch,
    commit,
    state,
    rootState
  }, params) {
    return new Promise((resolve, reject) => {
      Request.post(api.meeting.meetingHomeMenu, params)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  // 预约
  reserve({
    dispatch,
    commit,
    state,
    rootState
  }, params) {
    return new Promise((resolve, reject) => {
      Request.post(api.meeting.reserve, params)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  // 单视频页面观看密码
  getVideoPassword({
    dispatch,
    commit,
    state,
    rootState
  }, params) {
    return new Promise((resolve, reject) => {
      Request.post(api.meeting.getVideo, params)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  // 观看密码
  checkWatchPwd({
    dispatch,
    commit,
    state,
    rootState
  }, params) {
    return new Promise((resolve, reject) => {
      Request.post(api.meeting.checkWatchPwd, params)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  // 直播间日程
  meetingLiveinfo({
    dispatch,
    commit,
    state,
    rootState
  }, params) {
    return new Promise((resolve, reject) => {
      Request.post(api.meeting.meetingLiveinfo, params)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  // 白名单配置
  whiteConfig({
    dispatch,
    commit,
    state
  }, param) {
    return new Promise((resolve, reject) => {
      let parent_meeting_id = param.parent_meeting_id
      delete param.parent_meeting_id
      Request.post(api.meeting.whiteConfig, param, ).then((res) => {
        commit("setIsOpen", res.data.watch_status); // 1 开启 2 关闭
        commit("setIsWatch_type", res.data.watch_type); // 1 详情 2 点击
        if (res.data.watch_status == 1) {
          if (res.data.watch_type == 1) {
            dispatch("whitelistCheck", {
              id: param.id,
              parent_meeting_id: parent_meeting_id
            });
          } else {
            commit("setisWhiteListFlag", true);
          }
        }
        resolve(res);
      });
    });
  },
  // 查询白名单
  whitelistCheck({
    dispatch,
    commit,
    state,
    rootState
  }, params) {
    return new Promise((resolve, reject) => {
      let parent_meeting_id = params.parent_meeting_id
      delete params.parent_meeting_id
      Request.post(api.meeting.whitelistCheck, params, false)
        .then((res) => {
          commit("setisWhiteListFlag", true);
          commit("setisWhitelist", false);
          resolve(res);
        }).catch(err => {
          console.log(err, parent_meeting_id)
          if (err == '401') {
            router.push({
              path: '/login',
              query: {
                from: 'meetingDetail',
                parent_meeting_id: parent_meeting_id || 0,
                meeting_id: params.id
              }
            })
            return false
          }
          commit("setisWhitelist", true);
          commit("setisWhiteListFlag", false);
          reject(err)
        });
    });
  },
  getQrcode({
    commit,
    state
  }, params) {
    return new Promise((resolve, reject) => {
      console.log(params, '获取图片')
      Request.post("/api/web/v2/wechat/qrcode", params).then(res => {
        resolve(res)
      })
    })
  },
  // 判断是否需要强制关注
  getFocusStatus({
    commit,
    state
  }, param) {
    return new Promise((resolve, reject) => {
      Request.post("/api/web/v2/wechat/userinfo", param).then(res => {
        resolve(res)
      })
    })
  },
  getGZHInfo({
    dispatch,
    commit,
    state
  }, params) {
    return new Promise((resolve, reject) => {
      console.log(params)
      let current_appid =
        appid.testUrl[parseInt(params.wechat_subscription_type) - 1];

      const cache = localStorage.getItem(current_appid)
      if (cache) {
        resolve(cache)
        return
      }

      let code = utils.getQueryString("code");
      if (!code) {
        window.location.href =
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
          current_appid +
          "&redirect_uri=" +
          encodeURIComponent(location.href) +
          "&response_type=code&scope=snsapi_base&state=123#wechat_redirect";
      } else {
        Request.post("/api/web/v2/wechat/authorize", {
          code,
          wx_type: params.wechat_subscription_type
        }).then(res => {
          if (!res.data.openid) {
            const auth_url = location.href.replace(`code=${code}`, '').replace('?&', '?').replace('&&', '&').replace(/&$/, '').replace(/\?$/, '')
            window.location.href =
              "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
              current_appid +
              "&redirect_uri=" +
              encodeURIComponent(auth_url) +
              "&response_type=code&scope=snsapi_base&state=123#wechat_redirect";
          } else {
            localStorage.setItem(current_appid, res.data.openid)
            resolve(res.data.openid)
          }

        }).catch(e => {

        })
      }
      // resolve(current_appid)
    })
  },

  getCheckinConfig({
    dispatch,
    commit,
    state,
    rootState
  }, params) {
    return new Promise((resolve, reject) => {
      Request.post(api.meeting.checkinConfig, params).then(res => {
        const {
          data
        } = res
        resolve(data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  watchPlayback({
    dispatch,
    commit,
    state,
    rootState
  }, params) {
    // 增加三方对接参数
    if (rootState.platform.platformInfo) {
      const {
        platform,
        uid,
        scheid
      } = rootState.platform.platformInfo
      params['pf'] = platform
      params['uid'] = uid
      if (scheid) {
        params['scheid'] = scheid
      }
    }
    return new Promise((resolve, reject) => {
      Request.post(api.meeting.watchPlayback, params, false).then(res => {
        resolve()
      }).catch(err => {
        reject(err)
      })
    })
  },
  watchLive({
    dispatch,
    commit,
    state,
    rootState
  }, params) {
    // 增加三方对接参数
    if (rootState.platform.platformInfo) {
      const {
        platform,
        uid,
        scheid
      } = rootState.platform.platformInfo
      params['pf'] = platform
      params['uid'] = uid
      if (scheid) {
        params['scheid'] = scheid
      }
    }
    return new Promise((resolve, reject) => {
      Request.post(api.meeting.watchLive, params, false).then(res => {
        resolve()
      }).catch(err => {
        reject(err)
      })
    })
  },
  // 点赞列表
  praiselist({
    dispatch,
    commit,
    state,
    rootState
  }, params) {
    return new Promise((resolve, reject) => {
      Request.post(api.meeting.praiselist, params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  // 框架会按钮配置
  buttonConfig({
    dispatch,
    commit,
    state,
    rootState
  }, params) {
    return new Promise((resolve, reject) => {
      Request.post(api.meeting.buttonConfig, params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  // 企业跑马灯
  enterpriseLogo({
    dispatch,
    commit,
    state,
    rootState
  }, params) {
    return new Promise((resolve, reject) => {
      Request.post(api.meeting.enterpriseLogo, params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  // 校验是否专题
  checkSubject({
    dispatch,
    commit,
    state,
    rootState
  }, params) {
    return new Promise((resolve, reject) => {
      Request.post(api.meeting.checkSubject, params).then(res => {
        resolve(res.data.meeting_id)
      }).catch(err => {
        reject(err)
      })
    })
  },
  // 校验是否框架
  checkFrame({
    dispatch,
    commit,
    state,
    rootState
  }, params) {
    return new Promise((resolve, reject) => {
      Request.post(api.meeting.checkFrame, params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  // 个人数据中心相关接口
  getBottomBanner({
    dispatch,
    commit,
    state,
    rootState
  }, params) {
    return new Promise((resolve, reject) => {
      Request.post(api.meeting.banner, params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  // 个人数据中心相关接口
  getUserCenter({
    dispatch,
    commit,
    state,
    rootState
  }, params) {
    return new Promise((resolve, reject) => {
      Request.post(api.meeting.usercenter, params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  // 获取状态
  getUserStatus({
    dispatch,
    commit,
    state,
    rootState
  }, params) {
    return new Promise((resolve, reject) => {
      Request.post(api.meeting.useraboutmeetingstatus, params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getCertificate({
    dispatch,
    commit,
    state,
    rootState
  }, params) {
    return new Promise((resolve, reject) => {
      Request.post(api.meeting.getCertificate, params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  onlineexam({
    dispatch,
    commit,
    state,
    rootState
  }, params) {
    return new Promise((resolve, reject) => {
      Request.post(api.meeting.onlineexam, params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  inviteranklist({
    dispatch,
    commit,
    state,
    rootState
  }, params) {
    return new Promise((resolve, reject) => {
      Request.post(api.meeting.inviteranklist, params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  invitecount({
    dispatch,
    commit,
    state,
    rootState
  }, params) {
    return new Promise((resolve, reject) => {
      Request.post(api.meeting.invitecount, params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  inviteuserinfo({
    dispatch,
    commit,
    state,
    rootState
  }, params) {
    return new Promise((resolve, reject) => {
      Request.post(api.meeting.inviteuserinfo, params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  onlineAnswer({
    dispatch,
    commit,
    state,
    rootState
  }, params) {
    return new Promise((resolve, reject) => {
      Request.post(api.meeting.onlineAnswer, params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  startOnlineAnswer({
    dispatch,
    commit,
    state,
    rootState
  }, params) {
    return new Promise((resolve, reject) => {
      Request.post(api.meeting.startOnlineAnswer, params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getSaasMeetingInfo({
    dispatch,
    commit,
    state,
    rootState
  }, params) {
    return new Promise((resolve, reject) => {
      Request.post(api.meeting.getSaasMeetingInfo, params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getCheckinStatus({
    dispatch,
    commit,
    state,
    rootState
  }, params) {
    return new Promise((resolve, reject) => {
      Request.post(api.meeting.getCheckinStatus, params).then(res => {
        resolve(res.data.checkin_status == 1)
      }).catch(err => {
        reject(err)
      })
    })
  },

  getcompletioninfo({
    dispatch,
    commit,
    state,
    rootState
  }, params) {
    return new Promise((resolve, reject) => {
      Request.post(api.meeting.getcompletioninfo, params).then(res => {
        resolve(res.data.completion_information == 1)
      }).catch(err => {
        reject(err)
      })
    })
  },
}