import {
    SocketMessageMaker
} from './SocketMessageMaker'
class GATSocket {
    constructor(options) {
        this.url = options.url
        this.components = options.components
        this.messages = options.messages

        this.componentsMap = {}
        this.socket = null
        this.is_connection = false
        this.count = 1
        this.interval = 2000

        this.isOpen = false
        this.messageQueue = []

        this.targetCache = {}

        this._initSocket()
    }

    _initSocket() {
        try {
            if (this.socket) {
                this.socket.close()
                this.socket = null
            }
        } catch (e) {
            console.log(e)
        }

        this.socket = new WebSocket(this.url)
        this._addListener(this.socket)

        this.addComponents(this.components)
    }

    _addListener(ws) {
        ws.onopen = () => {
            this.isOpen = true
            try {
                // this.login()
                this.messageQueue.length > 0 && this.messageQueue.forEach(message => {
                    console.log('已发送缓存消息: ', message)
                    this.send(message)
                })
                this.messageQueue = []
                // this.messages && this.messages.forEach(message => {
                //     this.send(message)
                // })
                for (const key in this.componentsMap.comment) {
                    console.log('socket断开已经重连，已发送 joinGroup ',key)
                    this.send({
                        action: 'joinGroup',
                        groupId: key
                    })
                } 
            } catch (e) {
                console.log(e)
            }
        }

        ws.onmessage = e => {
            this.is_connection = true
            const data = JSON.parse(e.data)
            if (data.type) {
                const target = this.componentsMap[data.type]
                for (const key in target) {
                    if (Object.hasOwnProperty.call(target, key)) {
                        const handler = target[key]
                        handler && handler(data)
                    }
                }
            }
        }

        ws.onclose = e => {
            this.isOpen = false
            if (!e.wasClean) {
                this.is_connection = false
                this._reconnection()
            } else {
                ws = null
            }
        }

        ws.onerror = e => {
            console.log('error', e)
            this.isOpen = false
        }
    }

    _reconnection() {
        setTimeout(() => {
            if (!this.is_connection) {
                if (this.count > 30) return
                this._initSocket()
                this._reconnection()
                this.count++
            } else {
                this.count = 1
            }
        }, parseInt(this.count / 5) * this.interval + this.interval)
    }

    reopen() {
        this._initSocket()
    }

    close() {
        this.socket.close()
    }

    send(message) {
        if (!this.isOpen) {
            console.log('已缓存消息:', message)
            this.messageQueue.push(message)
        } else {
            this.socket.send(JSON.stringify(SocketMessageMaker.make(message)))
        }
    }

    addComponent(component) {
        let {
            target = 'common',
            type,
            handler,
        } = component
        if (!this.componentsMap[type]) this.componentsMap[type] = {}
        if (!this.componentsMap[type][target]) {
            this.componentsMap[type][target] = {}
            if (!this.targetCache[target]) this.targetCache[target] = []
         } 
        this.componentsMap[type][target] = handler
        this.targetCache[target].push(component)
    }

    removeComponent(component) {
        let {
            target = 'common',
            type,
        } = component
        if (!this.componentsMap[type]) {
            console.error('不存在的type')
            return
        }
        delete this.componentsMap[type][target]
    }

    addComponents(components) {
        components && components.forEach(component => {
            this.addComponent(component)
        })
    }

    removeComponents(components) {
        components && components.forEach(component => {
            this.removeComponent(component)
        })
    }

    // 业务相关
    login(params) {
        this.send({
            action: 'login',
            ...params
        })
    }

    join(groupId, components) {
        try {
            this.addComponents((Array.isArray(components) ? components : [components]).map(component=>{
                return {
                    target: groupId,
                    ...component
                }
            }))
            this.send({
                action: 'joinGroup',
                groupId
            })
        }catch(e){
            console.log(e)
        }
        for (const key in this.componentsMap.comment) {
            console.log(key)
        } 
    }

    leave(groupId) {
        this.removeComponents(this.targetCache[groupId])
        this.targetCache[groupId] = []
        this.send({
            action: 'leaveGroup',
            groupId
        })
    }
}

GATSocket.MessageMaker = SocketMessageMaker

export default GATSocket