

export default [
  {
    path: '/reports',
    name: 'reports',
    meta: {
        title: '大会报道'
    },
    component: () => import('@/views/reports')
  },
  // reportsDetail
  {
    path: '/reportsDetail',
    name: 'reportsDetail',
    meta: {
        title: '大会报道'
    },
    component: () => import('@/views/reportsDetail')
  },
]