export default {
    setSocket(state, socket) {
        state.socket = socket
    },
    setIsLogin(state, isLogin) {
        state.isLogin = isLogin
    },
    setIsListeningAccount(state, isListeningAccount) {
        state.isListeningAccount = isListeningAccount
    }
}