<template>
  <div class="expert-select-list">
    <van-overlay :show="show" class="overlay-main" z-index="2001">
      <div class="main">
        <div class="title">
          <span>选择当前操作的专家任务单</span>
        </div>
        <div class="list">
          <div
            class="list-item"
            v-for="(item, index) in list"
            :key="index"
            @click="$emit('selectItem', item)"
          >
            <div class="item-left">
              <div class="item-left-photo">
                <img :src="item.guest_photo" alt />
              </div>
              <div class="item-left-person">
                <div class="person-name">
                  <span>{{item.guest_name}}</span>
                </div>
                <div class="person-det">
                  <span>{{item.guest_position_name}}</span>
                </div>
                <div class="person-hospital van-ellipsis">{{item.guest_hospital_name}}</div>
                <div class="person-office van-ellipsis">{{item.guest_department_name}}</div>
              </div>
            </div>
            <div class="item-right">
              <van-icon name="arrow" />
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
export default {
    props: {
        show: {},
        list: {}
    },
    mounted() {},
    methodes: {}
}
</script>

<style lang="scss" scoped>
.overlay-main {
    display: flex;
    align-items: center;
    justify-content: center;
}
.main {
    width: calc(100% - 2.14rem);
    background: #ffffff;
    border-radius: 0.09rem;
    .title {
        width: 100%;
        height: 1.71rem;
        text-align: center;
        line-height: 1.71rem;

        span {
            font-size: 0.6rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #222222;
        }
    }
    .list-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.43rem;
        height: 4.69rem;
        background: #ffffff;
        border-top: 1px solid #ececec;
        .item-left {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .item-left-photo {
                width: 3.84rem;
                height: 3.84rem;
                img {
                    width: 3.84rem;
                    height: 3.84rem;
                }
            }
            .item-left-person {
                width: 100%;
                margin-left: 0.43rem;
                display: flex;
                flex-direction: column;
                .person-name {
                    span {
                        font-size: 0.77rem;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #222222;
                    }
                }
                .person-det {
                    span {
                        font-size: 0.6rem;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #222222;
                    }
                }
                .person-hospital {
                    width: 8.15rem;
                    margin-top: 0.34rem;

                    font-size: 0.51rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #888888;
                }
                .person-office {
                    width: 8.15rem;

                    font-size: 0.51rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #888888;
                }
            }
        }
        .item-right {
            color: rgba(0, 0, 0, 0.85);
            font-size: 0.6rem;
        }
    }
}
</style>