
import os from '@utils/os'
import Utils from '@utils'
import host from '@request/host'

// if (/https:\/\/test-m.docbook.com.cn/.test(location.href) || /http:\/\/test-m.docbook.com.cn/.test(location.href) ) location.href = 'https://m.docbook.com.cn'

if (!window.location.origin) {
    window.location.origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
}

const fullPath = location.href.split('#')[1]

setTimeout(() => {
    if (fullPath) {

        const oldPaths = ['/liaoning_infor', '/liaoning_professional', '/liaoning_register', '/liaoning_sub_result', '/liaoning_sub_succss_result', '/index_area', '/contontdetail', '']

        let href = ''

        const fullPaths = fullPath.split('?')

        const path = fullPaths[0]

        if (oldPaths.findIndex(item => item === path) != -1) {
            href = `/oldm/#${fullPath}`
        } else {
            const modifyPathMap = {
                '/person_center_unlogin': '/personalCenter',
                '/person_center_login': '/personalCenter',
                '/status_doing': '/statusVerification',
                '/status_fail': '/statusVerification',
                '/status_no_success': '/statusVerification',
                '/status_success': '/statusVerification',
                '/meeting_notice_detail': '/meetingHome',
                '/meetZlive': '/meetingDetail',
                '/meetZliveOld': '/meetingDetail',
                '/meet_back': '/meetingDetail',
                '/index_Structuralweek_new': '/meetingHome',
                '/index_Structuralweek': '/meetingHome',
                '/jgzFeatureDetail': '/hallDetail', // 要修改
                '/jgzNewsDetail': '/reportsDetail',
                '/YSComment': '/ypComment',
                '/YSExperts': '/ypExperts',
                '/YSSchedule': '/ypSchedule',
                '/HomeConsultingDetail': '/homeConsultingDetail',
                '/UserCenter': '/userCenter',
                '/ShareDeployRegister': '/shareDeployRegister',
                '/JGZShareNews': '/reports',
                '/JGZShareFeature': '/bigMeetingHall'
            }
            const modifyPath = modifyPathMap[path]
            let query = fullPaths[1]
            function replaceQuery (oldKey, newKey) {
                return query.split('&').reduce((queryStr, item, index) => {
                    const pair = item.split('=')
                    if (index > 0) queryStr += '&'
                    if (pair[1]) {
                        if (pair[0] == oldKey) queryStr += `${newKey}=${pair[1]}`
                        else queryStr += `${pair[0]}=${pair[1]}`
                    }
                    return queryStr
                }, '')
            }
            const paths = path.split('_')
            if (modifyPath) {
                if (path == '/meetZlive' || path == '/meetZliveOld' || path == '/index_Structuralweek_new' || path == '/index_Structuralweek') {
                    query = replaceQuery('meetLiveId', 'meeting_id')
                }
                href = `${modifyPath}${query ? '?' + query : ''}`
            } else {
                if (path == '/meeting_playback_detail') {
                    query = replaceQuery('meet_id', 'meeting_id')
                }
                else if (path == '/meetZlive' || path == '/meetZliveOld' || path == '/index_Structuralweek_new' || path == '/index_Structuralweek') {
                    query = replaceQuery('meetLiveId', 'meeting_id')
                }
                href = `${paths.map(item => item.charAt(0).toUpperCase() + item.slice(1)).join('')}?${query}`
            }
        }

        if (href) location.href = location.origin + href
    }
    else if (os.isPC() && !/heartm\.docbook/.test(location.href) && !/localhost/.test(location.href)) {
        function redirectPC (path, id, page) {
            // if (!os.isProduct()) return
            let allQuery = Utils.getAllQuery()
            delete allQuery[id]

            let url = ''
            if (id) {
                url = `https://${host.pcEnv}www.docbook.com.cn/${path}/${Utils.getQueryString(id)}`
            } else {
                url = `https://${host.pcEnv}www.docbook.com.cn/${path}`
            }

            // 处理专家任务单互跳
            if (page || page == 0) {
                let tcode = ''
                if (localStorage.getItem('expertTaskLogin')) {
                    let obj = JSON.parse(localStorage.getItem('expertTaskLogin'))
                    let key = Object.keys(obj)[0]
                    tcode = obj[key]
                } else {
                    tcode = allQuery.tcode || ''
                }
                if (Object.keys(allQuery).indexOf('tcode') == -1 && tcode) {
                    allQuery['page'] = page
                    allQuery['tcode'] = tcode
                } else {
                    allQuery['page'] = page
                }
            }
            // 处理pc医谱登录 删除敏感参数
            if (allQuery.token) {
                delete allQuery['token']
            }
            if (allQuery.refresh_token) {
                delete allQuery['refresh_token']
            }
            if (allQuery.sys_token) {
                delete allQuery['sys_token']
            }
            location.href = Object.keys(allQuery).reduce((url, curKey, index) => {
                url += (index === 0 ? '?' : '&')
                url += `${curKey}=${allQuery[curKey]}`
                return url
            }, url)
        }
        if (/\/hallDetail/.test(location.href)) {
            redirectPC('hallDetail', 'id')
        }
        else if (/\/reportsDetail/.test(location.href)) {
            redirectPC('reportsDetail', 'id')
        }
        else if (/\/meetingDetail/.test(location.href)) {
            redirectPC('meetingDetail', 'meeting_id')
        }
        else if (/\/meetingHome/.test(location.href)) {
            redirectPC('meetingHome', 'meeting_id')
        }
        else if (/\/operationDetail/.test(location.href)) {
            redirectPC('operationDetail', 'id')
        }
        else if (/\/doctorSeriesCourses/.test(location.href)) {
            redirectPC('doctorSeriesCourses', 'production_id')
        }
        else if (/\/doctorSharePage/.test(location.href)) {
            redirectPC('doctorSharePage', 'production_id')
        }
        else if (/\/expertSharePage/.test(location.href)) {
            redirectPC('expertDetail', 'guest_id')
        }
        else if (/\/adDetail/.test(location.href)) {
            redirectPC('adDetail', 'id')
        }
        else if (/\/doctorMainPage/.test(location.href)) {
            redirectPC('doctorDetail', 'doctor_id')
        }
        else if (/\/homeConsultingDetail/.test(location.href)) {
            redirectPC('information', 'information_id')
        }
        else if (/\/expertList/.test(location.href)) {
            redirectPC('doctorList', 'college_id')
        }
        else if (/\/channelClass/.test(location.href)) {
            // if (Utils.getAllQuery()['type'] == 4) redirectPC('informationList', 'college_id')
            if (Utils.getAllQuery()['type'] == 1) redirectPC('meeting')
        }
        else if (/\/userCenter/.test(location.href)) {
            redirectPC('userCenter', 'meeting_id')
        } else if (/\/expertsSchedule/.test(location.href) && !/\/expertsScheduleUniversal/.test(location.href)) {
            redirectPC('expertTaskList', 'id', 3) //我的日程
        } else if (/\/specimenChamberLogin/.test(location.href)) {
            redirectPC('expertTaskList', 'id', 4) //会签测试
        } else if (/\/expertTaskList/.test(location.href)) {
            redirectPC('expertTaskList', 'id', 1)
        } else if (/\/expertConfirmation/.test(location.href)) {
            redirectPC('expertTaskList', 'id', 2) //任务确认
        } else if (/\/taskList/.test(location.href)) {
            redirectPC('expertTaskList', 'id', 1)
        } else if (/\/download/.test(location.href)) {
            redirectPC('download')
        } else if (/\/doctorSharePage/.test(location.href)) { //课程的
            redirectPC('doctorSharePage', 'production_id')
        } else if (/\/doctorSeriesCourses/.test(location.href)) { //课程的
            redirectPC('doctorSeriesCourses', 'production_id')
        }

    }
}, 0)
