export default {
    "v": "5.7.6",
    "fr": 25,
    "ip": 0,
    "op": 25,
    "w": 20,
    "h": 20,
    "nm": "合成 1",
    "ddd": 0,
    "assets": [],
    "layers": [{
      "ddd": 0,
      "ind": 1,
      "ty": 4,
      "nm": "3",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [24.062, 9.939, 0],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [0, 0, 0],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [100, 100, 100],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [{
        "ty": "rc",
        "d": 1,
        "s": {
          "a": 0,
          "k": [16, 16],
          "ix": 2
        },
        "p": {
          "a": 0,
          "k": [0, 0],
          "ix": 3
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 4
        },
        "nm": "矩形路径 1",
        "mn": "ADBE Vector Shape - Rect",
        "hd": false
      }, {
        "ty": "tm",
        "s": {
          "a": 1,
          "k": [{
            "i": {
              "x": [0.52],
              "y": [1]
            },
            "o": {
              "x": [0.48],
              "y": [0]
            },
            "t": 0,
            "s": [52]
          }, {
            "i": {
              "x": [0.52],
              "y": [1]
            },
            "o": {
              "x": [0.48],
              "y": [0]
            },
            "t": 3,
            "s": [52]
          }, {
            "i": {
              "x": [0.52],
              "y": [1]
            },
            "o": {
              "x": [0.48],
              "y": [0]
            },
            "t": 18,
            "s": [52]
          }, {
            "i": {
              "x": [0.52],
              "y": [1]
            },
            "o": {
              "x": [0.48],
              "y": [0]
            },
            "t": 25,
            "s": [52]
          }, {
            "i": {
              "x": [0.52],
              "y": [1]
            },
            "o": {
              "x": [0.48],
              "y": [0]
            },
            "t": 34,
            "s": [52]
          }, {
            "t": 49,
            "s": [52]
          }],
          "ix": 1
        },
        "e": {
          "a": 1,
          "k": [{
            "i": {
              "x": [0.52],
              "y": [0.96]
            },
            "o": {
              "x": [0.48],
              "y": [0.04]
            },
            "t": 0,
            "s": [68]
          }, {
            "i": {
              "x": [0.52],
              "y": [0.945]
            },
            "o": {
              "x": [0.48],
              "y": [0.055]
            },
            "t": 3,
            "s": [72]
          }, {
            "i": {
              "x": [0.52],
              "y": [0.975]
            },
            "o": {
              "x": [0.48],
              "y": [0.025]
            },
            "t": 18,
            "s": [55]
          }, {
            "i": {
              "x": [0.52],
              "y": [0.96]
            },
            "o": {
              "x": [0.48],
              "y": [0.04]
            },
            "t": 25,
            "s": [66]
          }, {
            "i": {
              "x": [0.52],
              "y": [0.96]
            },
            "o": {
              "x": [0.48],
              "y": [0.04]
            },
            "t": 34,
            "s": [72]
          }, {
            "t": 49,
            "s": [55]
          }],
          "ix": 2
        },
        "o": {
          "a": 0,
          "k": 0,
          "ix": 3
        },
        "m": 1,
        "ix": 2,
        "nm": "修剪路径 1",
        "mn": "ADBE Vector Filter - Trim",
        "hd": false
      }, {
        "ty": "st",
        "c": {
          "a": 0,
          "k": [1, 1, 1, 1],
          "ix": 3
        },
        "o": {
          "a": 0,
          "k": 100,
          "ix": 4
        },
        "w": {
          "a": 0,
          "k": 3,
          "ix": 5
        },
        "lc": 2,
        "lj": 2,
        "bm": 0,
        "nm": "描边 1",
        "mn": "ADBE Vector Graphic - Stroke",
        "hd": false
      }],
      "ip": 0,
      "op": 25,
      "st": 0,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 2,
      "ty": 4,
      "nm": "2",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [18.25, 9.939, 0],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [0, 0, 0],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [100, 100, 100],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [{
        "ty": "rc",
        "d": 1,
        "s": {
          "a": 0,
          "k": [16, 16],
          "ix": 2
        },
        "p": {
          "a": 0,
          "k": [0, 0],
          "ix": 3
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 4
        },
        "nm": "矩形路径 1",
        "mn": "ADBE Vector Shape - Rect",
        "hd": false
      }, {
        "ty": "tm",
        "s": {
          "a": 1,
          "k": [{
            "i": {
              "x": [0.52],
              "y": [1]
            },
            "o": {
              "x": [0.48],
              "y": [0]
            },
            "t": 0,
            "s": [52]
          }, {
            "i": {
              "x": [0.52],
              "y": [1]
            },
            "o": {
              "x": [0.48],
              "y": [0]
            },
            "t": 12,
            "s": [52]
          }, {
            "t": 25,
            "s": [52]
          }],
          "ix": 1
        },
        "e": {
          "a": 1,
          "k": [{
            "i": {
              "x": [0.52],
              "y": [0.96]
            },
            "o": {
              "x": [0.48],
              "y": [0.04]
            },
            "t": 0,
            "s": [72]
          }, {
            "i": {
              "x": [0.52],
              "y": [0.96]
            },
            "o": {
              "x": [0.48],
              "y": [0.04]
            },
            "t": 12,
            "s": [54]
          }, {
            "t": 25,
            "s": [72]
          }],
          "ix": 2
        },
        "o": {
          "a": 0,
          "k": 0,
          "ix": 3
        },
        "m": 1,
        "ix": 2,
        "nm": "修剪路径 1",
        "mn": "ADBE Vector Filter - Trim",
        "hd": false
      }, {
        "ty": "st",
        "c": {
          "a": 0,
          "k": [1, 1, 1, 1],
          "ix": 3
        },
        "o": {
          "a": 0,
          "k": 100,
          "ix": 4
        },
        "w": {
          "a": 0,
          "k": 3,
          "ix": 5
        },
        "lc": 2,
        "lj": 2,
        "bm": 0,
        "nm": "描边 1",
        "mn": "ADBE Vector Graphic - Stroke",
        "hd": false
      }],
      "ip": 0,
      "op": 25,
      "st": 0,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 3,
      "ty": 4,
      "nm": "1",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [12, 9.939, 0],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [0, 0, 0],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [100, 100, 100],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [{
        "ty": "rc",
        "d": 1,
        "s": {
          "a": 0,
          "k": [16, 16],
          "ix": 2
        },
        "p": {
          "a": 0,
          "k": [0, 0],
          "ix": 3
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 4
        },
        "nm": "矩形路径 1",
        "mn": "ADBE Vector Shape - Rect",
        "hd": false
      }, {
        "ty": "tm",
        "s": {
          "a": 1,
          "k": [{
            "i": {
              "x": [0.52],
              "y": [1]
            },
            "o": {
              "x": [0.48],
              "y": [0]
            },
            "t": 0,
            "s": [52]
          }, {
            "i": {
              "x": [0.52],
              "y": [1]
            },
            "o": {
              "x": [0.48],
              "y": [0]
            },
            "t": 9,
            "s": [52]
          }, {
            "i": {
              "x": [0.52],
              "y": [1]
            },
            "o": {
              "x": [0.48],
              "y": [0]
            },
            "t": 18,
            "s": [52]
          }, {
            "i": {
              "x": [0.52],
              "y": [1]
            },
            "o": {
              "x": [0.48],
              "y": [0]
            },
            "t": 26,
            "s": [52]
          }, {
            "i": {
              "x": [0.52],
              "y": [1]
            },
            "o": {
              "x": [0.48],
              "y": [0]
            },
            "t": 71,
            "s": [52]
          }, {
            "i": {
              "x": [0.52],
              "y": [1]
            },
            "o": {
              "x": [0.48],
              "y": [0]
            },
            "t": 76,
            "s": [52]
          }, {
            "i": {
              "x": [0.52],
              "y": [1]
            },
            "o": {
              "x": [0.48],
              "y": [0]
            },
            "t": 96,
            "s": [52]
          }, {
            "t": 126,
            "s": [52]
          }],
          "ix": 1
        },
        "e": {
          "a": 1,
          "k": [{
            "i": {
              "x": [0.52],
              "y": [0.96]
            },
            "o": {
              "x": [0.48],
              "y": [0.04]
            },
            "t": 0,
            "s": [60]
          }, {
            "i": {
              "x": [0.52],
              "y": [0.969]
            },
            "o": {
              "x": [0.48],
              "y": [0.031]
            },
            "t": 9,
            "s": [72]
          }, {
            "i": {
              "x": [0.52],
              "y": [0.945]
            },
            "o": {
              "x": [0.48],
              "y": [0.055]
            },
            "t": 18,
            "s": [53]
          }, {
            "i": {
              "x": [0.52],
              "y": [0.96]
            },
            "o": {
              "x": [0.48],
              "y": [0.04]
            },
            "t": 26,
            "s": [60]
          }, {
            "i": {
              "x": [0.52],
              "y": [0.96]
            },
            "o": {
              "x": [0.48],
              "y": [0.04]
            },
            "t": 71,
            "s": [72]
          }, {
            "i": {
              "x": [0.52],
              "y": [0.96]
            },
            "o": {
              "x": [0.48],
              "y": [0.04]
            },
            "t": 76,
            "s": [54]
          }, {
            "i": {
              "x": [0.52],
              "y": [0.96]
            },
            "o": {
              "x": [0.48],
              "y": [0.04]
            },
            "t": 96,
            "s": [60]
          }, {
            "t": 126,
            "s": [54]
          }],
          "ix": 2
        },
        "o": {
          "a": 0,
          "k": 0,
          "ix": 3
        },
        "m": 1,
        "ix": 2,
        "nm": "修剪路径 1",
        "mn": "ADBE Vector Filter - Trim",
        "hd": false
      }, {
        "ty": "st",
        "c": {
          "a": 0,
          "k": [1, 1, 1, 1],
          "ix": 3
        },
        "o": {
          "a": 0,
          "k": 100,
          "ix": 4
        },
        "w": {
          "a": 0,
          "k": 3,
          "ix": 5
        },
        "lc": 2,
        "lj": 2,
        "bm": 0,
        "nm": "描边 1",
        "mn": "ADBE Vector Graphic - Stroke",
        "hd": false
      }],
      "ip": 0,
      "op": 25,
      "st": 0,
      "bm": 0
    }],
    "markers": []
}