export default {
  setShowButton(state, showButton) {
    state.showButton = showButton;
  },
  clean(state) {
    state.showButton = false;
  },
  setisWhitelist(state, isWhitelist) {
    state.isWhitelist = isWhitelist;
  },
  setIsOpen(state, isOpen) {
    state.isOpen = isOpen;
  },
  setisWhiteListFlag(state, isWhiteListFlag) {
    state.isWhiteListFlag = isWhiteListFlag;
  },
  setIsWatch_type(state, isWatch_type) {
    state.isWatch_type = isWatch_type;
  },
  // can_get_card
  setCan_get_card(state, can_get_card) {
    state.can_get_card = can_get_card;
  },
  setIsSchedule(state, data) {
    console.log('--------store-------', data)
    state.isSchedule = data
  }
};

