import Request from '@request'
import api from '@api'
import utils from '@utils'
import os from '@utils/os'

export default {
    abbott({
        dispatch,
        commit,
        state,
        rootState
    }, params) {
        const host = os.isProduct() ? 'https://ep.abbott-md.cn' : 'https://v.tunnel.yuemia.com'
        return new Promise((resolve, reject) => {
            Request.lightGet(host + api.platform.abbott, {
                route: 'true',
                ...params
            }).then(res => {
                if (res.data.url) {
                    commit('setPlatformUrl',res.data.url)
                    commit('player/setRouterPath',{
                        home: '',
                        back: res.data.url
                    },{
                        root: true
                    })
                }
                resolve(res.data)
            })
        })
    },
    checkUidLogin({
        dispatch,
        commit,
        state,
        rootState
    }, params) {
        return new Promise((resolve, reject) => {
            Request.post(api.platform.checkUidLogin, params).then(res => {
                commit('setPlatformInfo', params)
                commit('user/setUserLoginData',res.data, {
                    root: true
                })
                // localStorage.setItem("login_photo", res.data.photo)
                resolve(res.data)
            })
        })
    },
}