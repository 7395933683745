import { render, staticRenderFns } from "./HomeVideoItem.vue?vue&type=template&id=0c0f704b&scoped=true&"
import script from "./HomeVideoItem.vue?vue&type=script&lang=js&"
export * from "./HomeVideoItem.vue?vue&type=script&lang=js&"
import style0 from "./HomeVideoItem.vue?vue&type=style&index=0&id=0c0f704b&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c0f704b",
  null
  
)

export default component.exports