import Request from "@request";
import api from "@api";
import os from '@utils/os'
import javaRequest from '@utils/request/javaRequest'

export default {
  getAgreement({ dispatch, commit, state, rootState }, params) {
    return new Promise((resolve, reject) => {
      Request.post(api.common.agreement, params).then((res) => {
        const { data } = res;
        // commit('setAgreements', data)
        console.log(data, "获取协议列表");
        resolve(res);
      });
    });
  },
  getDiseaseMenu({ dispatch, commit, state, rootState }, params) {
    return new Promise((resolve, reject) => {
      Request.post(api.common.getDiseaseMenu, params).then((res) => {
        const { data } = res;
        console.log(data, "疾病详情菜单");
        resolve(res);
      });
    });
  },
  sendCode({ dispatch, commit, state, rootState }, params) {
    console.log(params);
    return new Promise((resolve, reject) => {
      Request.post(api.common.sendCode, params).then((res) => {
        resolve(res);
      });
    });
  },
  uploadFile({ dispatch, commit, state }, file) {
    return new Promise((resolve, reject) => {
      Request.upload({ file })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },
  attentionDoctor({ dispatch, commit, state }, params) {
    return new Promise((resolve, reject) => {
      Request.post(api.common.attentionDoctor, params, false)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  serviceconfig({ dispatch, commit, state }, params) {
    return new Promise((resolve, reject) => {
      Request.post(api.common.serviceconfig, params, false)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getappversion({ dispatch, commit, state }, params) {
    return new Promise((resolve, reject) => {
      Request.post(api.common.getappversion, { version_type: 1 }, false)
        .then((res) => {
          resolve(res);
          let { data } = res;
          commit("setAppMessage", data);
          commit("setIfReviewTime", data.if_review_time);
        })
        .catch((status) => {
          reject(status);
        });
    });
  },
  getusertemplateinfo({ dispatch, commit, state }, params) {
    return new Promise((resolve, reject) => {
      Request.post(api.common.getusertemplateinfo, params, false)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  // 是否加入科联体
  getisDeptUnion({ dispatch, commit, state }, params) {
    console.log(state)
    return new Promise((resolve, reject) => {
      javaRequest.get('/MedicalCollege/departments/union', params, false)
        .then((res) => {
          resolve(res)
          commit('setisDeptUnion', res.result.total ? true : false)
        })
    })
  },
  getAnswerStatus({ dispatch, commit, state }, params) {
    return new Promise((resolve, reject) => {
      Request.post(api.common.getAnswerStatus, params)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  tipoff({ dispatch, commit, state }, params) {
    return new Promise((resolve, reject) => {
      Request.post(api.common.tipoff, params)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
