export default [
  {
    path: '/baseDoctor',
    name: 'BaseDoctor',
    meta: {
      title: '协同门诊',
    },
    component: () => import('@/views/baseDoctor/index'),
  },
  {
    path: '/editPatientMsg',
    name: 'EditPatientMsg',
    meta: {
      title: '病患信息编辑',
    },
    component: () => import('@/views/baseDoctor/editPatientMsg'),
  },
  {
    path: '/baseDoctorOrderDetail',
    name: 'BaseDoctorOrderDetail',
    meta: {
      title: '订单详情',
    },
    component: () => import('@/views/baseDoctor/orderDetail'),
  },
  {
    path: '/superiorDoctorOrderDetail',
    name: 'SuperiorDoctorOrderDetail',
    meta: {
      title: '订单详情',
    },
    component: () => import('@/views/baseDoctor/receivedOrderDetail'),
  },
]
