<template>
    <div class="select-popup">
        <div class="navbar">
            <div class="navbar_left" @click="$emit('goPersonCenter')">
                <template v-if="!isInApp">
                    <img
                        v-if="userInfos && userInfos.img"
                        :src="userInfos.img"
                        class="login_img"
                        alt=""
                    />
                    <img v-else src="@images/home/my_photo.png" alt="" />
                </template>
            </div>
            <div class="navbar_center">{{ collegeText }}</div>
            <div class="navbar_right" @click="$emit('closed')">
                切换学科
                <img src="@images/home/rightDown.png" alt="" />
            </div>
        </div>
        <div class="cut-couser">
            <p>点击切换学科</p>
            <van-icon name="arrow-up" @click="$emit('closed')" />
        </div>
        <ul class="popup-list">
            <li
                v-for="(college, index) in allCollege"
                :key="index"
                :class="[collegeID == college.college_id ? 'popup-li-active' : '', 'popup-li']"
                :style="{
                    width: college.college_name.length > 5 ? '7.33rem' : '3.41rem'
                }"
                @click="changePopupCollege(college)"
            >
                <p :class="[collegeID == college.college_id ? 'popup-p-active' : '', 'popup-p']">
                    {{ college.college_name }}
                </p>
            </li>
        </ul>
    </div>
</template>
<script>
import { mapGetters, mapState } from '@utils/vuex'
export default {
    props: {
        showPopupBox: {
            type: Boolean
        }
    },
    data() {
        return {
            collegeText: '',
            isCourseClosed: true,
            imgshow: true
        }
    },
    computed: {
        userInfos() {
            return this.$store.state.user.userInfos
        },
        ...mapState('home', ['allCollege', 'collegeID']),
        ...mapGetters(['contentWidth', 'contentLeft', 'isInApp'])
    },
    mounted() {
        this.collegeText = this.$route.query.collegeName || '全部学科'
        this.$store.dispatch('home/getAllCollege').then(res => {
            let { data } = res
            this.$store.commit('home/setAllCollege', data)
            data.map(item => {
                if (item.college_id == this.collegeID) {
                    this.collegeText = item.college_name
                }
            })
        })
    },
    methods: {
        coursePopClosed() {
            this.isCourseClosed = true
        },
        overlayPopup() {
            // this.showPopupBox = !this.showPopupBox
            // if (this.showPopupBox) this.isCourseClosed = false
            // // this.imgshow = !this.imgshow
            // // if (this.isLogin) {
            // //     this.$store.commit('setHomeScrollTop', 0)
            // //     this.$store.commit('setKeepAlive', [])
            // // } else {
            // //     this.loginType = 'college'
            // //     this.showLogin = true
            // // }
        },
        changePopupCollege(item) {
            console.log(item)
            this.collegeText = item.college_name || '全部学科'
            // this.collegeID = item.college_id
            this.$store.commit('home/setCollegeID', item.college_id)
            this.$emit('changePopupCollege', item)
        }
    }
}
</script>
<style lang="scss" scoped>
@import '@styles/variables.scss';
.navbar {
    position: relative;
    width: 100%;
    height: 2.01rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.69rem 0 0.69rem;
    background: #0089ff;
    .navbar_left {
        width: 2.96rem;
        text-align: left;
        img {
            width: 0.78rem;
            height: 0.87rem;
            // border-radius: 100%;
        }
        .login_img {
            width: 1.14rem;
            height: 1.14rem;
            border-radius: 100%;
        }
    }
    .navbar_center {
        flex: 1;
        font-size: $fsxl;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
    }
    .navbar_right {
        width: 2.96rem;
        cursor: pointer;
        font-size: $fslg;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        text-align: right;
        img {
            width: 0.18rem;
            height: 0.18rem;
        }
    }
}
.popup-box ::v-deep {
    position: absolute;
    // top: 1.92rem;
    border-radius: 0rem 0rem 0.17rem 0.17rem;
}
.cut-couser {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.3rem 0.64rem;
    height: 1.28rem;
    color: #888888;
    p {
        font-size: 0.51rem;
        font-weight: 400;
        color: #888888;
    }
}
.popup-list {
    padding: 0.43rem 0.3rem 1.28rem 0;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    .popup-li {
        cursor: pointer;
        display: flex;
        margin: 0 0 0.64rem 0.51rem;
        // width: 3.41rem;
        height: 1.19rem;
        border-radius: 0.64rem;
        background: #f1f1f5;
        .popup-p {
            margin: auto;
            font-size: 0.51rem;
            font-weight: 400;
            line-height: 0.7rem;
            color: #888888;
        }
        .popup-p-active {
            color: #ffffff;
        }
    }
    .popup-li-active {
        background: #0089ff;
    }
}
.active-img {
    transform: rotate(180deg);
}
</style>