<template>
    <div id="load_wrap" :style="{ position: isInApp && showTabbar ? 'relative' : 'fixed' }">
        <div class="load">
            <lottie :options="options" class="lottie" />
            <p>加载中</p>
        </div>
    </div>
</template>

<script>
import Lottie from '@components/Lottie'
import globalLoading from '@animation/globalLoading'
import { mapGetters } from '@utils/vuex'

export default {
    components: {
        Lottie
    },
    data() {
        return {
            options: {
                loop: true,
                animationData: globalLoading
            }
        }
    },
    computed: {
        ...mapGetters(['isInApp']),
        showTabbar() {
            return this.$store.state.common.showTabbar
        }
    }
}
</script>

<style lang="scss" scoped>
#load_wrap {
    width: 100%;
    height: 100%;
    position: fixed;
    background: #fff;
    left: 0;
    top: 0;
    z-index: 1000000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.load {
    width: 116px;
    height: 66px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.lottie {
    width: 1.4rem;
}

.load p {
    text-align: center;
    color: #2196f3;
}
</style>