export default {
    surgicalList: '/api/web/v3/production/surgicalList',//手术&搜索

    receivedOrderList: '/MedicalCollege/departments/union/outPatient/receiveAppointmentList', // 收到的预约
    orderDetail: '/MedicalCollege/departments/union/outPatient/appOrderDetails', // 收到预约详情/我的预约详情
    myOrderList: '/MedicalCollege/departments/union/outPatient/myAppointmentList', // 我的预约列表
    cancelOrder: '/MedicalCollege/departments/union/outPatient/cancelOrder', // 取消订单
    // createOrder: '/MedicalCollege/departments/union/outPatient/createOrder', // 创建预约订单
    startOrder: '/MedicalCollege/departments/union/outPatient/startConsultation', // 接诊
    // overOrder: '/MedicalCollege/departments/union/outPatient/endConsultation', // 结诊
}