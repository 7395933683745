import Request from '@request'
import api from '@api'
import utils from '@utils'

export default {
    setWXConfig({
        dispatch,
        commit,
        state,
        rootState
    }, params) {
        return new Promise((resolve, reject) => {
            Request.post(api.common.wxShare, {
                url: location.href
            }).then(res => {
                const {data} = res
                commit('setWXConfig', data)
                resolve(data)
            }).catch(_=>{
                reject()
            })
        })
    },
    getWXInfo({
        dispatch,
        commit,
        state
    }, params) {
        return new Promise((resolve, reject) => {
            Request.post(api.common.getWXInfo, params).then(res => {
                commit('user/setUserLoginData', res.data, {root: true})
                const {
                    userid,
                    // refresh_token,
                    // sys_token,
                    // token,
                    // openid,
                    // unionid
                } = res.data
                console.log(res)
                // localStorage.setItem('userid', userid || '')
                // localStorage.setItem("refresh_token", refresh_token || '')
                // localStorage.setItem("sys_token", sys_token || '')
                // localStorage.setItem("token", token || '')
                // localStorage.setItem('wxopenid', openid || '')
                // localStorage.setItem('unionid', unionid || '')
                if (userid && token) {
                    commit('user/setLogin',true, {root: true})
                }
                resolve()
            }).catch(e => reject(e))
        })
    },
    // appGetWXAuthorizeInfo({
    //     dispatch,
    //     commit,
    //     state
    // }, params) {
    //     return new Promise((resolve, reject) => {
    //         Request.post(api.common.getWXAuthorizeInfo, params).then(res => {
    //             const {
    //                 userid,
    //                 refresh_token,
    //                 sys_token,
    //                 token,
    //                 openid,
    //                 unionid,
    //                 need_bind_phone
    //             } = res.data
            
    //             if (need_bind_phone == 1) {
    //                 localStorage.setItem('wxopenid', openid)
    //                 localStorage.setItem('unionid', unionid)
    //                 resolve(true)
    //             }
    //             else {
    //                 localStorage.setItem('userid', userid)
    //                 localStorage.setItem("refresh_token", refresh_token)
    //                 localStorage.setItem("sys_token", sys_token)
    //                 localStorage.setItem("token", token)
    //                 localStorage.setItem('wxopenid', openid)
    //                 localStorage.setItem('unionid', unionid)
    //                 if (userid && token) {
    //                     commit('user/setLogin',true, {root: true})
    //                 }
    //                 resolve(false)
    //             }
                
    //         }).catch(e => reject(e))
    //     })
    // },
    getAuthAddress({
        dispatch,
        commit,
        state
    }, params) {
        return new Promise((resolve, reject) => {
            const openid = localStorage.getItem('wxopenid')
            const unionid = localStorage.getItem('unionid')
            const queryCode = utils.getQueryString('code')
            const auth_url = location.href.replace(`code=${queryCode}`,'').replace('?&','?').replace('&&','&').replace(/&$/,'').replace(/\?$/,'')
            console.log('queryCode: '+queryCode+';auth_url : '+auth_url)
            Request.post(api.common.getAuthAddress, {
                openid,
                unionid,
                auth_url
            }).then(async res => {
                const {
                    if_auth,
                    url,
                    userid,
                    refresh_token,
                    sys_token,
                    token,
                    openid,
                    unionid,
                    photo
                } = res.data

                if (if_auth) {
                    if (userid && token) {
                        commit('user/setLogin',true, {root: true})
                        commit('setAuthInfo',res.data)
                    }
                    // localStorage.setItem("login_photo", photo || '')

                    commit('user/setUserLoginData', res.data, {root: true})
                    // localStorage.setItem('userid', userid || '') 
                    // localStorage.setItem("refresh_token", refresh_token || '')
                    // localStorage.setItem("sys_token", sys_token || '')
                    // localStorage.setItem("token", token || '')
                    // localStorage.setItem('wxopenid', openid || '')
                    // localStorage.setItem('unionid', unionid || '')
                    resolve()
                } else {
                    const code = utils.getQueryString('code')
                    if (code) {
                        dispatch('getWXInfo',{code}).then(res=>{
                            resolve()
                        }).catch(e=>{
                            if (url) location.href = url
                        })
                    }
                    else {
                        location.href = url
                    }
                }                
            }).catch(e => reject(e))
        })
    }
}