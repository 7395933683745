import javaRequest from '@utils/request/javaRequest'
import { NativeMessageNotice } from '@utils/nativeBridge/nativeMessage'
import api from '@api'
export default {
  // 获取消息列表
  getMessagePush({
    dispatch,
    commit,
    state,
    rootState
  }, params) {
    return new Promise((resolve, reject) => {
      if (!rootState.user.isLogin) return reject()
      javaRequest.post(api.messagePush.selectByIdPage, params).then(res => {
        resolve(res)
        if (rootState.common.isInApp) {
          rootState.nativeBridge.nativeBridge.sendMessage(new NativeMessageNotice({
            type: 'badge',
            params: {
              count: res.result.unreadCount + state.quantity * 1 + state.unreadMessages * 1
            }
          }))

        }
        commit('setUnreadCount', res.result.unreadCount)
      }).catch(err => {
        reject(err)
      })
    })
  },
  //  保存设备id
  insertEquipmentRecord({
    dispatch,
    commit,
    state,
    rootState
  }, params) {
    return new Promise((resolve, reject) => {
      if (!rootState.user.isLogin) return reject()
      javaRequest.put(api.messagePush.insertEquipmentRecord, params, false).then(res => {
        resolve(res)

      }).catch(err => {
        reject(err)
      })
    })
  },
  // 更新未读消息
  updateMessageRecord({
    dispatch,
    commit,
    state,
    rootState
  }, params) {
    return new Promise((resolve, reject) => {
      if (!rootState.user.isLogin) return reject()
      javaRequest.put(api.messagePush.updateMessageRecord, params).then(res => {
        resolve(res)
        commit('setUnreadCount', res.result.unreadCount)
        rootState.nativeBridge.nativeBridge.sendMessage(new NativeMessageNotice({
          type: 'badge',
          params: {
            count: res.result.unreadCount + state.quantity * 1 + state.unreadMessages * 1
          }
        }))

      }).catch(err => {
        reject(err)
      })
    })
  },
  updateMessageRecord({
    dispatch,
    commit,
    state,
    rootState
  }, params) {
    return new Promise((resolve, reject) => {
      if (!rootState.user.isLogin) return reject()
      javaRequest.put(api.messagePush.updateMessageRecord, params).then(res => {
        resolve(res)
        commit('setUnreadCount', res.result.unreadCount)
        rootState.nativeBridge.nativeBridge.sendMessage(new NativeMessageNotice({
          type: 'badge',
          params: {
            count: res.result.unreadCount + state.quantity * 1 + state.unreadMessages * 1
          }
        }))

      }).catch(err => {
        reject(err)
      })
    })
  },

  // 查看消息详情
  selectAppPushMessageDetails({
    dispatch,
    commit,
    state,
    rootState
  }, params) {
    return new Promise((resolve, reject) => {
      if (!rootState.user.isLogin) return reject()
      javaRequest.put(api.messagePush.selectAppPushMessageDetails, params).then(res => {
        resolve(res)

      }).catch(err => {
        reject(err)
      })
    })
  },
  // 删除设备记录
  deleteEquipmentRecord({
    dispatch,
    commit,
    state,
    rootState
  }, params) {
    return new Promise((resolve, reject) => {
      javaRequest.post(api.messagePush.deleteEquipmentRecord, params, false).then(res => {
        resolve(res)

      }).catch(err => {
        reject(err)
      })
    })
  },
  // 获取公告未读数
  getUnreadQuantity({
    dispatch,
    commit,
    state,
    rootState
  }, params) {
    return new Promise((resolve, reject) => {
      javaRequest.get('/MedicalCollege/departments/union/notice/getUnreadQuantity', params).then(res => {
        commit('setQuantity', res.result)
        if (rootState.common.isInApp) {

          rootState.nativeBridge.nativeBridge.sendMessage(new NativeMessageNotice({
            type: 'badge',
            params: {
              count: state.unreadCount + res.result * 1 + state.unreadMessages * 1
            }
          }))
        }
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }


}