import store from '@/store'
import os from '@utils/os'

export default {
    type: 'authorization',
    // extra: ,

    path: ['/meetingPlaybackDetail', '/', '/meetingHome', '/meetingDetail', '/expertSharePage', '/doctorSharePage', '/doctorSeriesCourses', '/personalCenter', '/homeConsultingDetail', '/doctorMainPage', '/productDetail', '/companyDetail'],
    // path: [],
    handler(info) {
        return new Promise(async (resolve, reject) => {
            const {
                to,
                from,
            } = info
            if (store.getters.isInApp) return resolve()
            if (store.getters.isLogin === undefined) await store.dispatch('user/getLoginStatus').catch(_ => _)
            const openid = localStorage.getItem('wxopenid')
            if (os.isWXWork() && os.getDevice() === 'mac') { // 修复MAC企业微信分享卡片打不开
                const wxWorkReload = sessionStorage.getItem('isWXWorkReload')
                if (wxWorkReload) sessionStorage.removeItem('isWXWorkReload')
                else {
                    sessionStorage.setItem('isWXWorkReload', 'needReload')
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000)
                }
            }

            if (os.isWechat() !== 3 && !store.getters.isLogin && (!openid || store.state.wechat.authInfo === undefined)) setTimeout(() => {
                store.dispatch('wechat/getAuthAddress')
            }, 100)
            resolve()
        })
    }
}