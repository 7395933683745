export default {
  A: [
    {
      englishName: "Afghanistan",
      chineseName: "阿富汗",
      codeName: "AF",
      areaNumber: "93",
      position: '0 -7648px'
    },
    {
      englishName: "Albania",
      chineseName: "阿尔巴尼亚",
      codeName: "AL",
      areaNumber: "355",
      position: '0 -7744px'
    },
    {
      englishName: "Algeria",
      chineseName: "阿尔及利亚",
      codeName: "DZ",
      areaNumber: "213",
      position: '0 -1120px'
    },
    // {
    //   englishName: "American Samoa",
    //   chineseName: "美属萨摩亚",
    //   codeName: "AS",
    //   areaNumber: "1684",
    // },
    {
      englishName: "Andorra",
      chineseName: "安道尔",
      codeName: "AD",
      areaNumber: "376",
      position: '0 -7584px'
    },
    {
      englishName: "Angola",
      chineseName: "安哥拉",
      codeName: "AO",
      areaNumber: "244",
      position: '0 -7840px'
    },
    // {
    //   englishName: "Anguilla",
    //   chineseName: "安圭拉",
    //   codeName: "AI",
    //   areaNumber: "1264",
    // },
    {
      englishName: "Antigua and Barbuda",
      chineseName: "安提瓜和巴布达",
      codeName: "AG",
      areaNumber: "1268",

      position: '0 -7680px'
    },
    {
      englishName: "Argentina",
      chineseName: "阿根廷",
      codeName: "AR",
      areaNumber: "54",
      position: '0 -7616px'
    },
    {
      englishName: "Armenia",
      chineseName: "亚美尼亚",
      codeName: "AM",
      areaNumber: "374",
      position: '0 -7776px'
    },
    {
      englishName: "Aruba",
      chineseName: "阿鲁巴",
      codeName: "AW",
      areaNumber: "297",
      position: '0 -8032px'
    },
    {
      englishName: "Australia",
      chineseName: "澳大利亚",
      codeName: "AU",
      areaNumber: "61",
      position: '0 -8000px'
    },
    {
      englishName: "Austria",
      chineseName: "奥地利",
      codeName: "AT",
      areaNumber: "43",
      position: '0 -7968px'
    },
    {
      englishName: "Azerbaijan",
      chineseName: "阿塞拜疆",
      codeName: "AZ",
      areaNumber: "994",
      position: '0 -8096px'
    },
  ],
  B: [
    {
      englishName: "Bahamas",
      chineseName: "巴哈马",
      codeName: "BS",
      areaNumber: "1242",
      position: '0 -160px'
    },
    {
      englishName: "Bahrain",
      chineseName: "巴林",
      codeName: "BH",
      areaNumber: "973",
      position: '0 -8320px'
    },
    {
      englishName: "Bangladesh",
      chineseName: "孟加拉国",
      codeName: "BD",
      areaNumber: "880",
      position: '0 -8192px'
    },
    {
      englishName: "Barbados",
      chineseName: "巴巴多斯",
      codeName: "BB",
      areaNumber: "1246",
      position: '0 -8160px'
    },
    {
      englishName: "Belarus",
      chineseName: "白俄罗斯",
      codeName: "BY",
      areaNumber: "375",
      position: '0 -256px'
    },
    {
      englishName: "Belgium",
      chineseName: "比利时",
      codeName: "BE",
      areaNumber: "32",
      position: '0 -8224px'
    },
    {
      englishName: "Belize",
      chineseName: "伯利兹",
      codeName: "BZ",
      areaNumber: "501",
      position: '0 -288px'
    },
    {
      englishName: "Benin",
      chineseName: "贝宁",
      codeName: "BJ",
      areaNumber: "229",
      position: '0 -8384px'
    },
    {
      englishName: "Bermuda",
      chineseName: "百慕大群岛",
      codeName: "BM",
      areaNumber: "1441",
      position: '0 -32px'
    },
    {
      englishName: "Bhutan",
      chineseName: "不丹",
      codeName: "BT",
      areaNumber: "975",
      position: '0 -192px'
    },
    {
      englishName: "Bolivia",
      chineseName: "玻利维亚",
      codeName: "BO",
      areaNumber: "591",
      position: '0 -96px'
    },
    {
      englishName: "Bosnia and Herzegovina",
      chineseName: "波斯尼亚和黑塞哥维那",
      codeName: "BA",
      areaNumber: "387",
      position: '0 -8128px'
    },
    {
      englishName: "Botswana",
      chineseName: "博茨瓦纳",
      codeName: "BW",
      areaNumber: "267",
      position: '0 -224px'
    },
    {
      englishName: "Brazil",
      chineseName: "巴西",
      codeName: "BR",
      areaNumber: "55",
      position: '0 -128px'
    },
    {
      englishName: "Brunei",
      chineseName: "文莱",
      codeName: "BN",
      areaNumber: "673",
      position: '0 -64px'
    },
    {
      englishName: "Bulgaria",
      chineseName: "保加利亚",
      codeName: "BG",
      areaNumber: "359",
      position: '0 -8288px'
    },
    {
      englishName: "Burkina Faso",
      chineseName: "布基纳法索",
      codeName: "BF",
      areaNumber: "226",
      position: '0 -8256px'
    },
    {
      englishName: "Burundi",
      chineseName: "布隆迪",
      codeName: "BI",
      areaNumber: "257",
      position: '0 -8352px'
    },
  ],
  C: [
    {
      englishName: "Cambodia",
      chineseName: "柬埔寨",
      codeName: "KH",
      areaNumber: "855",
      position: '0 -2848px'
    },
    {
      englishName: "Cameroon",
      chineseName: "喀麦隆",
      codeName: "CM",
      areaNumber: "237",
      position: '0 -608px'
    },
    {
      englishName: "Canada",
      chineseName: "加拿大",
      codeName: "CA",
      areaNumber: "514",
      position: '0 -320px'
    },
    {
      englishName: "China",
      chineseName: "中国",
      codeName: "CN",
      areaNumber: "86",
      position: '0 -640px'
    },
    {
      englishName: "Cape Verde",
      chineseName: "开普",
      codeName: "CV",
      areaNumber: "238",
      position: '0 -768px'
    },
    {
      englishName: "Cayman Islands",
      chineseName: "开曼群岛",
      codeName: "KY",
      areaNumber: "1345",
      position: '0 -3072px'
    },
    {
      englishName: "Central African Republic",
      chineseName: "中非共和国",
      codeName: "CF",
      areaNumber: "236",
      position: '0 -416px'
    },
    {
      englishName: "Chad",
      chineseName: "乍得",
      codeName: "TD",
      areaNumber: "235",
      position: '0 -5888px'
    },
    {
      englishName: "Chile",
      chineseName: "智利",
      codeName: "CL",
      areaNumber: "56",
      position: '0 -576px'
    },
    {
      englishName: "Colombia",
      chineseName: "哥伦比亚",
      codeName: "CO",
      areaNumber: "57",
      position: '0 -672px'
    },
    {
      englishName: "Comoros",
      chineseName: "科摩罗",
      codeName: "KM",
      areaNumber: "269",
      position: '0 -2912px'
    },
    // {
    //   englishName: "Cook Islands",
    //   chineseName: "库克群岛",
    //   codeName: "CK",
    //   areaNumber: "682",
    // },
    {
      englishName: "Costa Rica",
      chineseName: "哥斯达黎加",
      codeName: "CR",
      areaNumber: "506",
      position: '0 -704px'
    },
    {
      englishName: "Croatia",
      chineseName: "克罗地亚",
      codeName: "HR",
      areaNumber: "385",
      position: '0 -2240px'
    },
    {
      englishName: "Cuba",
      chineseName: "古巴",
      codeName: "CU",
      areaNumber: "53",
      position: '0 -736px'
    },
    // {
    //   englishName: "Curacao",
    //   chineseName: "库拉索",
    //   codeName: "CW",
    //   areaNumber: "599",
    // },
    {
      englishName: "Cyprus",
      chineseName: "塞浦路斯",
      codeName: "CY",
      areaNumber: "357",
      position: '0 -864px'
    },
    {
      englishName: "Czech Republic",
      chineseName: "捷克",
      codeName: "CZ",
      areaNumber: "420",
      position: '0 -896px'
    },
  ],
  D: [
    {
      englishName: "Democratic Republic of the Congo",
      chineseName: "刚果民主共和国",
      codeName: "CD",
      areaNumber: "243",
      position: '0 -448px'
    },
    {
      englishName: "Denmark",
      chineseName: "丹麦",
      codeName: "DK",
      areaNumber: "45",
      position: '0 -992px'
    },
    {
      englishName: "Djibouti",
      chineseName: "吉布提",
      codeName: "DJ",
      areaNumber: "253",
      position: '0 -960px'
    },
    {
      englishName: "Dominica",
      chineseName: "多米尼加",
      codeName: "DM",
      areaNumber: "1767",
      position: '0 -1024px'
    },
    {
      englishName: "Dominican Republic",
      chineseName: "多米尼加共和国",
      codeName: "DO",
      areaNumber: "1809",
      position: '0 -1056px'
    },
  ],
  E: [
    {
      englishName: "Ecuador",
      chineseName: "厄瓜多尔",
      codeName: "EC",
      areaNumber: "593",
      position: '0 -1152px'
    },
    {
      englishName: "Egypt",
      chineseName: "埃及",
      codeName: "EG",
      areaNumber: "20",
      position: '0 -1216px'
    },
    {
      englishName: "El Salvador",
      chineseName: "萨尔瓦多",
      codeName: "SV",
      areaNumber: "503",
      position: '0 -5760px'
    },
    {
      englishName: "Equatorial Guinea",
      chineseName: "赤道几内亚",
      codeName: "GQ",
      areaNumber: "240",
      position: '0 -1952px'
    },
    {
      englishName: "Eritrea",
      chineseName: "厄立特里亚",
      codeName: "ER",
      areaNumber: "291",
      position: '0 -1280px'
    },
    {
      englishName: "Estonia",
      chineseName: "爱沙尼亚",
      codeName: "EE",
      areaNumber: "372",
      position: '0 -1184px'
    },
    {
      englishName: "Ethiopia",
      chineseName: "埃塞俄比亚",
      codeName: "ET",
      areaNumber: "251",
      position: '0 -1344px'
    },
  ],
  F: [
    {
      englishName: "Faroe Islands",
      chineseName: "法罗群岛",
      codeName: "FO",
      areaNumber: "298",
      position: '0 -1536px'
    },
    {
      englishName: "Fiji",
      chineseName: "斐济",
      codeName: "FJ",
      areaNumber: "679",
      position: '0 -1440px'
    },
    {
      englishName: "Finland",
      chineseName: "芬兰",
      codeName: "FI",
      areaNumber: "358",
      position: '0 -1408px'
    },
    {
      englishName: "France",
      chineseName: "法国",
      codeName: "FR",
      areaNumber: "33",
      position: '0 -1568px'
    },
    // {
    //   englishName: "French Guiana",
    //   chineseName: "法属圭亚那",
    //   codeName: "GF",
    //   areaNumber: "594",
    // },
    // {
    //   englishName: "French Polynesia",
    //   chineseName: "法属波利尼西亚",
    //   codeName: "PF",
    //   areaNumber: "689",
    // },
  ],
  G: [
    {
      englishName: "Gabon",
      chineseName: "加蓬",
      codeName: "GA",
      areaNumber: "241",
      position: '0 -1600px'
    },
    {
      englishName: "Gambia",
      chineseName: "冈比亚",
      codeName: "GM",
      areaNumber: "220",
      position: '0 -1856px'
    },
    {
      englishName: "Georgia",
      chineseName: "格鲁吉亚",
      codeName: "GE",
      areaNumber: "995",
      position: '0 -1696px'
    },
    {
      englishName: "Germany",
      chineseName: "德国",
      codeName: "DE",
      areaNumber: "49",
      position: '0 -928px'
    },
    {
      englishName: "Ghana",
      chineseName: "加纳",
      codeName: "GH",
      areaNumber: "233",
      position: '0 -1760px'
    },
    {
      englishName: "Gibraltar",
      chineseName: "直布罗陀",
      codeName: "GI",
      areaNumber: "350",
      position: '0 -1792px'
    },
    {
      englishName: "Greece",
      chineseName: "希腊",
      codeName: "GR",
      areaNumber: "30",
      position: '0 -1984px'
    },
    // {
    //   englishName: "Greenland",
    //   chineseName: "格陵兰岛",
    //   codeName: "GL",
    //   areaNumber: "299",
    // },
    {
      englishName: "Grenada",
      chineseName: "格林纳达",
      codeName: "GD",
      areaNumber: "1473",
      position: '0 -1664px'
    },
    // {
    //   englishName: "Guadeloupe",
    //   chineseName: "瓜德罗普岛",
    //   codeName: "GP",
    //   areaNumber: "590",
    // },
    // {
    //   englishName: "Guam",
    //   chineseName: "关岛",
    //   codeName: "GU",
    //   areaNumber: "1671",
    // },
    {
      englishName: "Guatemala",
      chineseName: "瓜地马拉",
      codeName: "GT",
      areaNumber: "502",
      position: '0 -2048px'
    },
    {
      englishName: "Guinea",
      chineseName: "几内亚",
      codeName: "GN",
      areaNumber: "224",
      position: '0 -1888px'
    },
    {
      englishName: "Guinea-Bissau",
      chineseName: "几内亚比绍共和国",
      codeName: "GW",
      areaNumber: "245",
      position: '0 -2112px'
    },
    {
      englishName: "Guyana",
      chineseName: "圭亚那",
      codeName: "GY",
      areaNumber: "592",
      position: '0 -2144px'
    },
  ],
  H: [
    {
      englishName: "Haiti",
      chineseName: "海地",
      codeName: "HT",
      areaNumber: "509",
      position: '0 -2272px'
    },
    {
      englishName: "Honduras",
      chineseName: "洪都拉斯",
      codeName: "HN",
      areaNumber: "504",
      position: '0 -2208px'
    },
    {
      englishName: "Hong Kong China",
      chineseName: "中国香港",
      codeName: "HK",
      areaNumber: "852",
      position: '0 -2176px'
    },
    {
      englishName: "Hungary",
      chineseName: "匈牙利",
      codeName: "HU",
      areaNumber: "36",
      position: '0 -2304px'
    },
  ],
  I: [
    {
      englishName: "Iceland",
      chineseName: "冰岛",
      codeName: "IS",
      areaNumber: "354",
      position: '0 -2592px'
    },
    {
      englishName: "India",
      chineseName: "印度",
      codeName: "IN",
      areaNumber: "91",
      position: '0 -2496px'
    },
    {
      englishName: "Indonesia",
      chineseName: "印度尼西亚",
      codeName: "ID",
      areaNumber: "62",
      position: '0 -2368px'
    },
    {
      englishName: "Iran",
      chineseName: "伊朗",
      codeName: "IR",
      areaNumber: "98",
      position: '0 -2560px'
    },
    {
      englishName: "Iraq",
      chineseName: "伊拉克",
      codeName: "IQ",
      areaNumber: "964",
      position: '0 -2528px'
    },
    {
      englishName: "Ireland",
      chineseName: "爱尔兰",
      codeName: "IE",
      areaNumber: "353",
      position: '0 -2400px'
    },
    {
      englishName: "Israel",
      chineseName: "以色列",
      codeName: "IL",
      areaNumber: "972",
      position: '0 -2432px'
    },
    {
      englishName: "Italy",
      chineseName: "意大利",
      codeName: "IT",
      areaNumber: "39",
      position: '0 -2624px'
    },
    // {
    //   englishName: "Ivory Coast",
    //   chineseName: "象牙海岸",
    //   codeName: "CI",
    //   areaNumber: "225",
    // },
  ],
  J: [
    {
      englishName: "Jamaica",
      chineseName: "牙买加",
      codeName: "JM",
      areaNumber: "1876",
      position: '0 -2688px'
    },
    {
      englishName: "Japan",
      chineseName: "日本",
      codeName: "JP",
      areaNumber: "81",
      position: '0 -2752px'
    },
    {
      englishName: "Jordan",
      chineseName: "约旦",
      codeName: "JO",
      areaNumber: "962",
      position: '0 -2720px'
    },
  ],
  K: [
    {
      englishName: "Kazakhstan",
      chineseName: "哈萨克斯坦",
      codeName: "KZ",
      areaNumber: "327",
      position: '0 -3104px'
    },
    {
      englishName: "Kenya",
      chineseName: "肯尼亚",
      codeName: "KE",
      areaNumber: "254",
      position: '0 -2784px'
    },
    {
      englishName: "Kiribati",
      chineseName: "基里巴斯",
      codeName: "KI",
      areaNumber: "686",
      position: '0 -2880px'
    },
    {
      englishName: "Kuwait",
      chineseName: "科威特",
      codeName: "KW",
      areaNumber: "965",
      position: '0 -3040px'
    },
    {
      englishName: "Kyrgyzstan",
      chineseName: "吉尔吉斯斯坦",
      codeName: "KG",
      areaNumber: "996",
      position: '0 -2816px'
    },
  ],
  L: [
    {
      englishName: "Laos",
      chineseName: "老挝",
      codeName: "LA",
      areaNumber: "856",
      position: '0 -3136px'
    },
    {
      englishName: "Latvia",
      chineseName: "拉脱维亚",
      codeName: "LV",
      areaNumber: "371",
      position: '0 -3424px'
    },
    {
      englishName: "Lebanon",
      chineseName: "黎巴嫩",
      codeName: "LB",
      areaNumber: "961",
      position: '0 -3168px'
    },
    {
      englishName: "Lesotho",
      chineseName: "莱索托",
      codeName: "LS",
      areaNumber: "266",
      position: '0 -3328px'
    },
    {
      englishName: "Liberia",
      chineseName: "利比里亚",
      codeName: "LR",
      areaNumber: "231",
      position: '0 -3296px'
    },
    {
      englishName: "Libya",
      chineseName: "利比亚",
      codeName: "LY",
      areaNumber: "218",
      position: '0 -3456px'
    },
    {
      englishName: "Liechtenstein",
      chineseName: "列支敦士登",
      codeName: "LI",
      areaNumber: "423",
      position: '0 -3232px'
    },
    {
      englishName: "Lithuania",
      chineseName: "立陶宛",
      codeName: "LT",
      areaNumber: "370",
      position: '0 -3360px'
    },
    {
      englishName: "Luxembourg",
      chineseName: "卢森堡",
      codeName: "LU",
      areaNumber: "352",
      position: '0 -3392px'
    },
  ],
  M: [
    {
      englishName: "Macao China",
      chineseName: "中国澳门",
      codeName: "MO",
      areaNumber: 853,
      position: '0 -3840px'
    },
    {
      englishName: "Macedonia",
      chineseName: "马其顿",
      codeName: "MK",
      areaNumber: 389,
      position: '0 -3712px'
    },
    {
      englishName: "Madagascar",
      chineseName: "马达加斯加",
      codeName: "MG",
      areaNumber: 261,
      position: '0 -3648px'
    },
    {
      englishName: "Malawi",
      chineseName: "马拉维",
      codeName: "MW",
      areaNumber: 265,
      position: '0 -4096px'
    },
    {
      englishName: "Malaysia",
      chineseName: "马来西亚",
      codeName: "MY",
      areaNumber: 60,
      position: '0 -4160px'
    },
    {
      englishName: "Maldives",
      chineseName: "马尔代夫",
      codeName: "MV",
      areaNumber: 960,
      position: '0 -4064px'
    },
    {
      englishName: "Mali",
      chineseName: "马里",
      codeName: "ML",
      areaNumber: 223,
      position: '0 -3744px'
    },
    {
      englishName: "Malta",
      chineseName: "马耳他",
      codeName: "MT",
      areaNumber: 356,
      position: '0 -4000px'
    },
    {
      englishName: "Martinique",
      chineseName: "马提尼克",
      codeName: "MQ",
      areaNumber: 596,
      position: '0 -3904px'
    },
    {
      englishName: "Mauritania",
      chineseName: "毛里塔尼亚",
      codeName: "MR",
      areaNumber: 222,
      position: '0 -3936px'
    },
    {
      englishName: "Mauritius",
      chineseName: "毛里求斯",
      codeName: "MU",
      areaNumber: 230,
      position: '0 -4032px'
    },
    {
      englishName: "Mayotte",
      chineseName: "马约特",
      codeName: "YT",
      areaNumber: '269',
      position: '0 -6848px'
    },
    {
      englishName: "Mexico",
      chineseName: "墨西哥",
      codeName: "MX",
      areaNumber: 52,
      position: '0 -4128px'
    },
    {
      englishName: "Moldova",
      chineseName: "摩尔多瓦",
      codeName: "MD",
      areaNumber: 373,
      position: '0 -3552px'
    },
    {
      englishName: "Monaco",
      chineseName: "摩纳哥",
      codeName: "MC",
      areaNumber: 377,
      position: '0 -3520px'
    },
    {
      englishName: "Mongolia",
      chineseName: "蒙古",
      codeName: "MN",
      areaNumber: 976,
      position: '0 -3808px'
    },
    {
      englishName: "Montenegro",
      chineseName: "黑山",
      codeName: "ME",
      areaNumber: 382,
      position: '0 -3584px'
    },
    {
      englishName: "Montserrat",
      chineseName: "蒙特塞拉特岛",
      codeName: "MS",
      areaNumber: 1664,
      position: '0 -3968px'
    },
    {
      englishName: "Morocco",
      chineseName: "摩洛哥",
      codeName: "MA",
      areaNumber: 212,
      position: '0 -3488px'
    },
    {
      englishName: "Mozambique",
      chineseName: "莫桑比克",
      codeName: "MZ",
      areaNumber: 258,
      position: '0 -4192px'
    },
    {
      englishName: "Myanmar",
      chineseName: "缅甸",
      codeName: "MM",
      areaNumber: 95,
      position: '0 -3776px'
    },
  ],
  N: [
    {
      englishName: "Namibia",
      chineseName: "纳米比亚",
      codeName: "NA",
      areaNumber: "264",
      position: '0 -4224px'
    },
    {
      englishName: "Nepal",
      chineseName: "尼泊尔",
      codeName: "NP",
      areaNumber: "977",
      position: '0 -4480px'
    },
    {
      englishName: "Netherlands",
      chineseName: "荷兰",
      codeName: "NL",
      areaNumber: "31",
      position: '0 -4416px'
    },
    {
      englishName: "New Caledonia",
      chineseName: "新喀里多尼亚",
      codeName: "NC",
      areaNumber: "687",
      position: '0 -4256px'
    },
    {
      englishName: "New Zealand",
      chineseName: "新西兰",
      codeName: "NZ",
      areaNumber: "64",
      position: '0 -4576px'
    },
    {
      englishName: "Nicaragua",
      chineseName: "尼加拉瓜",
      codeName: "NI",
      areaNumber: "505",
      position: '0 -4384px'
    },
    {
      englishName: "Niger",
      chineseName: "尼日尔",
      codeName: "NE",
      areaNumber: "227",
      position: '0 -4288px'
    },
    {
      englishName: "Nigeria",
      chineseName: "尼日利亚",
      codeName: "NG",
      areaNumber: "234",
      position: '0 -4352px'
    },
    {
      englishName: "Norway",
      chineseName: "挪威",
      codeName: "NO",
      areaNumber: "47",
      position: '0 -4448px'
    },
  ],
  O: [
    {
      englishName: "Oman",
      chineseName: "阿曼",
      codeName: "OM",
      areaNumber: "968",
      position: '0 -4608px'
    },
  ],
  P: [
    {
      englishName: "Pakistan",
      chineseName: "巴基斯坦",
      codeName: "PK",
      areaNumber: "92",
      position: '0 -4800px'
    },
    {
      englishName: "Palau",
      chineseName: "帕劳",
      codeName: "PW",
      areaNumber: "680",
      position: '0 -4992px'
    },
    {
      englishName: "Palestine",
      chineseName: "巴勒斯坦",
      codeName: "BL",
      areaNumber: "970",
      position: '0 -4928px'
    },
    {
      englishName: "Panama",
      chineseName: "巴拿马",
      codeName: "PA",
      areaNumber: "507",
      position: '0 -4640px'
    },
    {
      englishName: "Papua New Guinea",
      chineseName: "巴布亚新几内亚",
      codeName: "PG",
      areaNumber: "675",
      position: '0 -4736px'
    },
    {
      englishName: "Paraguay",
      chineseName: "巴拉圭",
      codeName: "PY",
      areaNumber: "595",
      position: '0 -5024px'
    },
    {
      englishName: "Peru",
      chineseName: "秘鲁",
      codeName: "PE",
      areaNumber: "51",
      position: '0 -4672px'
    },
    {
      englishName: "Philippines",
      chineseName: "菲律宾",
      codeName: "PH",
      areaNumber: "63",
      position: '0 -4768px'
    },
    {
      englishName: "Poland",
      chineseName: "波兰",
      codeName: "PL",
      areaNumber: "48",
      position: '0 -4832px'
    },
    {
      englishName: "Portugal",
      chineseName: "葡萄牙",
      codeName: "PT",
      areaNumber: "351",
      position: '0 -4960px'
    },
    {
      englishName: "Puerto Rico",
      chineseName: "波多黎各",
      codeName: "PR",
      areaNumber: "1787",
      position: '0 -4896px'
    },
  ],
  Q: [
    {
      englishName: "Qatar",
      chineseName: "卡塔尔",
      codeName: "QA",
      areaNumber: "974",
      position: '0 -5056px'
    },
  ],
  R: [
    {
      englishName: "Republic Of The Congo",
      chineseName: "刚果共和国",
      codeName: "CG",
      areaNumber: "242",
      position: '0 -448px'
    },
    // {
    //   englishName: "Réunion Island",
    //   chineseName: "留尼汪",
    //   codeName: "RE",
    //   areaNumber: "262",
    //   position: ''
    // },
    {
      englishName: "Romania",
      chineseName: "罗马尼亚",
      codeName: "RO",
      areaNumber: "40",
      position: '0 -5088px'
    },
    {
      englishName: "Russia",
      chineseName: "俄罗斯",
      codeName: "RU",
      areaNumber: "7",
      position: '0 -5152px'
    },
    {
      englishName: "Rwanda",
      chineseName: "卢旺达",
      codeName: "RW",
      areaNumber: "250",
      position: '0 -5184px'
    },
  ],
  S: [
    {
      englishName: "Saint Kitts and Nevis",
      chineseName: "圣基茨和尼维斯",
      codeName: "KN",
      areaNumber: "1869",
      position: '0 -2944px'
    },
    {
      englishName: "Saint Lucia",
      chineseName: "圣露西亚",
      codeName: "LC",
      areaNumber: "1758",
      position: '0 -3200px'
    },
    // {
    //   englishName: "Saint Pierre and Miquelon",
    //   chineseName: "圣彼埃尔和密克隆岛",
    //   codeName: "PM",
    //   areaNumber: "508",
    //   position: ''
    // },
    // {
    //   englishName: "Saint Vincent and The Grenadines",
    //   chineseName: "圣文森特和格林纳丁斯",
    //   codeName: "VC",
    //   areaNumber: "1784",
    // },
    {
      englishName: "Samoa",
      chineseName: "萨摩亚",
      codeName: "WS",
      areaNumber: "685",
      position: '0 -6784px'
    },
    {
      englishName: "San Marino",
      chineseName: "圣马力诺",
      codeName: "SM",
      areaNumber: "378",
      position: '0 -5568px'
    },
    {
      englishName: "Sao Tome and Principe",
      chineseName: "圣多美和普林西比",
      codeName: "ST",
      areaNumber: "239",
      position: '0 -5728px'
    },
    {
      englishName: "Saudi Arabia",
      chineseName: "沙特阿拉伯",
      codeName: "SA",
      areaNumber: "966",
      position: '0 -5216px'
    },
    {
      englishName: "Senegal",
      chineseName: "塞内加尔",
      codeName: "SN",
      areaNumber: "221",
      position: '0 -5600px'
    },
    {
      englishName: "Serbia",
      chineseName: "塞尔维亚",
      codeName: "RS",
      areaNumber: "381",
      position: '0 -5120px'
    },
    {
      englishName: "Seychelles",
      chineseName: "塞舌尔",
      codeName: "SC",
      areaNumber: "248",
      position: '0 -5280px'
    },
    {
      englishName: "Sierra Leone",
      chineseName: "塞拉利昂",
      codeName: "SL",
      areaNumber: "232",
      position: '0 -5536px'
    },
    {
      englishName: "Singapore",
      chineseName: "新加坡",
      codeName: "SG",
      areaNumber: "65",
      position: '0 -5376px'
    },
    // {
    //   englishName: "Saint Maarten (Dutch Part)",
    //   chineseName: "圣马丁岛（荷兰部分）",
    //   codeName: "SX",
    //   areaNumber: "1721",
    //   position: ''
    // },
    {
      englishName: "Slovakia",
      chineseName: "斯洛伐克",
      codeName: "SK",
      areaNumber: "421",
      position: '0 -5504px'
    },
    {
      englishName: "Slovenia",
      chineseName: "斯洛文尼亚",
      codeName: "SI",
      areaNumber: "386",
      position: '0 -5440px'
    },
    {
      englishName: "Solomon Islands",
      chineseName: "所罗门群岛",
      codeName: "SB",
      areaNumber: "677",
      position: '0 -5248px'
    },
    {
      englishName: "Somalia",
      chineseName: "索马里",
      codeName: "SO",
      areaNumber: "252",
      position: '0 -5632px'
    },
    {
      englishName: "South Africa",
      chineseName: "南非",
      codeName: "ZA",
      areaNumber: "27",
      position: '0 -6880px'
    },
    {
      englishName: "South Korea",
      chineseName: "韩国",
      codeName: "KR",
      areaNumber: "82",
      position: '0 -3008px'
    },
    {
      englishName: "Spain",
      chineseName: "西班牙",
      codeName: "ES",
      areaNumber: "34",
      position: '0 -1312px'
    },
    {
      englishName: "Sri Lanka",
      chineseName: "斯里兰卡",
      codeName: "LK",
      areaNumber: "94",
      position: '0 -3264px'
    },
    {
      englishName: "Sudan",
      chineseName: "苏丹",
      codeName: "SD",
      areaNumber: "249",
      position: '0 -5312px'
    },
    {
      englishName: "Suriname",
      chineseName: "苏里南",
      codeName: "SR",
      areaNumber: "597",
      position: '0 -5664px'
    },
    {
      englishName: "Swaziland",
      chineseName: "斯威士兰",
      codeName: "SZ",
      areaNumber: "268",
      position: '0 -5824px'
    },
    {
      englishName: "Sweden",
      chineseName: "瑞典",
      codeName: "SE",
      areaNumber: "46",
      position: '0 -5344px'
    },
    {
      englishName: "Switzerland",
      chineseName: "瑞士",
      codeName: "CH",
      areaNumber: "41",
      position: '0 -480px'
    },
    {
      englishName: "Syria",
      chineseName: "叙利亚",
      codeName: "SY",
      areaNumber: "963",
      position: '0 -5792px'
    },
  ],
  T: [
    {
      englishName: "Taiwan China",
      chineseName: "中国台湾",
      codeName: "TW",
      areaNumber: "886",
      position: '0 -6304px'
    },
    {
      englishName: "Tajikistan",
      chineseName: "塔吉克斯坦",
      codeName: "TJ",
      areaNumber: "992",
      position: '0 -6016px'
    },
    {
      englishName: "Tanzania",
      chineseName: "坦桑尼亚",
      codeName: "TZ",
      areaNumber: "255",
      position: '0 -6336px'
    },
    {
      englishName: "Thailand",
      chineseName: "泰国",
      codeName: "TH",
      areaNumber: "66",
      position: '0 -5984px'
    },
    {
      englishName: "Timor-Leste",
      chineseName: "东帝汶",
      codeName: "TL",
      areaNumber: "670",
      position: '0 -6080px'
    },
    {
      englishName: "Togo",
      chineseName: "多哥",
      codeName: "TG",
      areaNumber: "228",
      position: '0 -5952px'
    },
    {
      englishName: "Tonga",
      chineseName: "汤加",
      codeName: "TO",
      areaNumber: "676",
      position: '0 -6176px'
    },
    {
      englishName: "Trinidad and Tobago",
      chineseName: "特立尼达和多巴哥",
      codeName: "TT",
      areaNumber: "1868",
      position: '0 -6240px'
    },
    {
      englishName: "Tunisia",
      chineseName: "突尼斯",
      codeName: "TN",
      areaNumber: "216",
      position: '0 -6144px'
    },
    {
      englishName: "Turkey",
      chineseName: "土耳其",
      codeName: "TR",
      areaNumber: "90",
      position: '0 -6208px'
    },
    {
      englishName: "Turkmenistan",
      chineseName: "土库曼斯坦",
      codeName: "TM",
      areaNumber: "993",
      position: '0 -6112px'
    },
    {
      englishName: "Turks and Caicos Islands",
      chineseName: "特克斯和凯科斯群岛",
      codeName: "TC",
      areaNumber: "1649",
      position: '0 -5856px'
    },
  ],
  U: [
    {
      englishName: "Uganda",
      chineseName: "乌干达",
      codeName: "UG",
      areaNumber: "256",
      position: '0 -6400px'
    },
    {
      englishName: "Ukraine",
      chineseName: "乌克兰",
      codeName: "UA",
      areaNumber: "380",
      position: '0 -6368px'
    },
    {
      englishName: "United Arab Emirates",
      chineseName: "阿拉伯联合酋长国",
      codeName: "AE",
      areaNumber: "971",
      position: '0 -7616px'
    },
    {
      englishName: "United Kingdom",
      chineseName: "英国",
      codeName: "GB",
      areaNumber: "44",
      position: '0 -1632px'
    },
    {
      englishName: "United States",
      chineseName: "美国",
      codeName: "US",
      areaNumber: "1",
      position: '0 -6432px'
    },
    {
      englishName: "Uruguay",
      chineseName: "乌拉圭",
      codeName: "UY",
      areaNumber: "598",
      position: '0 -6464px'
    },
    {
      englishName: "Uzbekistan",
      chineseName: "乌兹别克斯坦",
      codeName: "UZ",
      areaNumber: "998",
      position: '0 -6496px'
    },
  ],
  V: [
    {
      englishName: "Vanuatu",
      chineseName: "瓦努阿图",
      codeName: "VU",
      areaNumber: "678",
      position: '0 -6720px'
    },
    {
      englishName: "Venezuela",
      chineseName: "委内瑞拉",
      codeName: "VE",
      areaNumber: "58",
      position: '0 -6592px'
    },
    {
      englishName: "Vietnam",
      chineseName: "越南",
      codeName: "VN",
      areaNumber: "84",
      position: '0 -6688px'
    },
    {
      englishName: "Virgin Islands, British",
      chineseName: "英属处女群岛",
      codeName: "VG",
      areaNumber: "1340",
      position: '0 -6624px'
    },
    // {
    //   englishName: "Virgin Islands, US",
    //   chineseName: "美属维尔京群岛",
    //   codeName: "VI",
    //   areaNumber: "1284",
    //   position: ''
    // },
  ],
  Y: [
    {
      englishName: "Yemen",
      chineseName: "也门",
      codeName: "YE",
      areaNumber: "967",
      position: '0 -6816px'
    },
  ],
  Z: [
    {
      englishName: "Zambia",
      chineseName: "赞比亚",
      codeName: "ZM",
      areaNumber: "260",
      position: '0 -6912px'
    },
    {
      englishName: "Zimbabwe",
      chineseName: "津巴布韦",
      codeName: "ZW",
      areaNumber: "263",
      position: '0 -6944px'
    },
  ],
}