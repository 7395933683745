<template>
    <div class="acount-login">
        <div class="content">
            <div class="login-content">
                <div class="header-item">
                    <div class="header-title">
                        <span v-translate>手机验证码登录</span>
                    </div>
                    <div class="header-close" @click="cancel" v-if="isShowClose">
                        <img src="@/assets/images/login/close.png" alt />
                    </div>
                </div>
                <div class="login-form">
                    <van-field
                        class="my-field my-phone"
                        input-align="right"
                        type="text"
                        size="large"
                        center
                        v-model="username"
                        name="手机号"
                        :placeholder="$t('phone')"
                    >
                        <template #label>
                            <div
                                class="national-btn"
                                @click="
                                    () => {
                                        isNationnalShow = true
                                    }
                                "
                            >
                                + {{ areaNumber }}
                                <span
                                    class="f32"
                                    :style="{ 'background-position': codePosition }"
                                ></span>
                                <img src="@/assets/images/login/next.png" alt class="login-next" />
                            </div>
                        </template>
                    </van-field>
                    <div class="line"></div>
                    <van-field
                        class="my-field"
                        input-align="right"
                        v-model="sms"
                        center
                        size="large"
                        maxlength="4"
                        :placeholder="$t('code')"
                    >
                        <template #button>
                            <van-button
                                size="small"
                                type="info"
                                @click="handleSend"
                                :class="{ isSms: isSms }"
                                >{{ smsText }}</van-button
                            >
                        </template>
                    </van-field>
                    <div class="line"></div>
                </div>
                <agreement v-model="isChecked" :agreementId="agreementId"></agreement>
                <div class="login-error-tip">{{ errorText }}</div>
                <van-button
                    type="info"
                    class="login-button"
                    @click="handleLogin"
                    :disabled="username && sms && !isChecked"
                >
                    <span v-translate>登录</span>
                </van-button>
                <div class="login-type">
                    <div class="type-name" @click="handleLoginType">
                        <span v-translate>账号登录</span>
                    </div>
                    <div
                        class="type-language"
                        @click="changeLanguage('zh')"
                        v-if="$i18n.locale == 'en'"
                    >
                        中文
                    </div>
                    <div class="type-language" @click="changeLanguage('en')" v-else>English</div>
                </div>
            </div>
        </div>
        <van-actionSheet
            v-model="isNationnalShow"
            :round="false"
            title="选择所属国家或地区"
            :closeable="false"
            :style="{ width: contentWidth, left: contentLeft }"
        >
            <nationnal-list @handleSelect="handleSelect"></nationnal-list>
        </van-actionSheet>
        <div :class="[$i18n.locale == 'zh' ? 'Login-tip' : 'en-Login-tip']">
            *
            <span v-translate
                >若您未注册，系统将为您自动注册，密码会以短信形式发送到您的手机上。</span
            >
        </div>
        <drag-verify-img-chip
            v-if="ischeckImg"
            @onSuccess="onSuccess"
            @closeOverlay="ischeckImg = false"
        ></drag-verify-img-chip>
        <van-dialog
            v-model="loginTimeDialog"
            show-cancel-button
            :cancelButtonText="config.password_past == 1 ? $ct('取消') : '继续登录'"
            :confirmButtonText="$ct('更改密码')"
            confirmButtonColor="#0089FF"
            cancelButtonColor="#CECECE"
            @cancel="beforeLoginTimeCancel"
            @confirm="beforeLoginTimeConfirm"
        >
            <div class="dialog_content">
                <div class="dialog-title">安全提示</div>
                <div class="dialog_tip">
                    <span v-if="config.password_past == 2" v-translate style="color: #888888"
                        >您的账户密码已经超过{{
                            config.password_period
                        }}天未更换为保障安全，建议您及时更改密码</span
                    >
                    <span v-if="config.password_past == 1" v-translate style="color: #888888"
                        >您的账户密码已经超过{{
                            config.password_period
                        }}天未更换为保障账户安全须更换新密码后才可登录，点击按钮进行更改</span
                    >
                </div>
            </div>
        </van-dialog>
    </div>
</template>
<script>
// import { ActionSheet, Form, Field, Button } from 'vant'
import Agreement from './Agreement'
import nationnalList from './NationnalList'
import { mapState, mapGetters } from '@utils/vuex'
import DragVerifyImgChip from '@components/Common/DragVerifyImgChip'
import Request from '@request'
import api from '@api'
export default {
    name: 'Login',
    data() {
        return {
            errorText: '',
            username: '',
            sms: '',
            isChecked: null,
            agreementId: 6,
            codeName: 'cn',
            areaNumber: '86',
            codePosition: '0 -640px',
            isNationnalShow: false,
            smsText: this.$i18n.locale == 'zh' ? '获取验证码' : 'Send code',
            timer: null,
            isSms: false,
            ischeckImg: false,
            config: {},
            loginTimeDialog: false
        }
    },
    props: {
        loginFlag: {
            type: Boolean,
            default: false
        },
        signatures: {},
        isShowClose: {
            type: Boolean,
            default: true
        }
    },
    watch: {
        loginFlag(val) {
            this.loginFlag = val
        }
    },
    computed: { ...mapGetters(['contentWidth', 'contentLeft']) },
    components: {
        // ActionSheet,
        Agreement,
        // Field,
        // Form,
        // Button,
        DragVerifyImgChip,
        nationnalList
    },
    created() {
        this.getinsuranceconfig()
    },
    methods: {
        beforeLoginTimeCancel() {
            this.loginTimeDialog = false
            if (this.config.password_past == 2) {
                var params = {
                    phone: this.username,
                    country_code: this.areaNumber,
                    code: this.sms,
                    openid: localStorage.getItem('wxopenid'),
                    unionid: localStorage.getItem('unionid')
                }
                this.$store
                    .dispatch('user/loginCode', params)
                    .then(res => {
                        this.$listeners.handleClose()
                    })
                    .catch(err => {
                        this.errorText = err.message
                    })
            }
        },
        beforeLoginTimeConfirm() {
            this.loginTimeDialog = false
            this.$router.push({
                path: '/changePassword',
                query: {
                    phone: this.username
                }
            })
        },
        getinsuranceconfig() {
            Request.post(api.common.getinsuranceconfig, {
                type: 2
            }).then(response => {
                this.config = response.data
            })
        },
        onSuccess() {
            this.ischeckImg = false
            if (this.config.verification_code == 1) {
                this.sendCode()
            }
        },
        changeLanguage(name) {
            if (name == 'en') {
                this.smsText = 'Send code'
            } else {
                this.smsText = '发送验证码'
            }
            this.$i18n.locale = name
        },
        sendCode() {
            if (!this.timer) {
                this.$store
                    .dispatch('sendCode', {
                        phone: this.username,
                        country_code: this.areaNumber,
                        sms_signatures: this.signatures
                    })
                    .then(res => {
                        let count = 60
                        this.timer = setInterval(() => {
                            if (count > 0 && count <= 60) {
                                count--
                                this.smsText =
                                    this.$i18n.locale == 'zh'
                                        ? `重新获取(${count}s)`
                                        : `${count}s Replace Send`
                                this.isSms = true
                            } else {
                                this.smsText =
                                    this.$i18n.locale == 'zh' ? `获取验证码` : 'Send code'
                                clearInterval(this.timer)
                                this.timer = null
                                this.isSms = false
                            }
                        }, 1000)
                    })
                    .catch(_ => {
                        this.isSms = false
                    })
            }
        },
        handleSend() {
            if (this.isSms) return
            if (this.areaNumber == '86') {
                var reg_tel =
                    /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
            } else {
                var reg_tel = /^[0-9]*$/
            }
            if (!reg_tel.test(this.username)) {
                if (this.$i18n.locale == 'zh') {
                    this.$toast('请输入正确的手机号')
                    this.smsText = '发送验证码'
                } else {
                    this.$toast('phone error')
                    this.smsText = 'Send code'
                }
                return false
            }
            if (this.config.verification_code == 1) {
                this.ischeckImg = true
            } else {
                this.sendCode()
            }
        },
        async handleLogin() {
            let param = {
                phone: this.username,
                country_code: this.areaNumber,
                code: this.sms,
                openid: localStorage.getItem('wxopenid'),
                unionid: localStorage.getItem('unionid')
            }
            let phoneReg = /^\d+$/
            let smsReg = /^[\d]{4}$/
            if (!this.isChecked) {
                this.$toast('请勾选医谱服务协议')
                return false
            }
            if (!phoneReg.test(this.username)) {
                if (this.$i18n.locale == 'zh') {
                    this.$toast('请输入正确的手机号')
                    this.smsText = '发送验证码'
                } else {
                    this.$toast('phone error')
                    this.smsText = 'Send code'
                }
                return false
            }
            if (!smsReg.test(this.sms)) {
                if (this.$i18n.locale == 'zh') {
                    this.$toast('验证码长度错误')
                } else {
                    this.$toast('code error')
                }
                return false
            }
            let _this = this
            Request.post(
                api.common.initialpastpassword,
                {
                    username: _this.username,
                    code: _this.sms,
                    type: 2,
                    login_type: 1
                },
                false
            )
                .then(res => {
                    _this.$store
                        .dispatch('user/loginCode', param)
                        .then(res => {
                            _this.$listeners.handleClose()
                        })
                        .catch(err => {
                            console.log(err)
                            _this.errorText = err.message
                        })
                })
                .catch(err => {
                    if (err.status == 5112) {
                        this.loginTimeDialog = true
                    } else if (err.status == 5113) {
                        this.loginDialog = true
                    } else {
                        this.$toast(err.message)
                    }
                })
        },
        cancel() {
            this.$listeners.handleClose()
        },
        handleLoginType() {
            this.$emit('handleLoginType', 1)
            this.errorText = ''
        },
        handleSelect(data) {
            this.changeNation(data)
            this.isNationnalShow = false
        },
        changeNation(res) {
            this.codeName = res.codeName.toLowerCase()
            this.areaNumber = res.areaNumber
            this.codePosition = res.position
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@styles/variables.scss';
::v-deep {
    .van-dialog {
        border-radius: 0.17rem;
    }
}
.login-error-tip {
    height: 2rem;
    display: flex;
    align-items: center;
    margin: 0.2rem 0;
    font-size: 0.4rem;
    color: red;
}
.acount-login {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.content {
    min-height: 15rem;
}
.my-phone {
    ::v-deep .van-field__label {
        width: auto !important;
    }
}
.login-form ::v-deep {
    .my-field {
        background: transparent;
    }
    .van-cell::after {
        border: none;
    }
}
.my-field ::v-deep {
    .van-field__control {
        height: 1.2rem;
        font-size: $fslg;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
    }
}
.isSms {
    background: rgba(0, 137, 255, 0.2);
    border-radius: 2px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #0089ff;
    border: none;
}

.login-content {
    flex: 1;
    width: 100%;
    padding: 1.18rem 1.33rem;
    box-sizing: border-box;
    background: url(/img/login_cricle.ad20ba9b.png) right/cover no-repeat;
    background-size: 100%;
    background-position-x: 0px;
    background-position-y: 0px;
    margin-bottom: 1rem;
}
.header-item {
    display: flex;
    justify-content: space-between;
}
.header-title {
    font-size: $fsxl;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #222222;
}
.header-close img {
    width: 1.1rem;
    height: 1.1rem;
    vertical-align: middle;
}
.login-form {
    margin-top: 1rem;
}

.my-field {
    padding-right: 0;
    padding-left: 0;
}
.line {
    height: 1px;
    background: #cecece;
}
.f32 {
    background: url('../../assets/images/login/sprite_gj.png') no-repeat top left;
    width: 32px;
    height: 32px;
    display: inline-block;
    // background-position: 0 0;
}
.national-btn {
    display: flex;
    align-items: center;
    font-size: $fslg;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #222222;
}
.national-btn span {
    margin-left: 2px;
    // width: 1.06rem;
    // height: 100%;
}
.national-btn img {
    margin-left: 2px;
    width: 0.47rem;
    height: 0.71rem;
}
.login-next {
    width: 0.44rem;
    height: 0.44rem;
}
.login-form >>> .van-cell::after {
    content: '';
    display: none !important;
}
.login-button {
    margin-top: 1.65rem;
    width: 100%;
}
.login-type {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.48rem;
    font-size: $fsmd;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #888888;
}
.Login-tip {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1.8rem;
    margin-top: 0.88rem;
    padding: 0.3rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(254, 166, 2, 0.1);
    font-size: $fsxs;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #fea602;
}
.en-Login-tip {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2.4rem;
    margin-top: 0.88rem;
    padding: 0.3rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(254, 166, 2, 0.1);
    font-size: $fsxs;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #fea602;
}
@media screen and (min-width: 750px) {
    .my-field ::v-deep {
        .van-field__control {
            height: 1.2rem;
            font-size: $fslg-pc;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
        }
    }
}
.dialog_content {
    padding: 0.6rem;
    box-sizing: border-box;
}
.dialog-title {
    font-size: 16px;
    text-align: center;
    margin-bottom: 0.4rem;
}
.dialog_tip {
    color: red;
    font-size: 0.55rem;
    font-family: PingFang SC;
    font-weight: 400;
}

.dialog_tel {
    margin-top: 0.1rem;
    display: flex;
    font-size: 0.45rem;
    font-family: PingFang SC;
}
</style>
