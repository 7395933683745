<template>
  <div>
    <div class="meet_list" @click="$emit('item-click', item)">
      <div class="meet_item">
        <div class="meet_item_left">
          <div :class="['meet_item_title', { single: item.speaker }]">
            <span class="meet_item_title_text van-ellipsis">
              <span class="meet_flag review" v-if="item.meeting_type == '预告'">预告</span>
              <span class="meet_flag" v-if="item.meeting_type == '进行中'">进行中</span>
              <span class="meet_flag" v-if="item.meeting_type == '直播中'">直播中</span>
              <span class="meet_flag review" v-if="item.meeting_type == '回顾'">回顾</span>
              {{ item.parent_title ? item.parent_title : item.title }}
            </span>
          </div>
          <div
            class="meet_item_branch_title"
            v-if="item.speaker || item.parent_title"
            :style="{ lineHeight: line_height }"
          >
            <span>{{ item.speaker ? item.speaker : item.title }}</span>
          </div>
          <div class="meet-item_time">{{ item.begin_time + '-' + item.end_time }}</div>
        </div>
        <div class="meet_item_right">
          <!-- + '?x-oss-process=image/resize,w_200' -->
          <van-image
            v-resize="200"
            class="meet_item_top_right_img"
            alt="封面图片"
            lazy-load
            :src="item.cover"
          />
          <div class="credit-tip" v-if="isCredit && item.is_credit_meeting == 1">
            <img src="@images/home/credit_tip.svg" alt />
          </div>
          <!-- <img class="meet_item_top_right_img" v-bind:src="item.cover" alt="封面图片" width="100%" height="100%"> -->
          <div class="meet_item_top_log" v-if="item.view_display_status == 2">
            <img src="@images/home/tipVideo.png" alt />
            <span>{{ item.view_num | NumFilter }}</span>
          </div>

          <!-- <img data-v-7308cbb4="" src="/img/imageShadow.c29b9300.png" alt="背景" class="meet_item_imageShadow"> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import os from '@utils/os'
// import { Image } from 'vant'
export default {
    filters: {
        NumFilter(val) {
            if (Number(val) > 10000) {
                return (val / 10000).toFixed(1) + '万'
            } else {
                return val
            }
        }
    },
    name: 'MeetingItem',
    components: {
        // 'van-image': Image
    },
    props: {
        item: {
            type: Object,
            required: true,
            default: {}
        },
        isCredit: {}
    },
    computed: {
        line_height() {
            return os.isPC() ? '0.48rem' : '0.68rem'
        }
    },
    data() {
        return {
            flagText: '直播中'
        }
    },
    mounted() {},
    methods: {
        replaceLine(str) {
            if (!str) return ''
            str = str.replace(/\r\n/g, '&nbsp')
            str = str.replace(/\n/g, '&nbsp')
            str = str.replace(/\s/g, '&nbsp')
            str = str.replace(/\t/g, '&nbsp')
            return str
        },
        flagtype() {
            switch (parseInt(this.item.type)) {
                case 1:
                    return '直播'
                    break
                case 2:
                    return '交互'
                    break
                case 3:
                    return '直播+交互'
                    break
                default:
                    return '直播'
                    break
            }
        },
        goMeetingDetail() {}
    }
}
</script>
<style scoped lang="scss">
@import '@styles/variables.scss';

.meet_list:active {
    background: #efefef;
}

.meet_list {
    display: flex;
    min-height: 3.97rem;
    width: 100%;
    padding: 0.62rem 0rem;
    flex-direction: column;
    background-color: #fff;
    border-bottom: 1px solid #ececec;
    cursor: pointer;
}

.meet_item {
    display: flex;
    flex: 1;
    width: 100%;
    height: 3.5rem;

    flex-direction: row;
    justify-content: space-between;
}

.meet_item_left {
    width: 9.09rem;
    height: 100%;
    // margin-right: 0.38rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.meet_item_right ::v-deep {
    .van-image__img {
        border-radius: 4px;
    }
}
.meet_item_right {
    width: 5.46rem;
    height: 3.21rem;
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 0.17rem 0 0.38rem;
    box-sizing: border-box;
    position: relative;
}

.meet_item_title {
    flex-direction: row;
    display: flex;
    align-items: flex-start;
    height: 1.96rem;
}

.meet-item_time {
    word-break: keep-all;
    /*不允许换行*/
    overflow: hidden;
    text-overflow: ellipsis;
    /*超出显示...*/
    white-space: nowrap;
    /* 文本不会换行，文本会在在同一行上继续，直到遇到 <br> 标签为止。*/
    font-size: $fssm;
    line-height: 1.02rem;
    letter-spacing: 0rem;
    color: #888888;
}

.meet_item_flag {
    color: #ffffff;
    font-size: 0.58rem;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.02rem;
    letter-spacing: 0rem;
    background-color: #6194fe;
    border-radius: 0.1rem;
    padding: 0rem 0.2rem;
    text-align: center;
}

.single {
    display: block;
    -webkit-line-clamp: 1 !important;
    height: 1.26rem !important;
    width: 95%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1.26rem;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
}

.meet_item_top_right_img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    border: solid 0.02rem #f8f8f8;
    position: relative;
    // z-index: 1;
    box-shadow: 0px 5px 5px rgba(206, 206, 206, 0.6);
}

.meet_item_branch_title {
    flex-direction: row;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    line-height: 0.68rem;
    height: 1.38rem;
    color: #222222;
    font-size: 0.68rem;
}

.meet_item_imageShadow {
    width: 100%;
    position: absolute;
    top: 0.1rem;
    // z-index: 0;
    display: block;
    right: 0;
}

.meet_flag {
    padding: 0.08rem;
    box-sizing: border-box;
    white-space: nowrap;
    font-size: $fssm !important;
    background: #0089ff;
    font-weight: 400;
    color: #ffffff;
    border-radius: 0.09rem;
}

.review {
    background: rgba(0, 137, 255, 0.1);
    color: #0089ff;
}
.credit-tip {
    position: absolute;
    top: -0.09rem;
    left: -0.04rem;
    width: 1.32rem;
    height: 0.6rem;
    img {
        width: 100%;
    }
}

.meet_item_top_log {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 0.3rem;
    width: 100%;
    height: 1.1rem;
    line-height: 1.1rem;
    position: absolute;
    bottom: 0rem;
    left: 0rem;
    // z-index: 2;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
    border-radius: 0px 0px 4px 4px;

    span {
        font-size: $fssm;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        margin-left: 0.2rem;
    }

    img {
        width: 0.63rem;
        height: 0.52rem;
        vertical-align: middle;
    }
}

.meet_item_top_log_liveimg {
    width: 2.58rem;
    height: 0.81rem;
}

.meet_item_top_log_img {
    width: 1.5rem;
    height: 0.81rem;
}

.meet_item_title_text {
    // margin-left: 0.13rem;
    font-size: $fsxl;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.02rem;
    letter-spacing: 0rem;
    color: #222222;
    word-break: break-all;
    width: 95%;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap;
    // -webkit-box-orient: vertical;
}
</style>