import VueI18n from "vue-i18n"
// import Vue from 'vue'
import zh from '@utils/language/zh.js'
import en from '@utils/language/en.js'

// Vue.use(VueI18n)

function getTranslateMapping(object, parent) {
    parent = parent ? parent + '.' : ''
    return Object.keys(object).reduce((map, key) => {
        const zh = object[key]
        if (Object.prototype.toString.call(zh) === '[object Object]') map = {
            ...map,
            ...getTranslateMapping(zh, key)
        }
        else map[zh] = parent + key
        return map
    }, {})
}

const i18n = new VueI18n({
    locale: 'zh', // 语言标识
    messages: {
        en,
        zh
    }
})

const i18nMapping = {
    ...getTranslateMapping(i18n.messages.zh),
    ...getTranslateMapping(i18n.messages.en)
}

Vue.directive('translate', function (el, bind, vnode) {
    let key = i18nMapping[el.innerHTML] || i18nMapping[el.innerHTML.trim()]

    if (el.tagName === 'INPUT' || el.tagName === 'TEXTAREA') {
        const placeholder = el.getAttribute('placeholder')
        key = i18nMapping[placeholder]
        el.setAttribute('placeholder', (key ? vnode.context.$t(key) : placeholder))
    } else {
        el.innerHTML = key ? vnode.context.$t(key) : el.innerHTML
    }
})

Vue.prototype.$ct = function (zh) {
    const key = i18nMapping[zh]
    if (!key) return zh
    return this.$t(key)
}

export default i18n