export default [{
    path: '/partHomepage',
    name: 'partHomepage',
    meta: {
        title: "部位主页",
    },
    component: () => import("@/views/partHomepage"),
}, {
    path: '/partList',
    name: 'partList',
    meta: {
        title: "部位列表",
    },
    component: () => import("@/views/partList"),
}]