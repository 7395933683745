<template>
  <div ref="lottieAnimation" class="lottie-animation"></div>
</template>
<script>
  import Lottie from 'lottie-web'

  export default {
    components: {

    },
    props: {
      options: {
        type: Object,
        require: false,
        default: _ => {}
      },
      value: {
        type: Object,
        require: false,
        default: _ => {}
      },
    },
    data() {
      return {
        lottie: null
      }
    },
    mounted() {
      this.lottie = Lottie.loadAnimation({
        container: this.$refs.lottieAnimation,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        ...this.options
      })

      this.$emit('input', this.lottie)

      Object.keys(this.$listeners).forEach(key => {
        if (key !== 'input') this.lottie.addEventListener(key, this.$listeners[key])
      })
    },
    destroyed() {
      Object.keys(this.$listeners).forEach(key => {
        if (key !== 'input') this.lottie.removeEventListener(key, this.$listeners[key])
      })
      this.lottie.destroy()
    }
  }
</script>
<style scoped>

</style>