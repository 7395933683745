import axios from 'axios'
import host from './host'
import store from '@/store'
import md5 from 'js-md5'
import api from './api'
import os from '@utils/os'
import vm from '@/main'
import Type from '@utils/type'
import { doEncrypt } from '@utils/crypto'

const encryptKey = 'encryptData'

const service = axios.create({
  baseURL: '',
  timeout: 10000,
})

service.interceptors.request.use(
  config => {
    const {
      method,
      gatConfig
    } = config
    const { token, refresh_token, sys_token } = store.state.user.tokenInfo
    const headers = {
      ...config.headers,
      TOKEN: token,
      'REFRSH-TOKEN': refresh_token,
      'ACCPET-ENCODING': sys_token,
      'accept': 'application/json, text/plain, */*'
    }
    if (gatConfig.encrypt) headers['X-Encrypt'] = encryptKey

    if (method === 'post' || method === 'put') {
      const timestamp = new Date() * 1
      let platform = 8
      const sign = md5('platform=' + platform + '&timestamp=' + timestamp)
      if (store.getters.isInApp && !os.isPC()) {
        if (os.isIOS()) {
          platform = 10
        } else if (os.isAndroid()) {
          platform = 12
        }
      }
      const sysusersId = store.getters.userID
      // localStorage.getItem('userid')

      let data = {
        platform,
        sign,
        timestamp,
        userid: sysusersId,
        userId: sysusersId,
        sysusersId,
        ...config.data,
      }

      if (gatConfig.encrypt) {
        data = {
          [encryptKey]: doEncrypt(data)
        }
      }

      config = {
        ...config,
        headers,
        data,
      }
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  (response) => {
    const res = response.data
    const { gatConfig } = response.config
    if (res.code === 'FAILED') {
      if (res.msg && gatConfig.showTips) vant.Toast.fail(vm.$ct(res.msg))
    } else {
      return res
    }
  },
  (error) => {
    console.log('err' + error) // for debug

    if (os.isIOS() && error.msg !== 'Network Error') {
      vant.Toast.fail(error.msg)
    }
    return Promise.reject(error)
  }
)

// config option keys
// showTips
// encrypt
function handleConfig(config) {
  return Type.isObject(config) ? {
    showTips: true,
    ...config
  } : {
    showTips: config
  }
}


class Request {
  static post(url, params, showTips = true) {
    url = url.indexOf('https') != -1 ? url : host.javaDoMain + url
    return service({
      url,
      method: 'post',
      data: params,
      gatConfig: handleConfig(showTips)
    })
  }

  static get(url, params, showTips = true) {
    url = url.indexOf('https') != -1 ? url : host.javaDoMain + url
    return service({
      url,
      method: 'get',
      params,
      gatConfig: handleConfig(showTips)
    })
  }
  static put(url, params, showTips = true) {
    url = url.indexOf('https') != -1 ? url : host.javaDoMain + url
    return service({
      url,
      method: 'put',
      data: params,
      gatConfig: handleConfig(showTips)
    })
  }
  static delete(url, params, showTips = true) {
    url = url.indexOf('https') != -1 ? url : host.javaDoMain + url
    return service({
      url,
      method: 'delete',
      data: params,
      gatConfig: handleConfig(showTips)
    })
  }
}

export default Request
