

export default [
    {
      path: '/shareRegister',
      name: 'shareRegister',
      meta: {
          title: '注册'
      },
      component: () => import('@/views/shareRegister')
    },
    {
        path: '/shareDeployRegister',
        name: 'shareDeployRegister',
        meta: {
            title: '注册'
        },
        component: () => import('@/views/shareRegister/shareDeployRegister')
    }
]