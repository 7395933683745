import Request from "@request"
import api from "@api"

export default {
    balanceList({
        dispatch,
        commit,
        state,
        rootState
    }, params) {
        return new Promise((resolve, reject) => {
            Request.post(api.withdrawal.balanceList, params).then((res) => {
                resolve(res);
            })
        })
    },
    balanceDetail({
        dispatch,
        commit,
        state,
        rootState
    }, params) {
        return new Promise((resolve, reject) => {
            Request.post(api.withdrawal.balanceDetail, params).then(res => {
                resolve(res)
            }).catch(() => {
                reject()
            })
        })
    },
}