// 0 教学预告 1 大会专栏 2 最新咨询 3 精彩回放
// const getTabKey = function (index) {
//     let key
//     switch (index) {
//         case 0:
//             key = 'Advance'
//             break
//         case 1:
//             key = 'Column'
//             break
//         case 2:
//             key = 'Consulting'
//             break
//         case 3:
//             key = 'Playback'
//             break
//     }
//     return key
// }

export default {
    // current_tab_data(state) {
    //     return state[`${getTabKey(state.current_selected_tab).toLowerCase()}_data`]
    // },
    // current_tab_hotword(state) {
    //     return state[`${getTabKey(state.current_selected_tab).toLowerCase()}_hotword`]
    // }
}