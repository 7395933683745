import home from '@/views/home'
export default [
    {
        path: '/',
        name: 'home',
        meta: {
            title: '远程学院',
            keepAlive: true,
        },
        component: home,
    },
    {
        path: '/homeConsultingDetail',
        name: 'homeConsultingDetail',
        meta: {
            title: '资讯详情',
        },
        component: () => import('@/views/home/homeConsultingDetail'),
    },
    {
        path: '/search',
        name: 'search',
        meta: {
            title: '搜索',
        },
        component: () => import('@/views/search'),
    },
    {
        path: '/overSelectCollege',
        name: 'overSelectCollege',
        meta: {
            title: '关注学科',
        },
        component: () => import('@/views/home/overSelectCollege'),
    },
    {
        path: '/expertList',
        name: 'expertList',
        meta: {
            title: '关注学科',
        },
        component: () => import('@/views/home/expertList'),
    },
    // expertSearch
    {
        path: '/expertSearch',
        name: 'expertSearch',
        meta: {
            title: '搜索',
        },
        component: () => import('@/views/search/expertSearch'),
    },
    {
        path: '/selectedCollege',
        name: 'selectedCollege',
        meta: {
            title: '选择学科',
        },
        component: () => import('@/views/home/selectedCollege'),
    },

    {
        path: '/channelClass',
        name: 'channelClass',
        meta: {
            title: '详情',
        },
        component: () => import('@/views/home/channelClass'),
    },
    {
        path: '/encyclopediasHome',
        name: 'encyclopediasHome',
        meta: {
            title: '药械百科',
        },
        component: () => import('@/views/encyclopediasHome'),
    },
    {
        path: '/companyDetail',
        name: 'companyDetail',
        meta: {
            title: '药企详情',
        },
        component: () => import('@/views/companyDetail'),
    },
    {
        path: '/productDetail',
        name: 'productDetail',
        meta: {
            title: '产品详情',
        },
        component: () => import('@/views/productDetail'),
    },
    {
        path: '/productCatalog',
        name: 'productCatalog',
        meta: {
            title: '产品目录',
        },
        component: () => import('@/views/productCatalog'),
    },
    {
        path: '/medicalEquipmentClassification',
        name: 'medicalEquipmentClassification',
        meta: {
            title: '药械百科分类',
        },
        component: () => import('@/views/medicalEquipmentClassification'),
    },
    {
        path: '/productList',
        name: 'productList',
        meta: {
            title: '产品列表',
        },
        component: () => import('@/views/productList'),
    },
    {
        path: '/departmentHomePage',
        name: 'departmentHomePage',
        meta: {
            title: '科室主页',
        },
        component: () => import('@/views/departmentHomePage/index'),
    },
    {
        path: '/departmentHomeSearch',
        name: 'departmentHomeSearch',
        meta: {
            title: '科室搜索',
        },
        component: () => import('@/views/departmentHomeSearch/index'),
    },
    {
        path: '/departmentDetail',
        name: 'departmentDetail',
        meta: {
            title: '科室详情页',
        },
        component: () => import('@/views/departmentDetail'),
    },
    {
        path: '/doctorList',
        name: 'doctorList',
        meta: {
            title: '认证医生',
        },
        component: () => import('@/views/doctorList/index'),
    },
    {
        path: '/briefIntroduction',
        name: 'briefIntroduction',
        meta: {
            title: '简介',
        },
        component: () => import('@/views/briefIntroduction/index'),
    },
    {
        path: '/recentMeetings',
        name: 'recentMeetings',
        meta: {
            title: '近期会议',
        },
        component: () => import('@/views/recentMeetings/index'),
    },
    {
        path: '/followContent',
        name: 'followContent',
        meta: {
            title: '关注医生内容更新',
        },
        component: () => import('@/views/followContent/index'),
    },
    {
        path: '/singleSubject',
        name: 'singleSubject',
        meta: {
            title: '远程学院',
        },
        component: () => import('@/views/home/singleSubject'),
    }
]
