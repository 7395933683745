import Request from '@request'
import api from '@api'
import utils from '@utils'

export default {
    open({
        dispatch,
        commit,
        state,
        rootState
    }, params) {
        const {url, content} = params
        
        if (state.instance && state.instance.location.href) {
            alert('当前已经打开一个编辑窗口，请关闭后重新打开新窗口')
            return
        }
        else {
            commit('setInstance',window.open(url))
            commit('setContent', content) 
        }
    },
    handleActions({
        dispatch,
        commit,
        state,
        rootState
    }, params) {
        const {name, data} = params
        console.log(name)
        return new Promise((resolve, reject)=>{
            if (name === 'getContent') {
                resolve(state.content) 
            } else if (name === 'submit') {
                commit('setContent', data.content) 
                resolve() 
            }
        }) 
        
    }
}