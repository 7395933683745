export default [{
    path: '/expertTaskList',
    name: 'expertTaskList',
    meta: {
        title: '专家任务单'
    },
    component: () => import('@/views/expertTask/expertTaskList')
},
{
    path: '/specimenChamber',
    name: 'specimenChamber',
    meta: {
        title: '试片室'
    },
    component: () => import('@/views/expertTask/specimenChamber')
},
{
    path: '/specimenChamberLogin',
    name: 'specimenChamberLogin',
    meta: {
        title: '试片室-登录'
    },
    component: () => import('@/views/expertTask/specimenChamberLogin')
},
{
    path: '/expertsSchedule',
    name: 'expertsSchedule',
    meta: {
        title: '专家日程'
    },
    component: () => import('@/views/expertTask/expertsSchedule')
},
{
    path: '/expertIntroduction',
    name: 'expertIntroduction',
    meta: {
        title: '专家简介'
    },
    component: () => import('@/views/expertTask/expertIntroduction')
},
{
    path: '/expertsScheduleUniversal',
    name: 'expertsScheduleUniversal',
    meta: {
        title: '专家简介'
    },
    component: () => import('@/views/expertTask/expertsScheduleUniversal')
},
{
    path: '/expertScheduleDetail',
    name: 'expertScheduleDetail',
    meta: {
        title: '日程'
    },
    component: () => import('@/views/expertTask/expertScheduleDetail')
},
{
    // expertIframe
    path: '/expertIframe',
    name: 'expertIframe',
    meta: {
        title: '专家任务单'
    },
    component: () => import('@/views/expertTask/expertIframe')
},
{
    // expertConfirmation
    path: '/expertConfirmation',
    name: 'expertConfirmation',
    meta: {
        title: '专家确认单'
    },
    component: () => import('@/views/expertTask/expertConfirmation')
},
{
    path: '/taskList',
    name: 'taskList',
    meta: {
        title: '任务列表'
    },
    component: () => import('@/views/expertTask/taskList')
}
]