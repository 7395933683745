import { render, staticRenderFns } from "./CourseItem.vue?vue&type=template&id=39d54620&scoped=true&"
import script from "./CourseItem.vue?vue&type=script&lang=js&"
export * from "./CourseItem.vue?vue&type=script&lang=js&"
import style0 from "@styles/other/home/homeHotwords.css?vue&type=style&index=0&id=39d54620&scoped=true&lang=css&"
import style1 from "./CourseItem.vue?vue&type=style&index=1&id=39d54620&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39d54620",
  null
  
)

export default component.exports