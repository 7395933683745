

export default [
  {
    path: '/subjectMeetingList',
    name: 'subjectMeetingList',
    meta: {
      title: '学术大会'
    },
    component: () => import('@/views/subjectMeetingList')
  }
]