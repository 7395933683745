export default [
    {
        path: '/meetingLink',
        name: 'meetingLink',
        meta: {
            title: '加入会议'
        },
        component: () => import('@/views/link/meetingLink')
    },
    {
        path: '/appLink',
        name: 'appLink',
        meta: {
            title: '打开APP'
        },
        component: () => import('@/views/link/appLink')
    },
    {
        path: '/startMeeting',
        name: 'startMeeting',
        props: true,
        meta: {
            title: '启动会议'
        },
        component: () => import('@/views/link/startMeeting')
    },
]