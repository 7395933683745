import store from '@/store'
import {
    NativeMessageData
} from '@utils/nativeBridge/nativeMessage'

export function getVisitor() {
    try {
        // localStorage.removeItem('visitorID')
        let visitor = localStorage.getItem('visitorID')
        if (!visitor) {
            if (store.getters.isInApp) {
                let value = store.getters.nativeBridge.sendMessage(new NativeMessageData({
                    type: 'identifier'
                }))

                if (Object.prototype.toString.call(value) === '[object String]') {
                    value = JSON.parse(value)
                }
                const {
                    visitorUserId
                } = value

                if (visitorUserId) {
                    localStorage.setItem('visitorID', (visitor = visitorUserId))
                } else {
                    alert('visitorUserId获取失败')
                }
            } else {
                localStorage.setItem('visitorID', (visitor = new Array(8).fill().map(_ => parseInt(Math.random() * 10)).join('')))
            }
        }
        return visitor
    } catch (e) {
        console.log(e)
    }
}