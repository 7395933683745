export default {
  bannerData: [],
  advance_data: [],
  advance_page: 1,
  collegeID: 0,
  diseaseID: 0,
  isCollegeClose: false,
  playback_data: [],
  playback_page: 1,
  column_data: [],
  column_page: 1,
  consulting_data: [],
  consulting_page: 1,

  collegeList: [],
  allCollege: [], // 首页右侧菜单学院数据
  diseaselist: [], //疾病列表
  current_keyword: "",
  searchText: "",
  searchTabIndex: "",
  credit_data: [],
  credit_page: 1
};
