

export default {
    getads: '/api/web/v3/slides/homelist',
    advance_list: '/api/web/v3/meeting/homelist',
    advance_search: '/api/web/v3/meeting/search',
    playback_list: '/api/web/v2/meetingplayback/list',
    playback_search_list: '/api/web/v2/meetingplayback/search',
    column_list: '/api/web/v2/meeting/subject',
    column_search_list: '/api/web/v2/meeting/subject/search',
    consulting_list: '/api/web/v2/meeting/information',
    consulting_search_list: '/api/web/v2/meeting/information/search',
    advance_hotword: '/api/web/v2/meeting/notice/hotword',
    playback_hotword: '/api/web/v2/meetingplayback/hotword',
    column_hotword: '/api/web/v2/meeting/subject/hotword',
    consulting_hotword: '/api/web/v2/meeting/information/hotword',
    collegetree: '/api/web/v3/college/collegetree',
    collegelist: '/api/web/v3/college/collegelist',
    foucscollegelist: '/api/web/v3/college/foucscollegelist',
    changefouscollege: '/api/web/v3/college/changefouscollege',
    userfoucstatus: '/api/web/v3/college/userfoucstatus',
    expertlist: "/api/web/v3/doctorhomepage/expertlist",
    gettopexperts: "/api/web/v3/doctorhomepage/gettopexperts",
    recommend: '/api/web/v3/meeting/recommend',
    courselist: '/api/web/v3/production/courselist',//首页的视频课程
    seriescourselist: '/api/web/v3/production/seriescourselist',//首页的系列课程
    searchCourse: '/api/web/v3/production/searchCourse',//课程搜素
    searchSeriesCourse: '/api/web/v3/production/searchSeriesCourse',//系列的搜索
    coursedetail: '/api/web/v3/production/detail',
    seriescoursedetail: '/api/web/v3/production/seriescoursedetail',
    surgicalList: '/api/web/v3/production/surgicalList',//手术&搜索
    menuicon: '/api/web/v3/meeting/menuicon',
    allcourse: '/api/web/v3/production/allcourse',//精品课程列表
    contentlist: '/api/web/v3/meeting/contentlist',
    getdiseasename: '/api/web/v3/college/getdiseasename',
    informationList: '/api/web/v3/information/informationList',//医生文章列表
    creditmeetinglist: '/api/web/v3/meeting/creditmeetinglist', //学分专栏
    enterprisemeetinglist: '/api/web/v3/meeting/enterprisemeetinglist', // 企业专栏会议列表
}
