import Request from '@request'
import api from '@api'
import utils from '@utils'

export default {
    generateShearPlate({
        dispatch,
        commit,
        state,
        rootState
    }, params) {
        return new Promise((resolve, reject) => {
            Request.post(api.deptAssociation.generateShearPlate, params).then(res => {
                const {data} = res
                resolve(data)
            }).catch(_=>{
                reject()
            })
        })
    },
    getShearPlateInfo({
        dispatch,
        commit,
        state,
        rootState
    }, params) {
        return new Promise((resolve, reject) => {
            Request.post(api.deptAssociation.getShearPlateInfo, params, false).then(res => {
                const {data} = res
                resolve(data)
            }).catch(_=>{
                reject()
            })
        })
    },
    shearPlateLogin({
        dispatch,
        commit,
        state,
        rootState
    }, params) {
        return new Promise((resolve, reject) => {
            Request.post(api.deptAssociation.shearPlateLogin, params).then(res => {
                const { data } = res
                if (data.userid) commit('user/setUserLoginData', data, {
                    root: true
                })
                resolve(data)
            }).catch(e=>{
                reject(e)
            })
        })
    }
}