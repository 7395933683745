import os from "@utils/os";
import {
  getVisitor
} from "@utils/user";

export default {
  wxConfig: (state) => state.wechat.wxConfig,
  wx: (state) => state.wechat.wx,
  socket: (state) => state.socket.socket,
  nativeBridge: (state) => state.nativeBridge.nativeBridge,
  currentTitle: (state) => state.wechat.currentTitle,
  isMain: (state) => state.common.isMain,
  isLogin: (state) => state.user.isLogin,
  // isLoginElsewhere: state => state.user.isLoginElsewhere,
  visitor: (_) => getVisitor(),
  userIdentity: (state) => state.user.userIdentity,
  showHeaderAppTips: (state) =>
    state.common.isMain &&
    state.common.showHeaderAppTips &&
    !state.common.isInApp,
  pcWidth310: (_) => (os.isPC() ? "310px" : ""),
  contentWidth: (_) => (os.isPC() ? "580px" : "100%"),
  contentHeight: (state) => state.common.contentHeight,
  contentLeft: (state) => state.common.contentLeft,
  contentRight: (state) => state.common.contentRight,
  silderRight: (state) => parseFloat(state.common.contentRight) + 20 + "px",
  studySilderRight: (state) =>
    parseFloat(state.common.contentRight) - 580 + "px",
  canGoback: (state) => state.common.canGoback,
  isInApp: (state) => state.common.isInApp,
  isInPcApp: (state) => state.common.isInPcApp,
  userInfo: (state) => state.user.userInfos,
  aliveList: (state) => state.common.aliveList,
  ifReviewTime: (state) => state.common.ifReviewTime,
  appMessage: (state) => state.common.appMessage,
  isAppResume: (state) => state.common.isAppResume,
  sellerCode: (state) => state.common.sellerCode,
  userID: state => state.user.userID,
  isAppHomePage: state => state.common.isAppHomePage
};