

export default {
    testmeetingroomLogin: '/api/web/v2/doctorhomepage/testmeetingroom', //任务单id、
    testmeetingroom: '/api/web/v3/restroom/roomdetail',//新的试片室详情 试片室id
    experthomepage: '/api/web/v2/doctorhomepage/experthomepage',
    authDoctorInfo: '/api/web/v2/doctorhomepage/authdoctorinfo',
    doctorinfo: '/api/web/v2/doctorhomepage/doctorinfo',
    loginpage: '/api/web/v2/doctorhomepage/loginpage',
    checksubjectexpert: '/api/web/v2/doctorhomepage/checksubjectexpert',
    login: '/api/web/v2/doctorhomepage/login',
    codelogin: '/api/web/v2/doctorhomepage/codelogin',
    checkcanjoinmeetingLogin: '/api/web/v2/doctorhomepage/checkcanjoinmeeting',
    checkcanjoinmeeting: '/api/web/v3/restroom/canjoinmeeting',//新的试片室可否可用
    urlstartmeeting: '/api/web/v2/doctorhomepage/urlstartmeeting',
    expertsessionpage: '/api/web/v2/doctorhomepage/expertsessionpage',
    expertsessionroom: '/api/web/v2/doctorhomepage/expertsessionroom',
    expertsessioninfo: '/api/web/v2/doctorhomepage/expertsessioninfo',
    expertpayinfo: '/api/web/v2/doctorhomepage/expertpayinfo',
    expertwithdrawapply: '/api/web/v2/doctorhomepage/expertwithdrawapply',
    sendcode: '/api/web/v2/doctorhomepage/sendcode',
    officalwebsite: '/api/web/v2/doctorhomepage/officalwebsite',
    getuserauthstatus: '/api/web/v2/doctorhomepage/getuserauthstatus',
    getuserauthinfo: '/api/web/v2/doctorhomepage/getuserauthinfo',
    submitauth: '/api/web/v2/doctorhomepage/submitauth',
    servicephone: '/api/web/v2/doctorhomepage/servicephone',
    download: '/api/web/v2/client/download',
    docterschedule: '/api/web/v3/subject/docterschedule',
    schedulepage: '/api/web/v2/doctorhomepage/schedulepage',
    expertsessiondetail: '/api/web/v2/doctorhomepage/expertsessiondetail',
    topmenu: '/api/web/v2/doctorhomepage/topmenu',
    expertsessionshare: '/api/web/v2/doctorhomepage/expertsessionshare',
    assignmentaffirm: '/api/web/v2/doctorhomepage/assignmentaffirm',
    assignmentaffirmstatus: '/api/web/v2/doctorhomepage/assignmentaffirmstatus',
    getguestwait: '/api/web/v2/doctorhomepage/getguestwait', // 嘉宾待办列表
    getwaitguestinfo: '/api/web/v2/doctorhomepage/getwaitguestinfo', // 查询嘉宾信息(待办)
    editwaitguestinfo: '/api/web/v2/doctorhomepage/editwaitguestinfo', // 编辑嘉宾信息(待办)
    editwaitguestass: '/api/web/v2/doctorhomepage/editwaitguestass', // 更新嘉宾待办完成状态
    disposeguestwait: '/api/web/v2/doctorhomepage/disposeguestwait',  //嘉宾待办立即处理
    getmultipleguest: '/api/web/v2/doctorhomepage/getmultipleguest'

}
