<template>
  <div class="acount-login">
    <div class="content">
      <div class="login-content">
        <div class="header-item">
          <div class="header-title">
            <span v-translate>账号登录</span>
          </div>
          <div class="header-close" @click="cancel" v-if="isShowClose">
            <img src="@/assets/images/login/close.png" alt />
          </div>
        </div>
        <div class="login-form">
          <van-field
            class="my-field"
            type="text"
            size="large"
            center
            v-model="username"
            name="用户名"
            :placeholder="$ct('请输入账号')"
          />
          <div class="line"></div>
          <van-field
            class="my-field"
            size="large"
            center
            v-model="password"
            type="password"
            :placeholder="$ct('请输入密码')"
          />
          <div class="line"></div>
          <van-field
            v-if="config.user_identity == 1"
            class="my-field"
            input-align="left"
            v-model="sms"
            center
            size="large"
            :placeholder="$ct('请输入短信验证码')"
          >
            <template #button>
              <van-button
                size="small"
                type="info"
                @click="handleSend"
                :class="{ isSms: isSms }"
              >{{ smsText }}</van-button>
            </template>
          </van-field>
          <div class="line" v-if="config.user_identity == 1"></div>
        </div>

        <div class="forget-password">
          <span @click="forgetPassword" v-translate>忘记密码</span>
        </div>
        <div class="login-error-tip">{{errorText}}</div>
        <agreement v-model="isChecked" :agreementId="agreementId"></agreement>

        <van-button
          type="info"
          class="login-button"
          @click="handleLogin"
          :disabled="username && password && !isChecked"
        >
          <span v-translate>登录</span>
        </van-button>
        <div class="login-type">
          <div class="type-name" @click="handleLoginType">
            <span v-translate>手机验证码登录</span>
          </div>
          <div class="type-language" @click="changeLanguage('zh')" v-if="$i18n.locale == 'en'">中文</div>
          <div class="type-language" @click="changeLanguage('en')" v-else>English</div>
        </div>
      </div>
    </div>
    <drag-verify-img-chip
      v-if="ischeckImg"
      @onSuccess="onSuccess"
      @closeOverlay="ischeckImg = false"
    ></drag-verify-img-chip>
    <van-dialog
      v-model="loginTimeDialog"
      show-cancel-button
      :cancelButtonText="config.password_past == 1 ? $ct('取消') : '继续登录'"
      :confirmButtonText="$ct('更改密码')"
      confirmButtonColor="#0089FF"
      cancelButtonColor="#CECECE"
      @cancel="beforeLoginTimeCancel"
      @confirm="beforeLoginTimeConfirm"
    >
      <div class="dialog_content">
        <div class="dialog-title">安全提示</div>
        <div class="dialog_tip">
          <span
            v-if="config.password_past == 2"
            v-translate
            style="color: #888888;"
          >您的账户密码已经超过{{config.password_period}}天未更换为保障安全，建议您及时更改密码</span>
          <span
            v-if="config.password_past == 1"
            v-translate
            style="color: #888888;"
          >您的账户密码已经超过{{config.password_period}}天未更换为保障账户安全须更换新密码后才可登录，点击按钮进行更改</span>
        </div>
      </div>
    </van-dialog>
    <van-dialog
      v-model="loginDialog"
      show-cancel-button
      :confirmButtonText="$ct('更改密码')"
      :cancelButtonText="$ct('取消')"
      confirmButtonColor="#0089FF"
      cancelButtonColor="#CECECE"
      @cancel="beforeLoginCancel"
      @confirm="beforeLoginConfirm"
    >
      <div class="dialog_content">
        <div class="dialog-title">安全提示</div>
        <div class="dialog_tip">
          <span v-translate style="color: #888888; ">您的账户为初始密码，为保障账户安全须更换新密码后才可登录，点击按钮进行更改</span>
        </div>
      </div>
    </van-dialog>
  </div>
</template>
<script>
// import { ActionSheet, Form, Field, Button, Toast } from 'vant'
import Agreement from '@components/Login/Agreement'
import DragVerifyImgChip from '@components/Common/DragVerifyImgChip'
import Request from '@request'
import api from '@api'
export default {
    name: 'Login',
    data() {
        return {
            errorText: '',
            username: '',
            password: '',
            isChecked: null,
            agreementId: 6,
            isNationnalShow: true,
            sms: '',
            smsText: this.$i18n.locale == 'zh' ? '获取验证码' : 'Send code',
            timer: null,
            isSms: false,
            areaNumber: 86,
            ischeckImg: false,
            loginTimeDialog: false,
            loginDialog: false,
            config: {}
        }
    },
    props: {
        loginFlag: {
            type: Boolean,
            default: false
        },
        isShowClose: {
            type: Boolean,
            default: true
        }
    },
    watch: {
        loginFlag(val) {
            this.loginFlag = val
        }
    },
    components: { Agreement, DragVerifyImgChip },
    created() {
        this.getinsuranceconfig()
    },
    methods: {
        getinsuranceconfig() {
            Request.post(api.common.getinsuranceconfig, {
                type: 2
            }).then(response => {
                this.config = response.data
            })
        },
        onSuccess() {
            this.ischeckImg = false
            if (this.checkType == 'code') {
                this.sendCode()
            } else if (this.checkType == 'login') {
                this.login()
            }
        },
        sendCode() {
            this.isSms = true
            if (!this.timer) {
                this.$store
                    .dispatch('sendCode', {
                        phone: this.username,
                        country_code: this.areaNumber,
                        sms_signatures: this.signatures
                    })
                    .then(res => {
                        let count = 60
                        this.timer = setInterval(() => {
                            if (count > 0 && count <= 60) {
                                count--
                                this.smsText =
                                    this.$i18n.locale == 'zh'
                                        ? `重新获取(${count}s)`
                                        : `${count}s Replace Send`
                                this.isSms = true
                            } else {
                                this.smsText =
                                    this.$i18n.locale == 'zh' ? `获取验证码` : 'Send code'
                                clearInterval(this.timer)
                                this.timer = null
                                this.isSms = false
                            }
                        }, 1000)
                    })
                    .catch(_ => {
                        this.isSms = false
                    })
            }
        },
        handleSend() {
            if (this.isSms) return
            Request.post(api.common.getcountrycode, { username: this.username }).then(res => {
                this.areaNumber = res.data.country_code
                if (res.data.country_code == '86') {
                    var reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
                } else {
                    var reg_tel = /^[0-9]*$/
                }
                if (!reg_tel.test(this.username)) {
                    if (this.$i18n.locale == 'zh') {
                        this.$toast('请输入正确的手机号')
                        this.smsText = '发送验证码'
                    } else {
                        this.$toast('phone error')
                        this.smsText = 'Send code'
                    }
                    return false
                }

                if (this.config.verification_code == 1) {
                    this.ischeckImg = true
                    this.checkType = 'code'
                } else {
                    this.sendCode()
                }
            })
        },
        changeLanguage(name) {
            if (name == 'en') {
                this.smsText = 'Send code'
            } else {
                this.smsText = '发送验证码'
            }
            this.$i18n.locale = name
        },
        forgetPassword() {
            this.$router.push({
                path: '/forgetPassword'
            })
        },
        beforeLoginCancel() {
            this.loginDialog = false
        },
        beforeLoginConfirm() {
            this.$router.push({
                path: '/changePassword',
                query: {
                    phone: this.username
                }
            })
        },
        beforeLoginTimeCancel() {
            this.loginTimeDialog = false
            if (this.config.password_past == 2) {
                var params = {
                    username: this.username,
                    password: this.password,
                    code: this.sms,
                    openid: localStorage.getItem('wxopenid'),
                    unionid: localStorage.getItem('unionid')
                }
                this.$store
                    .dispatch('user/login', params)
                    .then(res => {
                        this.$listeners.handleClose()
                    })
                    .catch(err => {
                        this.errorText = err.message
                    })
            }
        },
        beforeLoginTimeConfirm() {
            this.loginTimeDialog = false
            this.$router.push({
                path: '/changePassword',
                query: {
                    phone: this.username
                }
            })
        },
        handleLogin() {
            if (this.config.user_identity == 1) {
                if (!this.sms) {
                    this.$toast('请输入验证码')
                }
            }

            if (!this.isChecked) {
                this.$toast('请勾选医谱服务协议')
                return false
            }
            if (!this.username) {
                if (this.$i18n.locale == 'zh') {
                    this.$toast('用户名或密码错误')
                } else {
                    this.$toast('name or password error')
                }
                return false
            }
            if (!this.password) {
                this.$toast('请填写密码')
                return false
            }
            if (this.config.verification_code == 1 && this.config.user_identity == 2) {
                this.ischeckImg = true
                this.checkType = 'login'
            } else {
                this.login()
            }
        },
        login() {
            let param = {
                username: this.username,
                password: this.password,
                code: this.sms,
                openid: localStorage.getItem('wxopenid'),
                unionid: localStorage.getItem('unionid')
            }
            Request.post(
                api.common.initialpastpassword,
                {
                    username: this.username,
                    password: this.password,
                    code: this.sms,
                    type: 2,
                    login_type: 2
                },
                false
            )
                .then(res => {
                    this.$store
                        .dispatch('user/login', param)
                        .then(res => {
                            this.$listeners.handleClose()
                        })
                        .catch(err => {
                            console.log(err)
                            this.errorText = err.message
                        })
                })
                .catch(err => {
                    if (err.status == 5112) {
                        this.loginTimeDialog = true
                    } else if (err.status == 5113) {
                        this.loginDialog = true
                    } else {
                        this.$toast(err.message)
                    }
                })
        },
        cancel() {
            this.$listeners.handleClose()
        },
        handleLoginType() {
            this.$emit('handleLoginType', 2)
            this.errorText = ''
        }
    }
}
</script>
<style lang="scss" scoped>
@import '@styles/variables.scss';
::v-deep {
    .van-dialog {
        border-radius: 0.17rem;
    }
}
.login-error-tip {
    height: 2rem;
    display: flex;
    align-items: center;
    margin: 0.2rem 0;
    font-size: 0.4rem;
    color: red;
}
.isSms {
    background: rgba(0, 137, 255, 0.2);
    border-radius: 2px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #0089ff;
    border: none;
}
.login-content {
    flex: 1;
    width: 100%;
    // height: 9.472rem;
    padding: 1.18rem 1.33rem 0 1.33rem;
    box-sizing: border-box;
    background: url(/img/login_cricle.ad20ba9b.png) right/cover no-repeat;
    background-size: 100%;
    background-position-x: 0px;
    background-position-y: 0px;
    margin-bottom: 1rem;
}
.header-item {
    display: flex;
    justify-content: space-between;
}
.header-title {
    font-size: $fsxl;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #222222;
}
.header-close img {
    width: 1.1rem;
    height: 1.1rem;
    vertical-align: middle;
}
.login-form {
    margin-top: 1rem;
}

.my-field {
    padding-right: 0;
    padding-left: 0;
}
.line {
    height: 1px;
    background: #cecece;
}

.login-button {
    margin-top: 0.43rem;
    width: 100%;
}
.forget-password {
    cursor: pointer;
    margin-top: 0.43rem;
    width: 100%;
    text-align: right;
    font-size: 0.6rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    span {
        font-size: 0.6rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
    }
}
.login-type {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.48rem;
    font-size: $fsmd;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #888888;
}
</style>

<style lang="scss" scoped>
@import '@styles/variables.scss';
.login-form ::v-deep {
    .my-field {
        background: transparent;
    }
    .van-cell::after {
        border: none;
    }
}
// }
.my-field ::v-deep {
    .van-field__control {
        height: 1.2rem;
        font-size: $fslg;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        /* color: #CECECE; */
    }
}
// }
.login-form {
    ::v-deep {
        .van-cell::after {
            content: '';
            display: none !important;
        }
    }
}
@media screen and (min-width: 750px) {
    .my-field ::v-deep {
        .van-field__control {
            height: 1.2rem;
            font-size: $fslg-pc;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
        }
    }
}
.dialog_content {
    padding: 0.6rem;
    box-sizing: border-box;
}
.dialog-title {
    font-size: 16px;
    text-align: center;
    margin-bottom: 0.4rem;
}
.dialog_tip {
    color: red;
    font-size: 0.55rem;
    font-family: PingFang SC;
    font-weight: 400;
}

.dialog_tel {
    margin-top: 0.1rem;
    display: flex;
    font-size: 0.45rem;
    font-family: PingFang SC;
}
</style>
