export default {
  getMeetingDetails: '/MedicalCollege/meeting/getMeetingDetails', // 视频会议详情
  getCalendarList: '/MedicalCollege/meeting/getCalendarList', //获取日历数据
  getCalendarDateList: '/MedicalCollege/meeting/getCalendarDateList', //获取日历日期信息
  getHistoryRecord: '/MedicalCollege/meeting/getHistoryRecord', // 获取历史数据
  getQRCode: '/MedicalCollege/meeting/getQRCode', // 获取二维码
  cancelMeeting: '/MedicalCollege/meeting/cancelMeeting', //取消跟删除日程通用
  join: '/MedicalCollege/meeting/join',
  sessiondetail: '/api/web/v3/meeting/sessiondetail',
  pcsessiondetail: '/api/v2/docbook/sessiondetail',
  getFutureRecord: '/MedicalCollege/meeting/getFutureRecord' //获取未来记录
}