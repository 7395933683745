<template>
    <div class="nav-bar" :style="{ background: bgColor }">
        <div class="nav-bar-left" :style="{ visibility: hiddenBack ? 'hidden' : 'visible' }">
            <slot name="left"
                ><img class="goback" src="@images/common/new_back.png" alt="" @click="goBack"
            /></slot>
        </div>
        <slot name="title"
            ><span class="van-ellipsis"> {{ title ? title : currentTitle }}</span></slot
        >

        <!-- <slot name="title"><span class="van-ellipsis"> {{title ? title : currentTitle}}</span></slot> -->
        <div class="nav-bar-right">
            <slot name="right"></slot>
        </div>
    </div>
</template>

<script>
import { mapGetters } from '@utils/vuex'
import { NativeMessageNotice } from '@utils/nativeBridge/nativeMessage'

export default {
    props: {
        title: {
            type: String,
            require: false,
            default: ''
        },
        hiddenBack: {
            type: Boolean,
            require: false,
            default: false
        },
        bgColor: {
            type: String,
            require: false,
            default: '#f4f4f4'
        }
    },
    computed: mapGetters(['currentTitle', 'canGoback', 'isInApp']),
    created() {
        console.log('navbar created', this.canGoback)
    },
    methods: {
        goBack() {
            // this.$router.go(-1)

            if (this.$listeners.leftClick) {
                this.$emit('leftClick')
            } else {
                if (this.isInApp) {
                    this.$nativeBridge.sendMessage(
                        new NativeMessageNotice({
                            type: 'goBack'
                        })
                    )
                } else {
                    if (!this.canGoback) {
                        this.$router.push('/')
                    } else {
                        this.$router.go(-1)
                    }
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@styles/variables.scss';
.nav-bar {
    width: 100%;
    height: 1.92rem;
    display: flex;
    align-items: center;

    .goback {
        cursor: pointer;
    }

    > span {
        flex: 1;
        font-size: $fsxl;
        color: #111;
        text-align: center;
    }

    .nav-bar-left {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 2.96rem;
        margin-left: 0.64rem;

        > img {
            width: 0.85rem;
            height: 0.85rem;
        }
    }

    .nav-bar-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 2.96rem;
        margin-right: 0.64rem;
    }
}
</style>