function canResize(target) {
    if (!target) return false // 是空则不添加
    // 必须是网络图片
    return target.includes('http') && !target.includes('x-oss-process=image/resize')
}

function changeOSSResize(el, binding, vueDom) {
    if (el.classList.contains('van-image')) { // 说明是 van-image
        const {
            propsData
        } = vueDom.componentOptions
        
        if (canResize(propsData.src)) {
            vueDom.child._props.src = `${propsData.src}?x-oss-process=image/resize,w_${binding.value}`
        }
    } else if (el.src) { // 说明是 src
        if (canResize(el.src)) {
            el.src = `${el.src}?x-oss-process=image/resize,w_${binding.value}`
        }
    } else { // 是 v-lazy 赋值的
        const {
            directives
        } = vueDom.data
        for (let i = 0; i < directives.length; i++) {
            const directive = directives[i]
            if (directive.name === 'lazy') {
                if (canResize(directive.value)) {
                    directive.value = `${directive.value}?x-oss-process=image/resize,w_${binding.value}`
                }
                break
            }
        }
    }
}

export default {
    bind(el, binding, vueDom) {
        changeOSSResize(el, binding, vueDom)
    },
    update(el, binding, vueDom) {
        changeOSSResize(el, binding, vueDom)
    }
}