<template>
    <div id="load_wrap">
        <div class="load">
            <lottie :options="options" class="lottie" />
        </div>
    </div>
</template>

<script>
    import Lottie from '@components/Lottie'
    import globalLoading from '@animation/globalLoading'

    export default {
        components: {
            Lottie
        },
        data() {
            return {
                options: {
                    loop: true,
                    animationData: globalLoading,
                },
            }
        },
    }
</script>

<style lang="scss" scoped>
    #load_wrap {
        position: relative;
        width: 100%;
        height: 100%;
        background: #fff;
        z-index: 1000000;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .load {
        width: 116px;
        height: 66px;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .lottie {
        width: 1.4rem;
    }
</style>