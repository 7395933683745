import store from "@/store";
import {
    NativeMessagePush,
} from '@utils/nativeBridge/nativeMessage'

export default {
    type: 'login',
    extra: {
        authorization: false,
        platformHandler: false,
    },
    path: ['/login'],
    handler(info) {
        return new Promise((resolve, reject) => {
            const {
                to,
                from,
                next
            } = info

            if (store.getters.isInApp) {
                // let params = {}
                // if (to.query.appParams) {
                //     params = {...to.query.appParams,
                //         path: from.path,
                //         fullPath: `${from.path.replace('/', '')}${Object.keys(to.query.appParams).reduce((query, cur, index) => {
                //             const val = encodeURIComponent(to.query.appParams[cur])
                //             if (index === 0) {
                //                 query += `?${cur}=${val}`
                //             }
                //             else {
                //                 query += `&${cur}=${val}`
                //             }
                //             return query
                //     },'')}`}  
                // }
                store.getters.nativeBridge.sendMessage(
                    new NativeMessagePush({
                        pageName: 'login',
                        // params
                    })
                )
                document.title = ''
                store.getters.wx.share({
                  title: '',
                })
                return store.commit('hideLoading')
            }
            if (!to.query.from && from.path !== '/privacyTreaty') {
                resolve({
                    ...to,
                    query: {
                        from: from.path === '/' ? undefined : from.path.replace('/', ''),
                        ...from.query,
                        ...to.query
                    }
                })
            } else {
                resolve()
            }
        })
    }
}