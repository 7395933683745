<template>
  <div id="app" class="wrap" :style="contentWidth">
    <IETip v-if="isIE" :style="{ width: contentWidth }"></IETip>
    <div class="content-wrap">
      <global-loading v-show="showLoading" />
      <expert-task-header v-if="isShowHeader"></expert-task-header>
      <keep-alive :include="aliveList">
        <router-view style="flex: 1; height: 0" />
      </keep-alive>
    </div>
    <!-- <tabbar v-show="showTabbar" style="position: relative"></tabbar> -->
  </div>
</template>

<script>
// import GuideMask from '@components/GuideMask'
import GlobalLoading from '@components/Common/GlobalLoading'
// ExpertTaskHeader
import ExpertTaskHeader from '@components/ExpertTaskHeader'
import IETip from '@components/IETip'
// import Tabbar from '@components/Tabbar'
import Utils from '@utils'
import os from '@utils/os'

export default {
  components: {
    ExpertTaskHeader,
    GlobalLoading,
    IETip
  },
  data () {
    return {
      routePath: '',
      isShowHeader: false
    }
  },
  watch: {
    $route (to, from) {
      if (to.name == 'expertTaskList') {
        this.isShowHeader = true
      } else if (to.query.isShowHeader == 1) {
        if (to.query.is_top_menu && to.query.is_top_menu == 2) {
          this.isShowHeader = false
        } else {
          this.isShowHeader = true
        }
      } else if (from.name == 'expertTaskList' && to.name == 'forgetPassword') {
        this.isShowHeader = false
      } else if (from.name == 'expertTaskList' && to.name == 'taskOrders') {
        this.isShowHeader = false
      } else {
        this.isShowHeader = false
      }

      this.routePath = this.$route.name
    }
  },
  computed: {
    isIEShow () { },
    showLoading () {
      return this.$store.state.common.showLoading
    },
    showTabbar () {
      return this.$store.state.common.showTabbar
    },
    contentWidth () {
      return {
        maxWidth: this.$store.getters.contentWidth
      }
    },
    aliveList () {
      return this.$store.getters.aliveList
    },
    isIE () {
      if (!!window.ActiveXObject || 'ActiveXObject' in window) {
        console.log(this.routePath)
        if (
          this.routePath == 'ypComment' ||
          this.routePath == 'ypExperts' ||
          this.routePath == 'ypSchedule'
        ) {
          return false
        } else {
          return true
        }
      } else {
        return false
      }
    }
  },
  created () {
    // console.log(this.$route, '111111')
    const ruleOne = Utils.findCssRule('.van-tabs__wrap--scrollable .van-tabs__nav')
    const ruleTwo = Utils.findCssRule('.van-tabs__wrap, .van-tabs__wrap--scrollable')
    let isWindow = false
    let agent = navigator.userAgent.toLowerCase()
    let isMac = /macintosh|mac os x/i.test(navigator.userAgent)
    if (agent.indexOf('win32') >= 0 || agent.indexOf('wow32') >= 0) {
      isWindow = true
    }
    if (agent.indexOf('win64') >= 0 || agent.indexOf('wow64') >= 0) {
      isWindow = true
    }

    if (os.isPC() && isWindow) {
      ruleOne.style.cssText = 'overflow: initial !important'
      ruleTwo.style.cssText = 'overflow-x: auto !important'
    }
  },
  mounted () { }
}
</script>

<style scoped lang="scss">
.wrap {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  background: #ececec;
  display: flex;
  flex-direction: column;

  .content-wrap {
    flex: 1;
    height: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

.IE-tips {
  min-height: 1.7rem;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  line-height: 1.7rem;
  background: #f95352;
  font-size: 0.5rem;
  color: #fff;
}

.st-commonwealth-player {
  position: absolute;
  top: 100000px;
  left: 100000px;
}
</style>
