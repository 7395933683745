let domain = ''
let saasDomain = ''
let socket = 'wss://testsocket.docbook.com.cn:8443'
let env = ''
let pcEnv = ''
let javaDoMain = ''

if (/dev-m/.test(window.location.href)) {
    domain = 'https://mapidev.docbook.com.cn'
    env = 'dev'
    pcEnv = 'test'
} else if (/test1-m/.test(window.location.href)) {
    domain = 'https://mapitest1.docbook.com.cn'
    saasDomain = 'https://test-manapi1.docbook.cn'
    javaDoMain = 'https://test1medical-college.docbook.com.cn'
    env = 'test1'
    pcEnv = 'test1'
} else if (/test-m/.test(window.location.href)) {
    domain = 'https://mapitest.docbook.com.cn'
    saasDomain = 'https://test-manapi.docbook.cn'
    javaDoMain = 'https://testmedical-college.docbook.com.cn'
    env = 'test'
    pcEnv = 'test'
} else if (/release-m/.test(window.location.href)) {
    domain = 'https://mapirelease.docbook.com.cn'
    saasDomain = 'https://dev-manapi.docbook.cn'
    javaDoMain = 'https://releasemedical-college.docbook.com.cn'
    env = 'release'
    pcEnv = 'release'
} else if (/localhost/.test(window.location.href)) {
    domain = "https://mapirelease.docbook.com.cn";
    saasDomain = 'https://dev-manapi.docbook.cn'
    javaDoMain = 'https://releasemedical-college.docbook.com.cn'
    env = "release";
    pcEnv = 'release'
    // domain = 'https://mapitest.docbook.com.cn'
    // saasDomain = 'https://test-manapi.docbook.cn'
    // javaDoMain = 'https://test1medical-college.docbook.com.cn'
    // javaDoMain = 'https://testmedical-college.docbook.com.cn'
    // env = 'test'
    // pcEnv = 'test'
} else if (/192.168/.test(window.location.href)) {
    domain = 'https://mapirelease.docbook.com.cn'
    javaDoMain = 'https://releasemedical-college.docbook.com.cn'
    env = 'dev'
    pcEnv = 'test'
} else if (/test-heartm.docbook.com.cn/.test(window.location.href)) {
    domain = 'https://mapitest.docbook.com.cn'
    env = 'test'
    pcEnv = 'test'
} else if (/release-heartm.docbook.com.cn/.test(window.location.href)) {
    domain = 'https://mapirelease.docbook.com.cn'
    env = 'release'
    pcEnv = 'test'
} else if (/heartm.docbook.com.cn/.test(window.location.href)) {
    domain = 'https://mapi.docbook.com.cn'
    saasDomain = 'https://manapi.docbook.cn'
    javaDoMain = 'https://medical-college.docbook.com.cn'
} else if (/graym.docbook.com.cn/.test(window.location.href) || /gray-m.docbook.com.cn/.test(window.location.href)) {
    env = 'gray'
    pcEnv = 'gray'
    domain = 'https://graymapi.docbook.com.cn'
    saasDomain = 'https://graymanapi.docbook.cn'
    socket = 'wss://chatsocket.docbook.com.cn:8443'
    javaDoMain = 'https://graymedical-college.docbook.com.cn'
} else {
    domain = 'https://mapi.docbook.com.cn'
    saasDomain = 'https://manapi.docbook.cn'
    socket = 'wss://chatsocket.docbook.com.cn:8443'
    javaDoMain = 'https://medical-college.docbook.com.cn'
}



// domain = 'https://mapidev.docbook.com.cn/'
// domain = "https://mapirelease.docbook.com.cn";
// domain = "https://mapi.docbook.com.cn";
// javaDoMain = 'https://testmedical-college.docbook.com.cn'
// domain = "https://mapi.docbook.com.cn"
// javaDoMain = 'https://medical-college.docbook.com.cn'
// domain = 'https://mapitest1.docbook.com.cn'
// saasDomain = 'https://test-manapi1.docbook.cn'
// javaDoMain = 'https://test1medical-college.docbook.com.cn'
// env = 'test1'
// pcEnv = 'test1'
// domain = 'https://mapitest.docbook.com.cn'
// domain = 'https://graymanapi.docbook.cn'

export default {
    domain,
    saasDomain,
    javaDoMain,
    socket,
    env,
    pcEnv,
}