
import { sm2 } from 'sm-crypto'
import Type from '@utils/type'

const PUBLIC_KEY = '04b873eca57be413cb6abeb3555781cd49b73619f3825212862a220603a50e60cbfd52a4049682b6002d3d6ca4e84779382bd32f6ceca3709f26f158eb2d839b09'
const PRIVATE_KEY = '43a5a07a138c91b868b9a3b8b5a03ae7202708b24ad4058f1d571e2bddc340c9'
const CIPHER_MODE = 1 // 1 - C1C3C2，0 - C1C2C3，默认为1

export function doEncrypt(data, isJOSN = true) {
    if (!data) return ''
    return sm2.doEncrypt(isJOSN ? JSON.stringify(data) : data , PUBLIC_KEY, CIPHER_MODE)
}

export function doDecrypt(data, isJOSN = false) {
    if (Type.isString(data) && !data) return ''
    else if (!data) return ''
    return sm2.doDecrypt(isJOSN ? JSON.parse(data) : data, PRIVATE_KEY, CIPHER_MODE)
}