import store from '@/store'
class WechatUtils {
    constructor() {
        this.wx = window.wx
        this.isReady = false
        this.wxConfig = undefined
        this.shareDataCache = undefined

        this._setListener()
    }

    config(config) {
        this.wxConfig = config
        this.wx.config(config)
    }

    share(data) {
        store.commit('wechat/setCurrentTitle', data.title)
        if (!data.link) data.link = window.location.href
        this.wx.onMenuShareTimeline(data)
        this.wx.onMenuShareAppMessage(data)
        this.wx.onMenuShareQQ(data)
        this.wx.onMenuShareQZone(data)
    }

    hideMenu() {
        this.wx.hideMenuItems({
            menuList: [
                "menuItem:share:appMessage",
                "menuItem:share:timeline",
                "menuItem:share:qq",
                "menuItem:share:weiboApp",
                "menuItem:favorite",
                "menuItem:share:facebook",
                "menuItem:share:QZone",
                "menuItem:copyUrl",
                "menuItem:openWithQQBrowser",
                "menuItem:openWithSafari",
                "menuItem:share:email",
            ],
        })
        this.wx.hideAllNonBaseMenuItem()
        this.wx.hideOptionMenu()
    }

    showMenu() {
        this.wx.showMenuItems({
            menuList: [
                "menuItem:share:appMessage",
                "menuItem:share:timeline",
                "menuItem:share:qq",
                "menuItem:share:weiboApp",
                "menuItem:favorite",
                "menuItem:share:facebook",
                "menuItem:share:QZone",
                "menuItem:copyUrl",
                "menuItem:openWithQQBrowser",
                "menuItem:openWithSafari",
                "menuItem:share:email",
            ]
        })
        this.wx.showAllNonBaseMenuItem()
        this.wx.showOptionMenu()
    }

    _setListener() {
        this.wx.ready = _ => {
            console.log('ready', this.shareDataCache)
            this.isReady = true
            if (this.shareDataCache) this.share(this.shareDataCache)
        }
    }
}

export default WechatUtils