export default {
    account: 'Account',
    password: 'Password',
    phone: 'Phone',
    code: 'input SMS verification code',
    leftTitle: 'Login',
    rightTitle: 'Account login',
    loginSubmit: 'Login',
    loginTip: 'if you are not registered, the system will automatically register for you, and the password will be sent to your mobile phone by SMS',
    invitedTip: 'The mobile phone number you log in is not an invited faculty. Please change another account to log in!',
    thirdParty: 'Third-party Account Login',
    invitedcommittee: 'if you have any questions, please contact the conference committee',
    countryregion: 'Please choose the country and region',
    dahui: 'Official website of the conference',
    genghuan: 'Replace mobile phone',
    forget: 'Forget Password',
    downLoadTip: 'Download Docbook APP for more',
    bind: 'BIND',
    meetingDetail: {
        home: 'Home',
        people: 'people',
        invitedPeople: 'You hava invited ',
        invite: 'Invite',
        noRanking: 'No ranking',
        // keepgoing: '马上向您的朋友发起邀请',
        instructions: 'explain',
        viewingVolume: 'Views',
        PeopleFollowed: 'Followed',
        ReturnToList: 'Return to list',
        start: 'start',
        more: 'More',
        time: 'time',
        likeText: 'Likes',
        likeedText: 'Likes',
        lectureTopic: 'topic',
        expertText: 'faculty',
        noticeText: 'notice',
        playText: 'play',
        playback: 'replay',
        reviewText: 'review',
        selected: 'Selected',
        liveText: 'live',
        loginPlay: 'login  play',
        Play: 'play',
        downCount: 'Live in：',
        HospitalClicks: 'Hospital clicks',
        Share: 'Share',
        Favorite: 'Favorite',
        Expand: 'Expand',
        packUp: 'Fold',
        brandRecommendation: 'Brand recommendation',
        yufollow: 'follow',
        yufollowed: 'followed',
        search: 'search',
        ShareExpert: 'Share',
        year: 'year',
        month: 'month',
        day: 'd',
        hour: 'h',
        minute: 'm',
        second: 's',
        amTitle: 'Congratulations followed successful',
        amTime: 'You will receive a text message reminder 5 minutes',
        amTip: 'before the meeting starts',
        amLook: 'Check it now',
        amMsg: 'Check the followed meeting in my > my followed',
        Nolist: 'No more',
        enterMeetings: 'Enter the meeting',
        meetingOver: 'The meeting has ended',
        playbackIsInEditing: 'Playback is being edited',
        tryAgain: 'Please try again later',
        noinvited: `Your account is not invited, Can't enter the meeting！`,
        nodatatip: 'Join other studios for more live broadcasts',
        Inprogress: 'In progress',
        otherLook: 'Watch other venues',
        otherLookLive: 'Return to the venue and watch other live broadcasts',
        loginWechat: 'Please login at Wechat',
        Getit: 'Get it',
        SwitchAccount: 'Switch account',
        SelectedSuccessfully: 'Selected Successfully',
        CancelFavorite: 'Cancel Favorite',
        Checkhere: 'Check here',
        Meetinglist: 'Meeting list',
        ChangeAccount: 'Replace Account',
        Moreroom: 'More room',
        endSessionTip: 'The current schedule has ended. Do you want to return to the venue to watch other live broadcasts'
    },
    shareCard: {
        loadingText: 'Loading, Please wait',
        retryTip: 'Network error, please click retry',
        savePictureOne: 'Save the photo',
        savePictureTwo: 'scan the QR code to',
        savePictureThree: 'watch the conference',
        schedule: 'Program',
        Introduction: 'Introduction',
        yipu: 'Docbook',
        viewDetails: 'view details',
        shareTipPc: 'Wechat scan and share friends',
        shareTip: 'Press the poster and save to the album share friends ',
        WeChat: 'WeChat',
        WechatMoments: 'Wechat moment',
        noData: ' No content',
        noDataText: 'Please watch other content',
        noExpert: 'No expert information available',
        shareBtn: 'Share Friends',
        FocusOn: 'Follow',
        followed: 'followed',
        Personalcenter: 'personal page',
        meetingPlace: 'studio',
        task: 'Task',
        enterMeeting: 'Join the session',
        noSchedule: 'Agenda coming soon',
        scheduleEmptyTip: 'click to watch the live',
        DateList: 'Agenda list',
        Date: 'Date',
        all: 'Total',
        confirm: 'confirm',
        interactionLive: 'interaction + Live',
        interaction: 'interaction',
        homeShareTip: "I'm watching wonderful content recommend it to you",
        selectYear: 'select year',
        selectMonth: 'select month',
        Livestudio: 'Live studio',
        Livestudiolive: 'Live studio + live',
        updating: 'Updating'
    },
    comment: {
        reply: 'Reply',
        tipoff: 'Tip-off',
        cancel: 'Cancel',
        sending: 'Sending',
        send: 'Send',
        commentPlaceholder: 'post your message',
        Topping: 'Top',
        copy: 'Copy',
        delected: 'delected',
        noComment: 'No message',
        noCommentText: 'Leave your message to interact with experts',
        delectedTip: 'You are sure you want to delete this comment?',
        determine: 'ok',
    },
    checkIn: {
        noNeed: 'Not to sign in',
        checkInSm: 'Sign-in instruction',
        tgCheckInMsg: 'Sign in',
        no: 'No',
        yes: 'Yes',
        checkInTitle: 'Sign in information',
        phone: 'Check in mobile  number',
        phonePlaceholder: 'Enter the mobile number',
        department: 'Department',
        departmentPlaceholder: 'Enter department ',
        productGroup: 'Product group',
        productGroupPlaceholder: 'Enter product group ',
        Company: 'Company',
        CompanyPlaceholder: 'Enter company name',
        RealName: 'Real name',
        RealNamePlaceholder: 'Enter real name',
        email: 'Email',
        emailPlaceholder: 'Enter email',
        hospital: 'Hospital',
        hospitalTip: 'Select hospital',
        det: 'Specialty',
        detPlaceholder: 'Select specialty',
        titel: 'Title',
        titlePlaceholder: 'Select professional title',
        identity: 'Identity',
        identityPlaceholder: 'Choose your identity',
        medicalProfessionals: 'Are you a medical professional',
        getPhone: 'Agree that the organizer can obtain the mobile phone number',
        getSigninInfo: 'authorize the organiser to obtain your information',
        identityVerification: 'Identity verification',
        IdCard: 'Upload your work certificate',
        chooseOne: 'Choose one of them',
        qualificationCertificate: `Doctor's Qualification Certificate No`,
        badge: 'Hospital badge',
        qcPlaceholder: `Please enter your doctor's Qualification Certificate No`,
        Example: 'Example',
        ExampleTip: 'The photo should be clear and legible',
        submit: 'Submit',
        doctorUser: 'Medical professional user',
        otherUsers: 'Other user',
        enterpriseUsers: 'Industry user',
        identityTitle: 'User check-in identity',
        agree: 'Agree',
        checkInTip: 'Submission is consent',
        ThenumberofIDcard: 'The number of ID card',
        checkInTips: 'This meeting is only for medical professionals. The materials are not used for any commercial purpose',
        autinNopass: 'The authentication information you submitted has not passed the review, please check and submit it again',
        doctorTip: 'Sorry, the current meeting is only available for doctors',
        enterprisesTip: 'Sorry, the current meeting is only available for enterprises',
        underReview: 'The information you submitted is under review. Please wait for SMS notice.',
        idCardFrontBlankTip: 'The front of ID card cannot be blank',
        idCardBackBlankTip: 'The back of ID card cannot be blank',
        idCardNumberBlankTip: 'The number of ID card cannot be blank',
        certificateNumberBlankTip: 'The number of Physician Qualification Certificate cannot be blank',
        certificateBlankTip: 'The Practicing Certificate cannot be blank',
        practicingCertificate: 'Practicing Certificate',
        upload: 'Click to upload',
        practicingCertificatePhoto: 'Photo in Practicing Certificate',
        uploadIdcardFrontBack: 'Upload both sides of id card',
        idCardFront: 'Front of id card',
        idCardBack: 'Back of id card',
        identityInformation: 'Identity information',
        legibleText: 'Upload photos with legible text',
        reupload: 'reupload',
        ThenumberofIDcard1: 'The number of ID card',
    },
    Player: {
        errorLoading: 'Load Fail, Click Retry',
        backLiveing: 'Return to live broadcast',
        prevSection: 'Next session',
        nextSection: 'Previous session',
        serversDown: 'Servers down, Please try later',
        teaBreak: 'Tea break, Coming soon',
        netWorkError: 'Please check your network configuration'
    },
    response: {
        serverError: 'server error',
        noPermission: 'No permission',
        ParameterError: 'Parameter error',
        NoData: ' No Data',
        AuthenticationError: 'Authentication error',
        TokenisInvalid: 'Token is invalid',
        MeetingNotExist: 'Meeting ID does not exist',
        noPermission: 'You do not have permission to access this meeting',
        CommentNotExist: 'Comment ID does not exist',
        ProgramNotExist: 'Program ID does not exist',
        ParametersMissing: 'Parameters are missing',
        FailedLike: 'Failed to like',
        Failedliked: 'liked',
        Failedfavorited: 'favorited',
        Failedfavorit: 'Fail',
        TheMeetingNotfavorited: 'The meeting is not favorited',
        Alreadyfollowedothers: ' Already followed, find others',
        CommentFail: 'Comment Fail',
        TheCommentCannompty: 'The comment cannot be empty',
        wordsLimit: 'content exceeds the max words limit',
        refollowAgain: ' followed successfully, Please do not re-follow again',
        followFail: 'Fail',
        appointmentNotSet: 'Pre meeting appointment is not set',
        FailToCancel: ' Fail to cancel',
        correctMobileNumber: 'Please enter the correct mobile number',
        verificationCodeFailed: 'The verification code failed',
        VerificationCodeError: 'Verification code error',
        namepassword: 'User name or password incorrect',
        forbidden: 'his user is forbidden',
        NoSignIn: 'No sign in',
        informationMissing: 'Information missing, please completed and sign in',
        VerificationCodeIncorrect: 'Verification code incorrect',
        MaxWords: 'Max 10 words',
        checkName: 'Please check your name',
        emailIncorrects: 'The email is incorrect',
        IDIncorrects: 'ID format is incorrect'
    },
    doctorMain: {
        live: 'live',
        Education: 'Education',
        Article: 'Article',
        Course: 'Course',
        operation: 'Operation',
        all: 'All'
    }
}