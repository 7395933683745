export default {
    account: '请输入账号',
    phone: '请输入手机号',
    password: '请输入密码',
    code: '请输入短信验证码',
    leftTitle: '手机验证码登录',
    rightTitle: '账号登录',
    loginSubmit: '登录',
    loginTip: '未注册的手机号系统将为您自动注册，密码将以短信形式发送到您的手机上',
    invitedTip: '您登录的手机号非邀约讲课专家，请更换其它账号登录',
    thirdParty: '第三方账号登录',
    invitedcommittee: '如有问题，请联系会务组',
    countryregion: '选择所属国家或地区',
    genghuan: '更换手机',
    forget: '忘记密码',
    dahui: '大会官网',
    downLoadTip: '下载APP，体验更多....',
    bind: '绑定',
    meetingDetail: {
        home: '主页',
        people: '人',
        invite: '邀请',
        noRanking: '暂无排名',
        invitedPeople: '您已成功邀请',
        // keepgoing: '马上向您的朋友发起邀请',
        instructions: '说明',
        ReturnToList: '返回列表',
        start: '开始',
        viewingVolume: '观看量',
        PeopleFollowed: '预约人次',
        more: '更多',
        time: '时间',
        likeText: '点赞',
        likeedText: '已点赞',
        lectureTopic: '讲题',
        expertText: '专家',
        noticeText: '预告',
        liveText: '直播',
        playText: '播放',
        playback: '回放',
        selected: '精选',
        reviewText: '回顾',
        loginPlay: '签到观看',
        Play: '点击播放',
        downCount: '距离会议开始还剩：',
        HospitalClicks: '医院播放量',
        Share: '分享',
        Favorite: '收藏',
        Expand: '展开',
        packUp: '收起',
        brandRecommendation: '品牌推荐',
        ShareExpert: '分享专家',
        yufollow: '预约',
        yufollowed: '已预约',
        search: '搜索',
        year: '年',
        month: '月',
        day: '天',
        hour: '时',
        minute: '分',
        second: '秒',
        amTitle: '恭喜您，预约成功',
        amTime: '您将在会议开始前5分钟',
        amTip: '收到短息消息提醒',
        amLook: '立即查看',
        amMsg: '已预约会议在我的>我的预约中查看',
        Nolist: '没有更多了',
        meetingOver: '会议已结束',
        playbackIsInEditing: '回放正在剪辑中',
        tryAgain: '请稍后再试',
        enterMeetings: '进入会议',
        noinvited: '您登录的账号未被邀约，请更换其它账号登录观看！',
        nodatatip: '进入其他会场观看精彩直播',
        Inprogress: '正在进行中',
        otherLook: '观看其他会场',
        otherLookLive: '返回会场，观看其他直播',
        loginWechat: '请在微信环境打开',
        Getit: '我知道了',
        SwitchAccount: '切换账号',
        SelectedSuccessfully: '收藏成功',
        CancelFavorite: '取消收藏',
        Checkhere: '来了',
        Meetinglist: '会议列表',
        ChangeAccount: '更换账号',
        Moreroom: '更多会场',
        endSessionTip: '当前日程直播已结束，是否返回会场观看其他直播'
    },
    shareCard: {
        loadingText: '正在拼命加载中，请您稍后',
        interactive: '交互',
        retryTip: '网络出小差了，请点击重试',
        savePictureOne: '保存本图片',
        savePictureTwo: '用微信识别或手机扫描二维码',
        savePictureThree: '观看精彩内容',
        schedule: '日程',
        Introduction: '简介',
        yipu: '医谱',
        viewDetails: '查看详情',
        shareTipPc: '微信扫一扫分享好友',
        shareTip: '长按海报保存到相册，分享好友',
        WeChat: '微信',
        WechatMoments: '朋友圈',
        noData: '暂无相关内容',
        noDataText: '您可以观看其他精彩内容',
        noExpert: '暂无专家信息',
        shareBtn: '分享好友',
        FocusOn: '+订阅',
        followed: '已订阅',
        Personalcenter: '个人主页',
        meetingPlace: '会场',
        task: '任务',
        enterMeeting: '进入会场',
        noSchedule: '暂无日程列表',
        scheduleEmptyTip: '点击进入会场观看精彩直播',
        DateList: '日期列表',
        Date: '日期',
        all: '全部',
        confirm: '确认',
        // footerBannerTip: '请在we'
        interactionLive: '直播+交互',
        interaction: '交互',
        homeShareTip: '我在观看精彩内容，推荐给你',
        selectYear: '选择年份',
        selectMonth: '选择月份',
        Livestudio: '直播间',
        Livestudiolive: '直播间+直播',
        updating: '更新中'
    },
    comment: {
        reply: '回复',
        tipoff: '举报',
        cancel: '取消',
        sending: '发送中',
        send: '发送',
        commentPlaceholder: '请输入您的留言',
        Topping: '置顶',
        copy: '复制',
        delected: '删除',
        noComment: '暂无留言',
        noCommentText: '输入您的留言参与专家互动',
        delectedTip: '您确定要删除此评论?',
        determine: '确定',

    },
    checkIn: {
        noNeed: '不提供',
        checkInSm: '签到说明',
        tgCheckInMsg: '提供签到信息',
        no: '否',
        yes: '是',
        checkInTitle: '签到信息',
        phone: '签到手机号',
        phonePlaceholder: '请输入签到手机号',
        department: '所在部门',
        departmentPlaceholder: '请输入您的所在部门',
        productGroup: '产品组',
        productGroupPlaceholder: '请输入您的产品组',
        Company: '所在单位',
        CompanyPlaceholder: '请输入您的所在单位',
        RealName: '真实姓名',
        RealNamePlaceholder: '请输入您的姓名',
        email: '邮箱',
        emailPlaceholder: '请输入您的联系邮箱',
        hospital: '所在医院',
        hospitalTip: '选择您的医院',
        det: '所在科室',
        detPlaceholder: '选择您的科室',
        titel: '您的职称',
        titlePlaceholder: '选择您的职称',
        identity: '身份',
        identityPlaceholder: '选择您的身份',
        medicalProfessionals: '您是否为医疗专业人士',
        getPhone: '同意主办方获取手机号',
        getSigninInfo: '是否同意主办方获取您的签到信息',
        identityVerification: '身份核实',
        IdCard: '上传您的工作证件',
        chooseOne: '任选其一',
        qualificationCertificate: '医师资格证编号',
        badge: '医院胸牌',
        qcPlaceholder: '请输入您的医师资格证编号',
        Example: '示例',
        ExampleTip: '照片文字须要清晰可辨',
        submit: '提交',
        doctorUser: '医生用户',
        otherUsers: '其他用户',
        enterpriseUsers: '企业用户',
        identityTitle: '用户签到身份',
        agree: '同意',
        checkInTip: '提交即同意',
        checkInTips: '此会议仅供医疗专业人士观看，其他人士请勿进入，资料不作任何商业用途',
        autinNopass: '您提交的认证信息未通过审核，请检查无误后重新提交认证信息',
        doctorTip: '抱歉，当前会议只针对医生身份用户开放',
        enterprisesTip: '抱歉，当前会议只针对企业身份用户开放',
        underReview: '您提交的信息已在审核中，请耐心等待短信通知！',
        idCardFrontBlankTip: '身份证正面不能为空',
        idCardBackBlankTip: '身份证反面不能为空',
        idCardNumberBlankTip: '身份证号码不能为空',
        ThenumberofIDcard: '身份证号码',
        certificateNumberBlankTip: '医师资格证编号不能为空',
        certificateBlankTip: '医师资格证编号不能为空',
        practicingCertificate: '执业证',
        upload: '点击上传',
        practicingCertificatePhoto: '执业证书内页照片',
        uploadIdcardFrontBack: '上传身份证正反面',
        idCardFront: '身份证正面',
        idCardBack: '身份证反面',
        identityInformation: '身份认证信息',
        legibleText: '上传照片文字须清晰可辨',
        reupload: '重新上传',

        ThenumberofIDcard1: '请输入身份证号码',
    },
    Player: {
        errorLoading: '加载失败 点击重试',
        backLiveing: '返回直播',
        prevSection: '上一节',
        nextSection: '下一节',
        serversDown: '服务出小差了，请重试',
        teaBreak: '茶歇中，请稍候',
        netWorkError: '当前网络未链接，请检查网络设置'
    },
    response: {
        serverError: '服务器错误',
        noPermission: '没有权限',
        ParameterError: '参数错误',
        NoData: '数据不存在',
        AuthenticationError: '认证错误',
        TokenisInvalid: 'TOKEN无效',
        MeetingNotExist: '会议不存在',
        noPermission: '您无权访问此会议',
        CommentNotExist: '评论ID不存在',
        ProgramNotExist: '日程ID不存在',
        ParametersMissing: '参数缺失',
        FailedLike: '点赞失败',
        Failedliked: '您已赞过',
        Failedfavorited: '已收藏过',
        Failedfavorit: '收藏失败',
        TheMeetingNotfavorited: '该会议未收藏',
        Alreadyfollowedothers: '您已关注过该专家，换一个试试吧',
        CommentFail: '评论失败',
        TheCommentCannompty: '评论内容不能为空',
        wordsLimit: '评论内容超出最大字数限制',
        refollowAgain: '已经预约成功，请勿重复操作',
        followFail: '设置会前预约失败',
        appointmentNotSet: '未设置会前预约',
        FailToCancel: '取消预约失败',
        correctMobileNumber: '请输入正确的手机号',
        verificationCodeFailed: '验证码发送频繁',
        VerificationCodeError: '验证码错误',
        namepassword: '用户名或密码错误',
        forbidden: '此用户禁止登录此系统',
        NoSignIn: '该会议无需签到',
        informationMissing: '您的信息不全，请补全后在签到',
        VerificationCodeIncorrect: '您的验证码错误',
        MaxWords: '真实姓名最多10个字',
        checkName: '请核对您的姓名',
        emailIncorrects: '邮箱不正确',
        IDIncorrects: '身份证格式不正确'
    },
    doctorMain: {
        live: '会议',
        Education: '患教',
        Article: '文章',
        Course: '课程',
        operation: '手术',
        all: '动态'
    }
}