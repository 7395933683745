export default [{
        path: '/withdrawal',
        name: 'withdrawal',
        meta: {
            title: '领取课酬'
        },
        component: () => import('@/views/withdrawal/withdrawal')

    },
    {
        path: '/bill',
        name: 'bill',
        meta: {
            title: '我的账单'
        },
        component: () => import('@/views/withdrawal/bill')

    },
    {
        path: '/billDetail',
        name: 'billDetail',
        meta: {
            title: '账单详情'
        },
        component: () => import('@/views/withdrawal/billDetail')

    },
    {
        path: '/withdrawalInfo',
        name: 'withdrawalInfo',
        meta: {
            title: '编辑资料'
        },
        component: () => import('@/views/withdrawal/withdrawalInfo')
    }
]