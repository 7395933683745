export default {
  showLoading: true, // loading
  showTabbar: false,
  tabbarSelectedIndex: 0,
  tabbarCollege: 0,
  showLogoutAlert: false,
  isInApp: navigator.userAgent.indexOf("YPAPP") !== -1, // 是否在 APP 中 navigator.userAgent.indexOf('YPAPP') != -1
  isInPcApp: navigator.userAgent.indexOf("YPPCAPP") !== -1, // 是否在 PC APP 中 navigator.userAgent.indexOf('YPPCAPP') != -1
  isAppHomePage: !/pageIgnore/.test(navigator.userAgent),
  showHeaderAppTips: true, // 强制不显示
  isMain: !/heartm.docbook.com.cn/.test(location.href),
  contentLeft: 0,
  contentRight: 0,
  contentHeight: 0,
  canGoback: false,
  aliveList: [],
  homeScrollTop: 0,
  appMessage: null,
  ifReviewTime: "1",
  isAppResume: false, //app是否处于当前
  isDeptUnion: false, //是否选中科联体
  verticallHung: false,
  deptUnionId: '',// 科联体id
  sellerCode: '',// 邀请码
  singleSubjectInfo: null, // 来自单一学科页面的信息
};