<template>
    <div class="course-item" @click="$emit('item-click', item)">
        <div
            class="course-pic"
            :style="{
                background: '#000 url(' + backImg + ') no-repeat center/contain'
            }"
        >
            <!-- 视频类型 1-回放，2-精选 -->
            <!-- {{ ifReviewTime }} -->
            <template v-if="ifReviewTime == 1">
                <!-- <p class="course-type free_price" v-if="item.is_free == 1">免费</p> -->
                <p class="course-type" v-if="item.is_free != 1">
                    {{ item.scribing_price ? '限时优惠' : '' }}
                    ￥{{ item.price }}
                </p>
            </template>
            <div class="tip" v-if="item.is_operation_review == 1">
                <img src="@images/operation/tip.png" alt="" />
            </div>
        </div>
        <div class="course-text">
            <span :class="['course-item-text', 'van-multi-ellipsis--l3', 'item-text-one-style']">{{
                item.title
            }}</span>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from '@utils/vuex'
import noCover from '@images/common/coursenone.png'

export default {
    filters: {
        NumFilter(val) {
            if (Number(val) > 10000) {
                return (val / 10000).toFixed(1) + '万'
            } else {
                return val
            }
        }
    },
    name: 'MeetingItem',
    components: {},
    props: {
        item: {
            type: Object,
            required: true,
            default: {}
        }
    },
    data() {
        return {
            noCover
        }
    },
    computed: {
        ...mapGetters(['ifReviewTime']),
        backImg() {
            return this.item.cover ? this.item.cover : this.noCover
        }
    },
    mounted() {}
}
</script>

<style scoped lang="scss">
@import '@styles/variables.scss';

.course-item {
    width: 5.55rem;
    height: 100%;
    margin: 0.21rem 0 0 0.43rem;
    cursor: pointer;
    padding-top: 0.43rem;
}
.tip {
    position: absolute;
    top: 0.18rem;
    left: 0.18rem;
    img {
        height: 0.78rem;
    }
}
.course-pic {
    position: relative;
    flex: 1;
    width: 5.55rem;
    height: 3.11rem;
    background-size: 100%;
    box-shadow: 0rem 0.12rem 0.47rem 0rem rgba(136, 136, 136, 0.4);
    border-radius: 0.09rem;
    position: relative;
    display: flex;
}

.course-pic-bottom {
    width: 100%;
    height: 1.19rem;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
    border-radius: 0rem 0rem 0.12rem 0.12rem;
    position: absolute;
    bottom: 0;
    padding: 0 0.1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .bottom_left {
        display: flex;
        align-items: center;
        p {
            display: flex;
            align-items: center;
            img {
                width: 0.53rem;
                height: 0.53rem;
                margin-right: 0.1rem;
            }
            span {
                font-size: $fsmd;
                color: #fff;
            }
        }
        p:nth-child(1) {
            margin-right: 0.3rem;
        }
    }
    .bottom_right {
        font-size: $fsmd;
        color: #fff;
    }
}

.course-type {
    position: absolute;
    top: 0.296rem;
    right: 0.296rem;
    font-weight: 400;
    font-size: 0.5rem;
    padding: 0 0.1rem;
    background: #ffd077;
    color: #8d400c;
    border-radius: 4px;
}
.free_price {
    background: rgba(249, 83, 82, 0.8);
    color: #fff;
}

.course-item-playing {
    width: 0.9rem;
    height: 0.5rem;
    line-height: 1.19rem;
    position: absolute;
    left: 0.3rem;
}
.course-text {
    width: 5.55rem;
    height: 1.41rem;
    font-size: 0.51rem;
    margin: 0.21rem 0;
}
.course-item-text {
    word-break: break-word;
    font-weight: 400;
    box-sizing: border-box;
    line-height: 0.7rem;
}

.item-text-one-style {
    color: #222222;
}
.item-text-two-style {
    color: #222222;
}
</style>
