export default [
  {
    path: '/productHall',
    name: 'productHall',
    meta: {
        title: '线上展厅'
    },
    component: () => import('@/views/productHall')
  },
  {
    path: '/bigMeetingHall',
    name: 'bigMeetingHall',
    meta: {
        title: '线上展厅'
    },
    component: () => import('@/views/bigMeetingHall')
  },
  {
    path: '/hallDetail',
    name: 'hallDetail',
    meta: {
        title: '线上展厅'
    },
    component: () => import('@/views/hallDetail')
  }
]