export default {
  setTabUnreadMessgeGroup(state, { index, count }) {
    state.tabUnreadMessageGroup[index] += count
  },
  initIMUnreadMessageGroup(state, imGroupIds = new Set()) {
    state.unreadMessageGroup = imGroupIds
  },
  setIMUnreadMessageGroup(state, imGroupId) {
    if (state.unreadMessageGroup.has(imGroupId)) {
      return
    }
    const n = [...state.unreadMessageGroup]
    n.push(imGroupId)
    state.unreadMessageGroup = new Set(n)
  },
  deleteIMUnreadMessageGroup(state, imGroupId) {
    if (state.unreadMessageGroup.has(imGroupId)) {
      state.unreadMessageGroup.delete(imGroupId)
      state.unreadMessageGroup = new Set([...state.unreadMessageGroup])
    }
  },
  setConclusionNotice(state, data) {
    state.hasConclusionNotice = data
  },
  setSwitchTab(state, data) {
    state.switchTab = data
    console.log(state.switchTab, '拿到了吗')
  },
  setIsStcApplication(state, data) {
    state.isStcApplication = data
  },
}
