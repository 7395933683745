<template>
  <div class="home-list-wrap">
    <div class="tip-more">
      <div class="tip">最新资讯</div>
      <div class="more" @click="$emit('handleClick', 4)">
        更多
        <van-icon name="arrow" />
      </div>
    </div>
    <div class="home-list-content-wrap" v-if="data && data.length > 0">
      <div v-for="(item, index) of data" :key="index">
        <div
          class="frist-consulting"
          v-if="index == 0"
          @click="itemClick(item)"
        >
          <div class="frist-img">
            <img :src="cover" alt />
            <span class="time">{{ item.own_time | formatDate }}</span>
          </div>
          <div class="frist-title van-multi-ellipsis--l2">{{ item.title }}</div>
          <!-- <div class="frist-title-line"></div> -->
        </div>
        <home-consulting-item
          v-else
          :item="item"
          @item-click="itemClick"
        ></home-consulting-item>
      </div>
    </div>
  </div>
</template>
<script>
import HomeConsultingItem from '@components/MeetingListItem/HomeConsultingItem'
import { mapState } from '@utils/vuex'

export default {
  components: {
    HomeConsultingItem
  },
  props: {
    data: {
      type: Array,
      require: true,
      defalut: []
    }
  },
  data() {
    return {}
  },
  computed: {
    cover() {
      return this.data[0].cover.split('?')[0]
    }
  },
  filters: {
    formatDate(val) {
      if (!val) return false
      let date = val.split(' ')[0].split('/')
      return `${date[1]}月${date[2]}日 ${val.split(' ')[1]}`
    }
  },
  created() { },
  mounted() { },
  methods: {
    itemClick(item) {
      this.$store.commit('setHomeScrollTop', document.querySelector('.scroll-main').scrollTop)
      this.$store.commit('setKeepAlive', ['home'])
      const { information_id } = item
      this.$router.push({
        path: 'homeConsultingDetail',
        query: {
          information_id
        }
      })
    }
  }
}
</script>
<style scoped src="@styles/other/home/homeHotwords.css"></style>
<style lang="scss" scoped>
@import "@styles/variables.scss";
.home-list-wrap {
  margin-bottom: 0.43rem;
}
.meet_list {
  display: flex;
  min-height: 3.97rem;
  width: 100%;
  padding: 0rem 0.53rem;
  box-sizing: border-box;
  flex-direction: column;
  background-color: #fff;
  // border-bottom: 1px solid #ececec;
  cursor: pointer;
}
.home-list-content-wrap {
  width: 94%;
  margin: 0 auto;
  // padding-bottom: 0.69rem;
  background: #fff;
  box-shadow: 0rem 0.18rem 0.37rem 0rem rgba(214, 214, 214, 0.2);
  border-radius: 0.37rem;

  .frist-consulting {
    padding: 0.46rem 0.46rem 0rem 0.46rem;
    .frist-img {
      position: relative;
      width: 100%;
      // height: 7.04rem;

      img {
        width: 100%;
        height: 100%;
        border-radius: 0.23rem;
      }
      .time {
        position: absolute;
        left: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: end;
        width: 100%;
        height: 1.39rem;
        padding: 0 0.43rem;
        background: linear-gradient(
          180deg,
          rgba(22, 43, 64, 0) 0%,
          #162b40 100%
        );
        font-size: 0.51rem;
        color: #ffffff;
      }
    }
    .frist-title {
      position: relative;
      margin: 0.46rem 0;
      box-sizing: border-box;
      font-size: 0.68rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #222222;
      // border-bottom: 0.02rem solid #ececec;
    }
    .frist-title-line {
      width: 100%;
      height: 0.02rem;
      background: #ececec;
    }
  }
}
</style>
