<template>
    <div class="home-list-wrap">
        <div class="tip-more">
            <div class="tip">推荐课程</div>
            <div class="more" @click="$emit('handleClick', 2)">更多<van-icon name="arrow" /></div>
        </div>
        <div class="home-list-content-wrap video" style="padding-top: 0">
            <video-course
                v-for="(item, index) of data"
                :key="index"
                :item="item"
                @item-click="itemClick"
            ></video-course>
        </div>
    </div>
</template>
<script>
import { mapState, mapGetters } from '@utils/vuex'
import VideoCourse from '@components/MeetingListItem/VideoCourse'

export default {
    components: {
        VideoCourse
    },
    props: {
        data: {
            type: Array,
            require: true,
            defalut: []
        }
    },
    data() {
        return {}
    },
    computed: {
        ...mapState('home', []),
        ...mapGetters(['ifReviewTime', 'contentWidth'])
    },
    watch: {},
    created() {},
    mounted() {},
    methods: {
        itemClick(item) {
            this.$store.commit('setHomeScrollTop', document.querySelector('.scroll-main').scrollTop)
            this.$store.commit('setKeepAlive', ['home'])
            if (item.course_type == 1) {
                this.$router.push({
                    path: 'doctorSharePage',
                    query: {
                        production_id: item.production_id
                    }
                })
            } else {
                this.$router.push({
                    path: 'doctorSeriesCourses',
                    query: {
                        production_id: item.production_id
                    }
                })
            }
        }
    }
}
</script>
<style scoped src="@styles/other/home/homeHotwords.css"></style>
<style lang="scss" scoped>
@import '@styles/variables.scss';

.swiper-container {
    margin-left: 0.53rem;
}
.swiper-container {
    .swiper-container-free-mode {
        .swiper-wrapper {
            margin: 0 -0.53rem;
        }
    }
}
.home-list-content-wrap {
    display: flex;
    flex: 1;
    overflow-x: auto;
    overflow-y: hidden;
}
.series {
    width: auto;
    // margin: 0 0rem 0 0.53rem;
    padding: 1.5rem 0rem 0rem 0;
}
.course-item-text {
    font-size: 0.51rem;
    margin-top: 0.21rem;
    word-break: break-all;
    font-weight: 400;
    box-sizing: border-box;
    line-height: 0.7rem;
}
.series_img {
    margin: 0 0 0 0.53rem;
    p {
        width: 5.54rem;
        height: 1.41rem;
    }
    img {
        width: 5.53rem;
        height: 3.11rem;
    }
}
.video_img {
    margin: 0 0 0 0.53rem;
    p {
        width: 7.2rem;
        height: 1.41rem;
    }
    img {
        width: 7.18rem;
        height: 4.05rem;
    }
}
.video {
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 0.53rem;
    margin-bottom: 0.21rem;
}
.home-series-wrap {
    display: flex;
    flex-direction: column;
    flex: 1;
    .course-more {
        display: flex;
        margin-top: 0.21rem;
        width: 1.32rem;
        height: 3.11rem;
        background: #f1f1f5;
        border-radius: 0.09rem;
        cursor: pointer;
        margin-right: 0.55rem;
        span {
            display: inline-block;
            margin: auto;
            width: 1.32rem;
            height: 0.7rem;
            font-size: 0.51rem;
            font-weight: 400;
            color: #888888;
            text-align: center;
        }
    }
}
.course-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
::v-deep {
    .van-list__error-text,
    .van-list__finished-text,
    .van-list__loading {
        width: 100%;
    }
}
.series-top {
    margin-top: 0.21rem;
    background: #fff;
    // padding-left: 3%;
    box-sizing: border-box;
    .expert-title {
        padding: 0.4rem 0.49rem 0.4rem 0.51rem;
        height: 1.49rem;
        display: flex;
        font-weight: 400;
        justify-content: space-between;
        align-items: center;
        span:nth-child(1) {
            font-size: 0.68rem;
            color: #222222;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 500;
        }
        span:nth-child(2) {
            font-size: 0.51rem;
            cursor: pointer;
            color: #888888;
            display: flex;
            align-items: center;
        }
    }
}
</style>
