export default [
  {
    path: "/curriculum",
    name: "curriculum",
    meta: {
      title: "课程",
      keepAlive: true,
    },
    component: () => import("@/views/curriculum/index.vue"),
  }
];
