export default {
    setPlatformInfo(state, info) {
        if (!state.platformInfo) state.platformInfo = info
        else state.platformInfo = {...state.platformInfo, ...info}
    },
    setPlatformUrl(state, url) {
        state.platformUrl = url
    },
    setCanGoback(state, canGoback) {
        state.pfCanGoback = canGoback
    } 
}