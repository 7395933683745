

export default [
  {
    path: '/stCommonwealth',
    name: 'stCommonwealth',
    meta: {
      title: '科联体'
    },
    component: () => import('@/views/stCommonwealth')
  },
  {
    path: '/departmentMember',
    name: 'departmentMember',
    meta: {
      title: '科室会员'
    },
    component: () => import('@/views/departmentMember')
  },
  {
    path: '/notice',
    name: 'notice',
    meta: {
      title: '公告'
    },
    component: () => import('@/views/notice')
  },
  {
    path: '/noticeDetail',
    name: 'noticeDetail',
    meta: {
      title: '公告'
    },
    component: () => import('@/views/noticeDetail')
  },
  {
    path: '/stCommonwealthFrom',
    name: 'stCommonwealthFrom',
    meta: {
      title: '科联体申请单'
    },
    component: () => import('@/views/stCommonwealthFrom')
  },
  {
    path: '/doctorMember',
    name: 'doctorMember',
    meta: {
      title: '医生会员'
    },
    component: () => import('@/views/doctorMember')
  },
  {
    path: '/addMembers',
    name: 'addMembers',
    meta: {
      title: '添加成员'
    },
    component: () => import('@/views/addMembers')
  },
  {
    path: '/membershipApplication',
    name: 'membershipApplication',
    meta: {
      title: '申请会员'
    },
    component: () => import('@/views/membershipApplication')
  }, {
    path: '/appointmentMeeting',
    name: 'appointmentMeeting',
    meta: {
      title: '预约会议'
    },
    component: () => import('@/views/appointmentMeeting')
  }
]