
export default [
    {
      path: '/download',
      name: 'download',
      meta: {
          title: '下载页'
      },
      component: () => import('@/views/download')
    },
  ]