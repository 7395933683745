import Request from "@request";
import api from "@api";
import { Toast } from "vant";
import os from "@utils/os.js";
export default {
  getWebComments({ dispatch, commit, state, rootState }, params) {
    if (!params) params = {};
    const is_load_more = params.is_load_more;
    return new Promise((resolve, reject) => {
      Request.post(api.comment.getWebcomments, params).then((res) => {
        const { data } = res;
        let commentList = is_load_more
          ? [...state.comment_list, ...data.data]
          : data.data;
        commit("setCommentList", commentList);
        // commit('setTopCommentList', commentList)
        resolve(res);
      });
    });
  },
  deleteComment({ dispatch, commit, state, rootState }, params) {
    return new Promise((resolve, reject) => {
      Request.post(api.comment.deleteComment,params).then(res => {
        resolve(res)
      })
    })
  },
  setWebComment({ dispatch, commit, state, rootState }, params) {
    // 增加三方对接参数
    if (rootState.platform.platformInfo) {
      const { platform, uid } = rootState.platform.platformInfo
      params['enterprisePlat'] = platform
      params['uid'] = uid
    }
    return new Promise((resolve, reject) => {
      Request.post(api.comment.setWebComment,params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  gettopcomments({ dispatch, commit, state, rootState }, params) {
    return new Promise((resolve, reject) => {
      Request.post(api.comment.gettopcomments,params).then(res => {
        let { data } = res
        commit('setTopCommentList', data.data)
        resolve(res)
      })
    })
  },
  doctorproduction({ dispatch, commit, state, rootState }, params) {
    // 增加三方对接参数
    // if (rootState.platform.platformInfo) {
    //   const key = 'Abbott'
    //   params['uid'] = rootState.platform.platformInfo[key]
    //   params['enterprisePlat'] = key
    // }
    return new Promise((resolve, reject) => {
      Request.post(api.common.doctorproduction,params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
};
