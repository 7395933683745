import os from '@utils/os'
import utils from '@utils'
import saasRequest from '@request/saasRequest'
import Request from '@request'
import host from '@request/host'
import api from '@api'
import {
    NativeMessageData,
    NativeMessagePush,
} from '@utils/nativeBridge/nativeMessage'
import store from '@/store'
import router from '@/router'
export default class NativeBridge {
    static actionSupportMap = {
        share: 'share',
        pushNative: 'pushNative',
        pushWeb: 'pushWeb',
        notice: 'notice',
        getData: 'getData',
        pay: 'pay',
        getStorage: 'getStorage',
        resourceCache: 'resourceCache',
    }

    constructor() {
        this.os = 'unknow'
        if (os.isAndroid()) {
            this.os = 'android'
        } else if (os.isIOS()) {
            this.os = 'ios'
        } else if (os.getDevice() === 'mac') {
            this.os = 'mac'
        }

        this.callbackCache = undefined
    }

    isSupportAction(actionName) {
        return !!NativeBridge.actionSupportMap[actionName]
    }

    sendMessage(message) {
        if (
            !(store.getters.isInApp || this.os === 'mac') ||
            !message.actionName
        )
            return
        if (!message.extend) console.error('数据对象不正确')
        if (!this.isSupportAction(message.actionName))
            console.error('不支持的action')
        if (message.actionName === 'pushNative') {
            const { params = {}, pageName } = message.params.data
            if (!pageName || (pageName === 'newWeb' && !params.url)) return
        }
        try {
            // if (message.actionName == 'pushNative') {
            //     message.extend({
            //         userInfo: {
            //             ...store.getters.userInfo,
            //             ...store.state.user.tokenInfo
            //         },
            //     })
            // }
            if (message.callback) {
                this.callbackCache = message.callback
                delete message['callback']
            }
            let jsonStr = JSON.stringify(message)
            // if (os.isPC()) {
            //     console.log('PC阻止发送消息', message)
            //     return
            // }
            if (this.os === 'ios' || this.os === 'mac') {
                if (message.actionName === 'getData') return window.prompt(jsonStr)
                return window.webkit.messageHandlers.js2native.postMessage(jsonStr)
            } else if (this.os === 'android') {
                return window.android.js2native(jsonStr)
            } else {
                console.error('发送原生消息失败', message)
            }
        } catch (e) {
            console.log('函数内错误', e)
        }
    }

    handleMessage(message) {
        if (!(store.getters.isInApp || this.os === 'mac')) return
        try {
            const { actionName } = message
            if (!this.isSupportAction(actionName)) console.error('不支持的action')
            else {
                const { type, params } = message.params
                if (actionName === 'pushWeb') {
                    if (!type) {
                        location.replace(
                            location.origin +
                            '/' +
                            Object.keys(params).reduce((query, key, index) => {
                                query += `${index > 0 ? '&' : '?'}${key}=${params[key]}`
                                return query
                            }, '')
                        )
                        // router.replace({
                        //     path: '/',
                        //     query: params
                        // })
                        return
                    }

                    // 特殊处理
                    if (type === 'shareDeployRegister' && !params.id) {
                        params['id'] = 'eyJpZCI6IjM1In0='
                    }
                    if (type === 'statusVerification') {
                        store.commit('setverticallHung', true)
                    }
                    router.push({
                        path: `/${type}`,
                        query: params,
                    })
                } else if (actionName === 'notice') {
                    if (type === 'appState') {
                        // 把状态存储到store供全局使用
                        let isResume = params.state == 'becomeActive'
                        store.commit('setAppResume', isResume)
                        if (params.state === 'becomeActive') {
                            if (
                                router.currentRoute.matched[0].instances.default
                                    .appRunninginTheBackground
                            )
                                router.currentRoute.matched[0].instances.default.appRunninginTheBackground()
                            /*
                            if (store.getters.isLogin) {
                                store
                                    .dispatch('user/getLoginStatus')
                                    .catch((status) => {
                                        if (status == 401) {
                                            store.dispatch('user/logout')
                                            // vant.Dialog({
                                            //     message: '您当前账号已在其它地方登录，是否重新登录？',
                                            //     showConfirmButton: true,
                                            //     showCancelButton: true,
                                            //     className: 'shofOffLogin',
                                            //     confirmButtonText: '切换帐号',
                                            //     confirmButtonColor: '#0089FF',
                                            //     cancelButtonText: '我知道了',
                                            //     cancelButtonColor: '#888888',
                                            //     overlayStyle: {
                                            //         zIndex: 9999
                                            //     },
                                            //     getContainer: 'body',
                                            // }).then(() => {
                                            //     router.push('/login')
                                            //     store.dispatch('user/logout')
                                            // })
                                            //     .catch(() => {
                                            //         location.reload()
                                            //     })
                                        }
                                    })
                            }
                            */
                        }
                    } else if (type === 'payCallback') {
                        this.callbackCache && this.callbackCache()
                    } else if (type === 'getStorage') {
                        return localStorage.getItem(params.key)
                    } else if (type == 'parseUniversalLinks') {
                        /*
                            // 获取入会链接通知原生
                            console.log(params, 'parseUniversalLinks 获取的链接')
                            let param = {}
                            if (/release-m/.test(params) || /m.docbook.com.cn/.test(params)) {
                                if (utils.getUrlParam(params, 'token')) {
                                    Request.post(api.common.startmeeting, {
                                        code: decodeURIComponent(utils.getUrlParam(params, 'token')),
                                    }).then((res) => {
                                        console.log(
                                            utils.getUrlParam(params, 'token'),
                                            res.data,
                                            '------接口请求之后'
                                        )
                                        const { id } = res.data
                                        nativeBridge.sendMessage(
                                            new NativeMessagePush({
                                                pageName: 'joinMeeting',
                                                params: {
                                                    zoomMeetingId: id,
                                                    autolink: '',
                                                },
                                            })
                                        )
                                    })
                                } else if (utils.getUrlParam(params, 'order_id')) {
                                    Request.post(
                                        `https://${host.env}meeting.docbook.com.cn/api/consultation/invite/joinmeeting`,
                                        {
                                            order_id: utils.getUrlParam(params, 'order_id'),
                                        }
                                    ).then((res) => {
                                        const { meetingID } = res.data
                                        nativeBridge.sendMessage(
                                            new NativeMessagePush({
                                                pageName: 'joinMeeting',
                                                params: {
                                                    zoomMeetingId: meetingID,
                                                },
                                            })
                                        )
                                    })
                                } else {
                                    if (utils.getUrlParam(params, 'id')) {
                                        param.zoomMeetingId = utils.getUrlParam(params, 'id')
                                    }
                                    if (utils.getUrlParam(params, 'uname')) {
                                        param.uname = encodeURIComponent(
                                            utils.getUrlParam(params, 'uname')
                                        )
                                    }
                                    nativeBridge.sendMessage(
                                        new NativeMessagePush({
                                            pageName: 'joinMeeting',
                                            params: param,
                                        })
                                    )
                                }
                            } else {
                                if (utils.getUrlParam(params, 'meeting_id')) {
                                    param.zoomMeetingId = encodeURIComponent(
                                        utils.getUrlParam(params, 'meeting_id')
                                    )
                                }
                                saasRequest
                                    .post('/v1/meeting/urlStartMeeting', {
                                        code: utils.getUrlParam(params, 'code'),
                                    })
                                    .then((res) => {
                                        if (utils.getUrlParam(res.data.join_url, 'sid')) {
                                            param.sid = encodeURIComponent(
                                                utils.getUrlParam(res.data.join_url, 'sid')
                                            )
                                        }
                                        if (utils.getUrlParam(res.data.join_url, 'token')) {
                                            param.token = encodeURIComponent(
                                                utils.getUrlParam(res.data.join_url, 'token')
                                            )
                                        }
                                        console.log(params, param, '------获取的链接------')
                                        nativeBridge.sendMessage(
                                            new NativeMessagePush({
                                                pageName: 'joinMeeting',
                                                params: param,
                                            })
                                        )
                                    })
                            }
                            */
                    } else if (type === 'notification') {
                        /*
                        // 消息推送通知
                        store.commit('setShowTabbar', false)
                        // 跳转内容类型:0=三方链接、1=富文本内容、2=单场会、3=框架会、4=分会场、5=精品课程（单个课程）、6=精品课程（系列课程）、7=医学资讯、8=手术示教、9=医生主页 10=科联体公告 13=协同 14=视频会议推送
                        if (!(params.type == 10 || params.type == 14 || params.type == 13)) {
                            // if (store.getters.isLogin) {
                            store
                                .dispatch('messagePush/updateMessageRecord', {
                                    pushMessageId: params.pushMessageId,
                                    messageRecordStatus: 1,
                                })
                                .then((res) => { })
                            // }

                        }
                        if (!params.type) {
                            nativeBridge.sendMessage(
                                new NativeMessagePush({
                                    pageName: 'newWeb',
                                    params: {
                                        url: params.url,
                                    },
                                })
                            )
                        } else {
                            console.log(params, params.url, '点击条幅跳转', params.type)
                            store.commit('setShowTabbar', false)
                            store.commit('setverticallHung', true)
                            router.push(JSON.parse(params.url))
                        }
                        */
                    } else if (type == 'notificationPermissionsChange') {
                        // 获取推送权限
                        let systemInfo = store.nativeBridge.nativeBridge.sendMessage(
                            new NativeMessageData({
                                type: 'systemInfo',
                            })
                        )
                        let notification_limit = ''
                        if (os.isAndroid()) {
                            notification_limit = JSON.parse(systemInfo).notification_limit
                        }
                        if (os.isIOS()) {
                            notification_limit = JSON.parse(
                                JSON.parse(systemInfo).systemInfo
                            ).notification_limit
                        }
                        store.commit(
                            'messagePush/setNotificationLimit',
                            notification_limit
                        )
                    }
                    /*
                    else if (type === 'getWechatCode') {
                        const { code } = params
                        store
                            .dispatch('wechat/appGetWXAuthorizeInfo', {
                                code,
                            })
                            .then((needBindMobile) => {
                                if (needBindMobile) {
                                    router.push('/bindMobile')
                                } else {
                                    router.go(-1)
                                }
                            })
                    } */
                    else if (type === 'setConsultantChatUnreadMessageNumber') {
                        //原生顾问在线未读消息数更新后通知 web 端
                        store.commit('messagePush/setUnreadMessages', params)
                    } else if (type === 'updateConsultingUnread') {
                        // 协同门诊-诊室 im消息通知h5有待回复消息, 把待回复消息会话id存到vuex中方便查询
                        store.dispatch('stCommonwealth/asyncSetIMUnreadMessageGroup', params.imGroupId)
                    } else if (type === 'updateConsultingDetail') {
                        // 协同门诊-诊室 诊室结诊接到通知更新页面状态
                        store.commit('stCommonwealth/setConclusionNotice', true)
                    }
                    else if (type === 'logout') {
                        store.dispatch('user/logout', {
                            intercept: true
                        })
                    }
                }

                return ''
            }
        } catch (e) {
            console.log('函数内错误', e)
        }
    }
}
