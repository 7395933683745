
<script>
export default {
  functional: true,
  render () {
    return (
      <div class="ie">
        <p class="tips">当前浏览器不支持，建议使用以下浏览器</p>
        <p class="comfort">下列软件均已通过安全验证，您可放心安装</p>
        <div class="download-wrap">
          <div class="item">
            <div class="left">
              <img src={require('@images/player/chrome.svg')} alt="" />
              <span>谷歌浏览器</span>
            </div>
            <div class="right">
              <a href="https://file.docbook.com.cn/software/93.0.4577.63_chrome_installer.exe">
                <img src={require('@images/player/download.svg')} alt="" />
              </a>
            </div>
          </div>
          <div class="item">
            <div class="left">
              <img src={require('@images/player/360.png')} alt="" />
              <span>360浏览器</span>
            </div>
            <div class="right">
              <a href="https://file.docbook.com.cn/software/360se13.1.1618.0.exe">
                <img src={require('@images/player/download.svg')} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
</script>

<style scoped lang="scss">
@import "@styles/variables.scss";
.ie {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
  height: 100% !important;
  width: 100% !important;
  position: relative;

  .tips {
    font-size: $fssm;
    color: white;
  }

  .comfort {
    margin-top: 0.3rem;
    font-size: $fsxs;
    color: #888888;
  }

  .download-wrap {
    margin-top: 0.9rem;

    .item {
      display: flex;
      margin-top: 0.3rem;
      height: 1.24rem;
      cursor: pointer;

      .left {
        width: 4.6rem;
        padding: 0.2rem;
        border-top-left-radius: 0.03rem;
        border-bottom-left-radius: 0.03rem;
        border-color: #0089ff;
        border-style: solid;
        display: flex;
        align-items: center;

        img {
          height: 0.77rem;
        }

        span {
          margin-left: 0.15rem;
          color: white;
          font-size: $fsxs;
        }
      }

      .right {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 1.24rem;
        width: 1.24rem;
        background-color: #0089ff;

        img {
          height: 0.53rem;
        }
      }
    }
  }
}
</style>